import { createContext, useContext, useEffect, useState } from "react";
import { ObtenerDatos } from "../helpers/ApiHelper";
import { AuthContext } from "./AuthContext";
import Swal from "sweetalert2";


export const TorneosContext = createContext();

export const TorneosProvider = (props) => {
    const {usuario} = useContext(AuthContext)
    const [torneos, setTorneos] = useState();
    const [torneoSeleccionado, setTorneoSeleccionado] = useState([]);
    const [modalAgregaTorneo, setModalAgregaTorneo] = useState(false)
    const [ligas, setLigas] = useState();
    const [ciudades, setCiudades] = useState()
    const [datosTorneo, setDatosTorneo] = useState();
    const [valorLiga, setValorLiga] = useState();
    const [valorCiudad, setValorCiudad] = useState();
    const [tablaPosiciones, setTablaPosiciones] = useState();
    const [calendario, setCalendario] = useState();

    const actualizaDatos = (campo, valor) => {
        setDatosTorneo({
            ...datosTorneo,
            [campo]:valor
        })
    }

    useEffect(() => {
        console.log('datos de torneo', datosTorneo)
    }, [datosTorneo])
    

    const obtenerTorneos = () => {
        const data = new FormData();
        data.append('idUsuario', usuario.idUsuario)

        ObtenerDatos('/torneos.php?funcion=obtenerTorneos', data).then(response => {
            console.log('respuesta de torneos', response)
            setTorneos(response)
        })
    }

    const obtenerTablaPosiciones = () => {
        const data = new FormData();
        data.append('idTorneo', torneoSeleccionado?.idTorneoDeportivo)
        ObtenerDatos('/torneos.php?funcion=tablaPosiciones', data).then(response => {
            console.log('tabla de posiciones', response);
            setTablaPosiciones(response);
        })
    }

    const obtenerCalendario = () => {
        const data = new FormData();
        data.append('idTorneo', torneoSeleccionado?.idTorneoDeportivo)
        ObtenerDatos('/torneos.php?funcion=calendario', data).then(response => {
            setCalendario(response);
        })
    }

    const obtenerCiudades = () => {
        ObtenerDatos('/usuarios.php?funcion=ciudades').then(response => {
            let opciones = new Array();
            response?.map(ciudad => {
                opciones.push({...ciudad, id:ciudad.idCiudad, label:ciudad.nombreCiudad})
            })
            setCiudades(opciones)
        })
    }

    const obtenerLigas = () => {
        const data = new FormData();
        data.append('idUsuario', usuario.idUsuario)
        ObtenerDatos('/ligas.php?funcion=obtenerLigas', data).then(response => {
            let opciones = new Array();
            response?.map(liga => {
                opciones.push({id:liga.idLiga, label:liga.nombre})
            })
          setLigas(opciones);
          if(opciones.length==1) {
            setValorLiga(opciones[0])
            actualizaDatos('idLiga', opciones[0].id)
          }
          console.log('ligas', opciones);
        });
      }

      const guardarTorneo = () => {
        ObtenerDatos('/torneos.php?funcion=guardarTorneo', JSON.stringify(datosTorneo)).then(response => {
            console.log('respuesta de guardar')
            obtenerTorneos();
            setModalAgregaTorneo(false);
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Torneo guardado correctamente",
                showConfirmButton: false,
                timer: 1500
              });         

        })
      }

      const eliminarTorneo = () => {
        Swal.fire({
            title: "Estas seguro(a)?",
            text: "No se puede revertir!, se eliminarán los registros de tablas de posiciones y calendario",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, eliminar!"
          }).then((result) => {
            if (result.isConfirmed) {
                const data = new FormData();
                data.append('idTorneoDeportivo', torneoSeleccionado?.idTorneoDeportivo)
                ObtenerDatos('/torneos.php?funcion=eliminarTorneo', data ).then(response => {
                    obtenerTorneos();
                    setTorneoSeleccionado();
                    Swal.fire({
                      title: "Eliminado!",
                      text: "El torneo ha sido eliminado.",
                      icon: "success"
                    });
                })
            }
          });
      }

      
   
   
    return(
        <TorneosContext.Provider
            value={{
                torneos, obtenerTorneos, torneoSeleccionado, setTorneoSeleccionado,
                modalAgregaTorneo, setModalAgregaTorneo, ligas, setLigas, obtenerLigas,
                ciudades, setCiudades, obtenerCiudades, datosTorneo, setDatosTorneo,
                valorLiga, setValorLiga, valorCiudad, setValorCiudad, actualizaDatos, guardarTorneo,
                tablaPosiciones, setTablaPosiciones, obtenerTablaPosiciones, obtenerCalendario, calendario,
                eliminarTorneo
            }}
            >
            {props.children}
        </TorneosContext.Provider>
    )
}