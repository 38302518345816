import { Delete, Edit } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material'
import dayjs from 'dayjs';
import React, { createContext, useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { ModalAgregaEventoComponent } from '../components/eventos/ModalAgregaEvento';
import { ObtenerDatos } from '../helpers/ApiHelper';

export const EventosContext = createContext();

export const CalendarioPage = () => {
  const [eventos, setEventos] = useState()
  const [modalAgregaEvento, setModalAgregaEvento] = useState(false);
  const [eventoSeleccionado, setEventoSeleccionado] = useState();

  useEffect(() => {
    obtenerEventos();
  }, [])
  
  const obtenerEventos = () => {
    ObtenerDatos('/eventos.php?funcion=obtenerEventos', null).then(response => {
      setEventos(response);
      console.log('eventos', response);
    });
  }

  const eliminarEvento = (idEvento) => {
    Swal.fire({
      title: '¿Estás seguro(a)?',
      text: "Se eliminará el evento, esto no puede ser revertido",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = new FormData();
        data.append('idEvento', idEvento)
        ObtenerDatos('/eventos.php?funcion=eliminarEvento', data).then(response => {
          if(response==1) {
            Swal.fire(
              'Eliminado!',
              'El evento ha sido eliminado.',
              'success'
            )
            obtenerEventos();
          } else {
            Swal.fire(
              'Error!',
              'el evento no pudo ser eliminado!.',
              'error'
            )

          }
        })
      }
    })
  }
  
  const columna = (titulo, ancho, fondo, anchoLetra, evento) => {
    return (
      <Typography
      style={{
        flex:ancho,
        fontWeight:anchoLetra,
        fontSize:14,
        paddingLeft:5,
        paddingRight:5,
        padding:5,
        color:evento?.idEvento == eventoSeleccionado?.idEvento ? 'white':'black',
        backgroundColor:evento?.idEvento == eventoSeleccionado?.idEvento ? '#03ABC6':'white'
      }}
    >
      {titulo}
    </Typography>
  
    )
  }

  const columna1 = (titulo, ancho, fondo, anchoLetra, evento) => {
    return (
      <Typography
      style={{
        flex:ancho,
        fontWeight:anchoLetra,
        fontSize:14,
        paddingLeft:5,
        paddingRight:5,
        padding:5,
        color:'black',
        backgroundColor:fondo
      }}
    >
      {titulo}
    </Typography>
  
    )
  }

  return (
    <EventosContext.Provider value={{modalAgregaEvento, setModalAgregaEvento, obtenerEventos, eventoSeleccionado}} >
    <>
    <div
      style={{
        marginLeft:20,
        marginTop:20,
        marginRight:20,
        display:'flex',
        flexDirection:'row',
        flex:5,
        justifyContent:'space-between',
        alignItems:'center'
      }}
    > 
      <Typography
        style={{
          fontSize:20,
          fontWeight:600
        }}
      >
        Calendario
      </Typography>
      <Button
        onClick={() => {setEventoSeleccionado(); setModalAgregaEvento(true)}}
        variant="contained"
      >
        Agregar
      </Button>
    </div>
    <div
      style={{
        marginLeft:20,
        marginTop:20,
        display:'flex',
        flexDirection:'column',
        flex:90
      }}
    >
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              width:'100%',
              backgroundColor:'red'
              //flex:1
            }}
          >
            {columna1('Nombre del Evento',20,'#03ABC6',500)}
            {columna1('Fecha',20,'#03ABC6',500)}
            {columna1('Recinto',20,'#03ABC6',500)}
            {columna1('Categoría',20,'#03ABC6',500)}
            {columna1('Puntos Deporti',20,'#03ABC6',500)}
            {columna1('',5, '#03ABC6',500)}
            {columna1('',5, '#03ABC6',500)}
          </div>

      {
        eventos?.map(evento => (
          <div
            onClick={() => {
              setEventoSeleccionado(evento)
            }}
            style={{
              display:'flex',
              flexDirection:'row',
              //flex:1
            }}
          >
            {columna(evento.nombreEvento,20,'white',300, evento)}
            {columna(dayjs(evento.fechaInicio).format('DD/MM/YYYY'),20,'white',300, evento)}
            {columna(evento.nombre,20,'white',300, evento)}
            {columna(evento.categoria,20,'white',300, evento)}
            {columna(evento.puntosDeporti,20,'white',300, evento)}
            <div
              style={{
                display:'flex',
                flex:5,
                color:evento?.idEvento == eventoSeleccionado?.idEvento ? 'white':'black',
                backgroundColor:evento?.idEvento == eventoSeleccionado?.idEvento ? '#03ABC6':'white'
              }}
            >
            <IconButton
              onClick={() => setModalAgregaEvento(true)}
            >
              <Edit style={{color:evento?.idEvento == eventoSeleccionado?.idEvento ? 'white':'green',}} />
            </IconButton>
            </div>
            <div
              style={{
                display:'flex',
                flex:5,
                color:evento?.idEvento == eventoSeleccionado?.idEvento ? 'white':'black',
                backgroundColor:evento?.idEvento == eventoSeleccionado?.idEvento ? '#03ABC6':'white',
                paddingLeft:20
              }}
            >
            <IconButton
              onClick={() => eliminarEvento(evento?.idEvento)}
            >
              <Delete style={{color:evento?.idEvento == eventoSeleccionado?.idEvento ? 'white':'red',}} />
            </IconButton>
            </div>
          </div>
        ))
      }
    </div>
    </>
    <ModalAgregaEventoComponent />
    </EventosContext.Provider>
  )
}

