import { Delete, Edit } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material'
import React, { createContext, useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { ModalAgregaDeporteComponent } from '../components/deportes/ModalAgregaDeporte';
import { ObtenerDatos } from '../helpers/ApiHelper';
import { ImagenApp } from '../components/deportes/ImagenApp';

export const DeportesContext = createContext();

export const DeportesPage = () => {
    const [deportes, setDeportes] = useState();
  const [modalAgregaDeporte, setModalAgregaDeporte] = useState(false);
  const [deporteSeleccionado, setDeporteSeleccionado] = useState();

  useEffect(() => {
    obtenerDeportes();
  }, [])
  
  const obtenerDeportes = () => {
    ObtenerDatos('/deportes.php?funcion=obtenerDeportes', null).then(response => {
      setDeportes(response);
      console.log('deportes', response);
    });
  }
  
  const columna = (titulo, ancho, fondo, anchoLetra, deporte) => {
    return (
      <Typography
      style={{
        flex:ancho,
        fontWeight:anchoLetra,
        fontSize:14,
        paddingLeft:5,
        paddingRight:5,
        padding:5,
        color:deporte?.idDeporte == deporteSeleccionado?.idDeporte ? 'white':'black',
        backgroundColor:deporte?.idDeporte == deporteSeleccionado?.idDeporte ? '#03ABC6':'white'
      }}
    >
      {titulo}
    </Typography>
  
    )
  }

  const columna1 = (titulo, ancho, fondo, anchoLetra, deporte) => {
    return (
      <Typography
      style={{
        flex:ancho,
        fontWeight:anchoLetra,
        fontSize:14,
        paddingLeft:5,
        paddingRight:5,
        padding:5,
        color:'black',
        backgroundColor:fondo
      }}
    >
      {titulo}
    </Typography>
  
    )
  }

  const eliminarDeporte = (idDeporte) => {
    Swal.fire({
      title: '¿Estás seguro(a)?',
      text: "Se eliminará el deporte, esto no puede ser revertido, solo se pueden eliminar deportes que no han sido usados",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = new FormData();
        data.append('idDeporte', idDeporte)
        ObtenerDatos('/deportes.php?funcion=eliminarDeporte', data).then(response => {
          if(response==1) {
            Swal.fire(
              'Eliminado!',
              'El deporte ha sido eliminado.',
              'success'
            )
            obtenerDeportes();
          } else {
            Swal.fire(
              'Error!',
              'El deporte no pudo ser eliminado!.',
              'error'
            )

          }
        })
      }
    })
  }

  return (
    <DeportesContext.Provider value={{modalAgregaDeporte, setModalAgregaDeporte, obtenerDeportes, deporteSeleccionado}} >
    <>
    <div
      style={{
        marginLeft:20,
        marginTop:20,
        marginRight:20,
        display:'flex',
        flexDirection:'row',
        flex:5,
        justifyContent:'space-between',
        alignItems:'center'
      }}
    > 
      <Typography
        style={{
          fontSize:20,
          fontWeight:600
        }}
      >
        Deportes / Disciplinas
      </Typography>
      <Button
        onClick={() => {setDeporteSeleccionado(); setModalAgregaDeporte(true)}}
        variant="contained"
      >
        Agregar
      </Button>
    </div>
    <div
      style={{
        display:'flex',
        flexDirection:'row'
      }}
    >

    <div
      style={{
        marginLeft:20,
        marginTop:20,
        display:'flex',
        flexDirection:'column',
        flex:50,
        maxWidth:'60vw',
        maxHeight:'80vh',
        overflowX:'auto'
      }}
    >
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              width:'100%',
              backgroundColor:'red'
              //flex:1
            }}
          >
            {columna1('Deporte',20,'#03ABC6',500)}
            {columna1('Mostrar en listado',20,'#03ABC6',500)}
            {columna1('Icono mini (app)',20,'#03ABC6',500)}
            {columna1('',5, '#03ABC6',500)}
          </div>

      {
        deportes?.map(deporte => (
          <div
            onClick={() => {
              setDeporteSeleccionado(deporte)
            }}
            style={{
              display:'flex',
              flexDirection:'row',
              
              
              //flex:1
            }}
          >
            {columna(deporte.deporte,20,'white',300, deporte)}
            {columna(deporte.mostrarEnListado=='1' ? 'Sí':'No',20,'white',300, deporte)}
            {columna(deporte.iconoMini,30,'white',300, deporte)}
            <div
              style={{
                display:'flex',
                flex:5,
                color:deporte?.idDeporte == deporteSeleccionado?.idDeporte ? 'white':'black',
                backgroundColor:deporte?.idDeporte == deporteSeleccionado?.idDeporte ? '#03ABC6':'white'
              }}
            >
            <IconButton
              onClick={() => setModalAgregaDeporte(true)}
            >
              <Edit style={{color:deporte?.idDeporte == deporteSeleccionado?.idDeporte ? 'white':'green',}} />
            </IconButton>
            <IconButton
              onClick={() => eliminarDeporte(deporte.idDeporte)}
            >
              <Delete style={{color:deporte?.idDeporte == deporteSeleccionado?.idDeporte ? 'white':'red',}} />
            </IconButton>
            </div>
          </div>
        ))
      }
    </div>


      <div
        style={{
          display:'flex',
          flexDirection:'row',
          flex:1,
          justifyContent:'flex-end',
          marginTop:20,
          marginRight:20
        }}
      >
        <ImagenApp />
      </div>
    </div>

    </>
    <ModalAgregaDeporteComponent />
    </DeportesContext.Provider>
  )
}

