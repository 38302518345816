import React, { useContext, useEffect, useRef, useState } from 'react'
import { DeportesContext } from '../../pages/DeportesPage'
import { BrokenImage } from '@mui/icons-material'
import { Button } from '@mui/material'
import { ObtenerDatos } from '../../helpers/ApiHelper'

export const ImagenApp = () => {
    const  {deporteSeleccionado, obtenerDeportes} = useContext(DeportesContext)
    const archivo = useRef();
    const refImagen = useRef();
    const [imagen, setImagen] = useState()

    useEffect(() => {
        console.log('deporte seleccionado para imagen', deporteSeleccionado)
        refImagen.current.src =  deporteSeleccionado?.iconoMini ? 'https://deporti.com.mx/imagenes/deportes/'+deporteSeleccionado?.iconoMini:'https://deporti.com.mx/imagenes/deportes/imagotipo.png'
    }, [deporteSeleccionado])

    const seleccionImagen = (event) => {
        console.log(event.target.files[0])
        setImagen({file:event.target.files[0]});
        console.log('selecciono imagen de deporte',event);
        var reader = new FileReader();
        reader.onload = function(){
            refImagen.current.src = reader.result;
        };
        reader.readAsDataURL(event.target.files[0]);
        subirImagen();
    }

    const subirImagen = () => {
        const data = new FormData();
        data.append('imagenDeporte', archivo.current.files[0])
        data.append('idDeporte', deporteSeleccionado?.idDeporte)
        
        
        ObtenerDatos('/deportes.php?funcion=actualizarImagenDeporte',data).then(response => {
            console.log('respuesta del servidor', response)
            obtenerDeportes();
        })
    }
    

  return (
    <div
        style={{
            display:'flex',
            flexDirection:'column'
        }}
    >
        <img 
            ref={refImagen}
            style={{
                width:150,
                height:'auto'
            }}
            src={deporteSeleccionado?.iconoMini ? 'https://deporti.com.mx/imagenes/deportes/'+deporteSeleccionado?.iconoMini:'https://deporti.com.mx/imagenes/deportes/imagotipo.png'}
        />
        <input 
            onChange={(e) => {console.log('se selecciono archivo', e), seleccionImagen(e)}}
            ref={archivo}
            type='file'
            style={{
                visibility:'hidden',
                width:1,height:1
            }}
        />
        <Button
            onClick={() => archivo.current.click()}
            variant='contained'
        >
            Cambiar imagen
        </Button>
    </div>
  )
}
