import { Divider } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { TorneosContext } from '../../context/TorneosContext'

export const Calendario = () => {
    const { obtenerCalendario, calendario, torneoSeleccionado} = useContext(TorneosContext)

    useEffect(() => {
        obtenerCalendario();
    }, [torneoSeleccionado])
    

  return (
    <div
        style={{
            display:'flex',
            flexDirection:'column',
            marginLeft:20,
            overflowY:'auto',
            maxHeight:'80vh'
        }}
    >
        {
            calendario?.map(fecha => (
                <div
                    style={{
                        marginBottom:20,
                        fontSize:12
                    }}
                >
                    <Divider />
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'row',
                            justifyContent:'flex-start',
                            color:'#03ABC6',
                        }}
                    >
                        {fecha.fecha}

                    </div>
                    <Divider/>
                    {
                        fecha?.partidos?.map(partido => (
                            <div
                                style={{
                                    display:'flex',
                                    flexDirection:'row',
                                }}
                            >
                                {partido.equipo1} vs {partido.equipo2}
                            </div>
                        ))
                    }
                </div>
            ))
        }
    </div>
  )
}
