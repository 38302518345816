import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TextField, Typography } from '@mui/material';
import { UNSAFE_convertRoutesToDataRoutes } from '@remix-run/router';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ObtenerDatos } from '../../helpers/ApiHelper';
import { EventosContext } from '../../pages/CalendarioPage';
import { AttachFile, Camera, CameraAlt, Delete, UploadFile } from '@mui/icons-material';
import { ImageSearch, Input } from '@mui/icons-material'
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
export const ModalAgregaEventoComponent = () => {
    const {modalAgregaEvento, setModalAgregaEvento, eventoSeleccionado, obtenerEventos} = useContext(EventosContext);
    const [datosEvento, setDatosEvento] = useState();
    const [deportes, setDeportes] = useState();
    const [valorDeporte, setValorDeporte] = useState({id:0,label:''});
    const [recintos, setRecintos] = useState();
    const [valorRecinto, setValorRecinto] = useState({id:0, label:''})
    const [categorias, setCategorias] = useState();
    const [valorCategoria, setValorCategoria] = useState({id:0, label:''})
    const [errorGuardado, setErrorGuardado] = useState(false);
    const [adjuntos, setAdjuntos] = useState();
    const uploadInputRef = useRef(null);
    const imgMilitante = useRef(null);
    const [file, setFile] = useState()

    const refForm = useRef();

    const handleClose = async() => {
        await setDatosEvento({})
        setModalAgregaEvento(false)
    }

    const SeleccionImagen = (event) => {
      setFile({file:event.target.files[0]});
      console.log('selecciono imagen',event);
      var reader = new FileReader();
      reader.onload = function(){
          imgMilitante.current.src = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
  };
    const seleccionArchivos = (e) => {
      console.log('archivos seleccionados',e);
      setAdjuntos(e.target.files);
    }

    const obtenerDeportes = () => {
      ObtenerDatos('/deportes.php?funcion=obtenerDeportes', null).then(response => {
        setDeportes(response);
        console.log('deportes', response);
        const opciones = new Array();
        response?.map(dato => {
          opciones.push({label:dato.deporte, id:dato.idDeporte})
        })
        setDeportes(opciones)
      });
    }

    const obtenerRecintos = () => {
      ObtenerDatos('/recintos.php?funcion=obtenerRecintos', null).then(response => {
        setRecintos(response);
        console.log('recintos', response);
        const opciones = new Array();
        response?.map(dato => {
          opciones.push({label:dato.nombre, id:dato.idRecinto})
        })
        setRecintos(opciones)
      });
    }
    const obtenerCategorias = () => {
      ObtenerDatos('/categorias.php?funcion=obtenerCategorias', null).then(response => {
        //setRecintos(response);
        console.log('categorias', response);
        const opciones = new Array();
        response?.map(dato => {
          opciones.push({label:dato.categoria, id:dato.idCategoria})
        })
        setCategorias(opciones)
      });
    }

    useEffect(() => {
      obtenerEventos();
      obtenerDeportes();
      obtenerRecintos();
      obtenerCategorias();
    }, [modalAgregaEvento])
    

    const actualizaDatos = (dato, valor) => {
       setDatosEvento({
           ...datosEvento,
           [dato]:valor
       })
    }

    useEffect(() => {
      console.log('Se actualizaron los datos del evento', datosEvento)
    }, [datosEvento])

    const verificaAccion = () => {
      console.log('recinto seleccionado en verificar accion', eventoSeleccionado)
      if(eventoSeleccionado?.idEvento) {
        setDatosEvento(eventoSeleccionado)
        setValorDeporte({
          id:eventoSeleccionado?.idDeporte,
          label:eventoSeleccionado?.deporte
        })
        setValorRecinto({
          id:eventoSeleccionado?.idRecinto,
          label:eventoSeleccionado?.nombre
        })
        setValorCategoria({
          id:eventoSeleccionado?.idCategoria,
          label:eventoSeleccionado?.categoria
        })
      } else {
      }
    }

    useEffect(() => {
      verificaAccion();
    },[eventoSeleccionado])
    

    const handleSubmit =  (event) => {
        event.preventDefault();
        var data = new FormData();
        data.append('imagenEvento', uploadInputRef.current.files[0])
        data.append('datos', JSON.stringify(datosEvento));
         ObtenerDatos('/eventos.php?funcion=agregarEvento', data).then(respuesta => {
            console.log('respuesta de guardado en modal', respuesta);
            if(respuesta!=1) {
                setErrorGuardado(true);
            } else{
                setErrorGuardado(false);
                obtenerEventos();
                handleClose();
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Evento guardado correctamente",
                  showConfirmButton: false,
                  timer: 1500
                });            


            }
        })
    }

  return (
      <div
        style={{
            minWidth:800
        }}
    >
     <Dialog maxWidth='xl' minWidth='xl' fullWidth='true' open={modalAgregaEvento} onClose={handleClose}>
        <form onSubmit={handleSubmit} ref={refForm}>
        <DialogTitle>
          <div>
            Evento

          </div>
        </DialogTitle>
        <DialogContent
            
            style={{minWidth:800}}
        >
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              flex:1
            }}
          >
                         <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  alignItems:'center'
                }}
              >
                <Typography
                  style={{
                    fontSize:13,
                    marginRight:5,
                    marginLeft:10
                  }}
                >
                  Ocultar evento
                </Typography>
                <Checkbox
                size='small'
                checked={datosEvento?.ocultarEvento=='1' ? true:false}
                  
                  onChange={(event) => { console.log('chk', event); actualizaDatos('ocultarEvento', event.target?.checked ? '1':'0')}}
                    style={{
                   //   fontSize:20
                    }}
                />
                
              </div>      
          </div>
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              flex:1
            }}
          >
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:70
              }}
            >
              <div
                style={{
                  display:'flex',
                }}
              >
          
                <TextField
                  style={{
                    flex:25,
                    marginRight:10
                  }}
                  InputLabelProps={{shrink:true}}
                  autoFocus
                  margin="dense"
                  label="Nombre del evento"
                  type="text"
                  fullWidth
                  variant="standard"
                  required={true}
                  onChange={(e) => actualizaDatos('nombreEvento',e.target.value)}
                  value={datosEvento?.nombreEvento || ''}
                />
                <Autocomplete
                  variant="standard"
                  onChange={(event, newValue) => {console.log('new value', newValue); setValorDeporte(newValue); actualizaDatos('idDeporte', newValue.id)}}
                  value={valorDeporte?.id ? valorDeporte:null}
                  //defaultValue={tiposActivo[index]}
                  //disablePortal
                  id="combo-box-demo"
                  options={deportes}
                  sx={{ zIndex:999999999 }}
                  style={{zIndex:99999999, flex:25, margintop:3}}
                  renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Deporte / Disciplina" />}
                />
                {/* <TextField
                  style={{
                    flex:25,
                    marginRight:10,
                    marginLeft:10
                  }}
                  InputLabelProps={{shrink:true}}
                  
                  margin="dense"
                  label="Destino/Objetivo"
                  type="text"
                  fullWidth
                  variant="standard"
                  required={true}
                  onChange={(e) => actualizaDatos('destino',e.target.value)}
                  value={datosEvento?.destino || ''}
                /> */}
                <Autocomplete
                  variant="standard"
                  onChange={(event, newValue) => {console.log('new value', newValue); setValorRecinto(newValue); actualizaDatos('idRecinto', newValue.id)}}
                  value={valorRecinto?.id ? valorRecinto:null}
                  //defaultValue={tiposActivo[index]}
                  //disablePortal
                  id="combo-box-demo"
                  options={recintos}
                  sx={{ zIndex:999999999 }}
                  style={{zIndex:99999999, flex:25, margintop:3}}
                  renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Recinto" />}
                />

              </div>
              <div
                style={{
                  display:'flex',
                }}
              >
                <TextField
                  style={{
                    flex:25,
                    marginRight:10,
                    marginLeft:10
                  }}
                  InputLabelProps={{shrink:true}}
                  
                  margin="dense"
                  label="Fecha y hora de inicio"
                  type="datetime-local"
                  fullWidth
                  variant="standard"
                  required={true}
                  onChange={(e) => actualizaDatos('fechaInicio',e.target.value)}
                  value={datosEvento?.fechaInicio || ''}
                />
                <TextField
                  style={{
                    flex:25,
                    marginRight:10,
                    marginLeft:10
                  }}
                  InputLabelProps={{shrink:true}}
                  
                  margin="dense"
                  label="Fecha y hora de finalización"
                  type="datetime-local"
                  fullWidth
                  variant="standard"
                  required={false}
                  onChange={(e) => actualizaDatos('fechaFin',e.target.value)}
                  value={datosEvento?.fechaFin || ''}
                />
                <Autocomplete
                  variant="standard"
                  onChange={(event, newValue) => {console.log('new value', newValue); setValorCategoria(newValue); actualizaDatos('idCategoria', newValue.id)}}
                  value={valorCategoria?.id ? valorCategoria:null}
                  //defaultValue={tiposActivo[index]}
                  //disablePortal
                  id="combo-box-demo"
                  options={categorias}
                  sx={{ zIndex:999999999 }}
                  style={{zIndex:99999999, flex:25, margintop:3}}
                  renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Categoría" />}
                />
                <TextField
                  style={{
                    flex:25,
                    marginRight:10,
                    marginLeft:10
                  }}
                  InputLabelProps={{shrink:true}}
                  
                  margin="dense"
                  label="Puntos Deporti"
                  type="number"
                  fullWidth
                  variant="standard"
                  required={true}
                  onChange={(e) => actualizaDatos('puntosDeporti',e.target.value)}
                  value={datosEvento?.puntosDeporti || ''}
                />                

              </div>
              <div
                style={{
                  display:'flex',
                }}
              >
                <TextField
                  style={{
                    flex:25,
                    marginRight:10,
                    marginLeft:10
                  }}
                  InputLabelProps={{shrink:true}}
                  
                  margin="dense"
                  label="Organizador"
                  type="text"
                  fullWidth
                  variant="standard"
                  required={true}
                  onChange={(e) => actualizaDatos('organizador',e.target.value)}
                  value={datosEvento?.organizador || ''}
                />
                <TextField
                  style={{
                    flex:25,
                    marginRight:10,
                    marginLeft:10
                  }}
                  InputLabelProps={{shrink:true}}
                  
                  margin="dense"
                  label="Teléfono"
                  type="text"
                  fullWidth
                  variant="standard"
                  required={true}
                  onChange={(e) => actualizaDatos('telefono',e.target.value)}
                  value={datosEvento?.telefono || ''}
                />
              </div>
              <div
                style={{
                  display:'flex',
                }}
              >
                <TextField
                  style={{
                    flex:25,
                    marginRight:10,
                    marginLeft:10
                  }}
                  InputLabelProps={{shrink:true}}
                  
                  margin="dense"
                  label="Facebook"
                  type="text"
                  fullWidth
                  variant="standard"
                  required={false}
                  onChange={(e) => actualizaDatos('facebook',e.target.value)}
                  value={datosEvento?.facebook || ''}
                />
                <TextField
                  style={{
                    flex:25,
                    marginRight:10,
                    marginLeft:10
                  }}
                  InputLabelProps={{shrink:true}}
                  
                  margin="dense"
                  label="Instagram"
                  type="text"
                  fullWidth
                  variant="standard"
                  required={false}
                  onChange={(e) => actualizaDatos('instagram',e.target.value)}
                  value={datosEvento?.instagram || ''}
                />
                <TextField
                  style={{
                    flex:25,
                    marginRight:10,
                    marginLeft:10
                  }}
                  InputLabelProps={{shrink:true}}
                  
                  margin="dense"
                  label="Correo"
                  type="text"
                  fullWidth
                  variant="standard"
                  required={true}
                  onChange={(e) => actualizaDatos('correo',e.target.value)}
                  value={datosEvento?.correo || ''}
                />
              </div>
              <div
                style={{
                  display:'flex',
                }}
              >
                <TextField
                  style={{
                    flex:25,
                    marginRight:10,
                    marginLeft:10
                  }}
                  InputLabelProps={{shrink:true}}
                  
                  margin="dense"
                  label="Nombre del responsable"
                  type="text"
                  fullWidth
                  variant="standard"
                  required={true}
                  onChange={(e) => actualizaDatos('nombreResponsable',e.target.value)}
                  value={datosEvento?.nombreResponsable || ''}
                />
                <TextField
                  style={{
                    flex:25,
                    marginRight:10,
                    marginLeft:10
                  }}
                  InputLabelProps={{shrink:true}}
                  
                  margin="dense"
                  label="Cargo del responsable"
                  type="text"
                  fullWidth
                  variant="standard"
                  required={true}
                  onChange={(e) => actualizaDatos('cargoResponsable',e.target.value)}
                  value={datosEvento?.cargoResponsable || ''}
                />
                <TextField
                  style={{
                    flex:25,
                    marginRight:10,
                    marginLeft:10
                  }}
                  InputLabelProps={{shrink:true}}
                  
                  margin="dense"
                  label="CTA"
                  type="text"
                  fullWidth
                  variant="standard"
                  required={true}
                  onChange={(e) => actualizaDatos('cta',e.target.value)}
                  value={datosEvento?.cta || ''}
                />
              </div>
              <div
                style={{
                  display:'flex',
                }}
              >
                <TextField
                  style={{
                    flex:25,
                    marginRight:10,
                    marginLeft:10
                  }}
                  InputLabelProps={{shrink:true}}
                  
                  margin="dense"
                  label="Descripción"
                  type="text"
                  rows={4}
                  multiline
                  fullWidth
                  variant="standard"
                  required={true}
                  onChange={(e) => actualizaDatos('descripcion',e.target.value)}
                  value={datosEvento?.descripcion || ''}
                />     
                </div>         
            </div>
            <div
            style={{
                display:'flex',
                flexDirection:'row',
                paddingLeft:20,
                //maxHeight:50
            }}
        >
            <div
              style={{
                display:'flex',
                flexDirection:'column'
              }}
            >
              <Button
                style={{
                  textTransform:'unset'
                }}
                variant="contained"
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                >Imagen del evento <ImageSearch /></Button>
            <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden'}} accept='.jpg,.png' type="file" />
            <img style={{
              width:200,
              height:'auto'
            }} ref={imgMilitante} src={eventoSeleccionado?.imagenEvento ? process.env.REACT_APP_API_URLIMAGENES+'/eventos/'+eventoSeleccionado?.imagenEvento : ''} />
              </div>         
              </div>
              <div>
        </div>
          </div>
          {
            errorGuardado && (
              <Typography
                style={{
                    fontSize:12,
                    color:'red'
                }}
              >
                Error al guardar
            </Typography>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type='submit' >Guardar</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
