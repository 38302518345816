import React, { useContext, useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react';
import { CardUsuario } from '../components/usuarios/CardUsuario';
import { UsuariosContext } from '../context/UsuariosContext';
import { ModalApoyo } from '../components/usuarios/ModalApoyo';
import { ModalTablaApoyos } from '../components/usuarios/ModalTablaApoyos';
import { ObtenerDatos } from '../helpers/ApiHelper';
import { AuthContext } from '../context/AuthContext';
import { CardReporte } from '../components/mapa/CardReporte';
import { Card } from '@mui/material';

const defaultProps = {
  center: {
    lat: 28.700772688116892,
    lng: -106.53524198508615
  },
  zoom: 7
};

export const MapaPage = () => {
  const {usuarioSeleccionado, setUsuarioSeleccionado, usuarios,obtenerUsuarios} = useContext(UsuariosContext)
  const {etiquetaFiltro, nombreFiltro, actualizaMapa} = useContext(AuthContext)
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [isMapsLoaded, setIsMapsLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [layerCiudadanos, setLayerCiudadanos] = useState(false);
  const [usuariosMapa, setUsuariosMapa] = useState();

  console.log('usuarios al entrar al mapa', usuarios,usuarioSeleccionado, etiquetaFiltro)
  const usuariosRecibidos = usuarios;

  useEffect(() => {
    setUsuariosMapa(usuarios)
  }, [])
  
  useEffect(() => {
    console.log('cambio usuarios en mapa', usuarios)
  }, [usuarios])
  
  useEffect(() => {
    console.log('cambiaron los filtros', etiquetaFiltro, nombreFiltro,layerCiudadanos)
    if(layerCiudadanos?.url) {
      const d = new Date();
      const seconds = Math.round(d.getTime() / 1000);
      //layerCiudadanos.setMap(null);
      layerCiudadanos.url='https://deporti.com.mx/servicios/generar.capa.usuarios.php?v='+seconds+'&usuario='+encodeURI(nombreFiltro)+'&etiqueta='+etiquetaFiltro?.value || ''
      console.log('layer de ciudadanos 2', layerCiudadanos)
      layerCiudadanos.setMap(map)
    }
  }, [actualizaMapa])

  useEffect(() => {
    console.log(('cambio el filtro en effect', etiquetaFiltro))
  }, [etiquetaFiltro])
  
  
  

  const loadMap = (map, maps, usuariosFuncion) => {
    console.log('usuarios recibidos en funcion', usuariosFuncion)
    setMap(map);
    setMaps(maps);
    const d = new Date();
    const seconds = Math.round(d.getTime() / 1000);
    // const kmlLayerSecciones = new maps.KmlLayer({
    //     url: 'http://raosari.dyndns.info:8080/decidechih/servicios/kmls/generar.capa.php?v=202203211403&anio='+anio,
    //     suppressInfoWindows: true,
    //     draggable: true,
    //     zIndex:2000,
    //     map:map
    // });
    const kmlLayerCiudadanos = new maps.KmlLayer({

        url: 'https://deporti.com.mx/servicios/generar.capa.usuarios.php?v='+seconds,
        suppressInfoWindows: true,
        draggable: true,
        zIndex:3000,
        map:map
    });
    kmlLayerCiudadanos.addListener('click', function(event) {
      console.log(event, 'usuarios', usuariosRecibidos, event?.featureData?.description);
      console.log(('etiqueta filtro cuando da click', etiquetaFiltro))
      const data = new FormData();
      data.append('idUsuario', event?.featureData?.description)
      data.append('etiqueta', etiquetaFiltro?.value)
      ObtenerDatos('/usuarios.php?funcion=usuarioID', data).then(response => {
        console.log('response de detalle', response)
        if(event?.featureData?.description) {
          if(event?.featureData?.description?.substr(0,1) =='R') {
            setUsuarioSeleccionado(response)
          } else {
            setUsuarioSeleccionado(response[0])
          }
        }
      })      
    })
    setLayerCiudadanos(kmlLayerCiudadanos)
  }

  

  return (
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        flex:1,
        backgroundColor:'transparent'
      }}
    >
      <div
        style={{display:'flex', flexDirection:'column', flex:80}}
      >
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDH9WzJ1Gkjg7VsxW6aSp9v2QJpgx0GVWI", }}
        
        //ref={myRef}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        draggable={true}
        //year={year.value}
        //seccionseleccionada = {seccionseleccionada}
        onDragend= {(evt) => { }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
            //mapRef.current = map;
          loadMap(map, maps, usuarios);
            const d = new Date();
            const seconds = Math.round(d.getTime() / 1000);
            return(<b>este es un objeto dentro de una funcion</b>)

    
        }}
        onChange={({map, maps}) => {
            //console.log(year.year.value)
            
            //loadMap1(map,maps,year.year.value);
        }}
      >

    </GoogleMapReact>
      </div>
      <div
        style={{display:'flex', flexDirection:'column', flex:20}}
      >
        {
          usuarioSeleccionado?.IdReporte ? (
            <CardReporte  />
          ):(
            <CardUsuario />
          )
}
      </div>
      <ModalApoyo />
      <ModalTablaApoyos />

    </div>
  )
}
