import { CheckBox, KeyboardReturnRounded } from '@mui/icons-material';
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, Divider, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { UsuariosContext } from '../../context/UsuariosContext'
import { ObtenerDatos } from '../../helpers/ApiHelper';

export const ModalUsuario = () => {
    const {modalUsuario, setModalUsuario, usuarioSeleccionado, instituciones, obtenerInstituciones, 
        generos, obtenerGeneros, estadosCiviles, obtenerEstadosCiviles, obtenerCiudades, ciudades,
        municipios, obtenerMunicipios, ocupaciones, obtenerOcupaciones, obtenerUsuarios, etiquetas,
        modalDeportesFavoritos, setModalDeportesFavoritos, deportesFavoritos, deportesPractica, setModalDeportesPractica} = useContext(UsuariosContext);
    const [datosUsuario, setDatosUsuario] = useState();
    const [valorInstitucion, setValorInstitucion] = useState({id:0,label:null});
    const [valorGenero, setValorGenero] = useState()
    const [valorEstadoCivil, setValorEstadoCivil] = useState()
    const [valorCiudad, setValorCiudad] = useState();
    const [valorMunicipio, setValorMunicipio] = useState();
    const [valorOcupacion, setValorOcupacion] = useState()
    const [valorEtiqueta, setValorEtiqueta] = useState();
    const handleClose=()=> {
        setDatosUsuario()
        setValorInstitucion();
        setValorGenero();
        setValorEstadoCivil();
        setValorCiudad();
        setValorMunicipio();
        setValorOcupacion();
        setValorEtiqueta();
        setModalUsuario(false);
    }

    const actualizaDatos = (campo, valor) => {
        setDatosUsuario({
            ...datosUsuario,
            [campo]:valor
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Guardando datos');
        const data = new FormData();
        data.append('datos', JSON.stringify(datosUsuario))
        data.append('deportesFavoritos', JSON.stringify(deportesFavoritos))
        data.append('deportesPractica', JSON.stringify(deportesPractica) )
        ObtenerDatos('/usuarios.php?funcion=agregarUsuarioDeporti', data).then(response => {
            console.log('respuesta del servidor', response);
            setModalUsuario(false);
            obtenerUsuarios();
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Usuario guardado correctamente",
                showConfirmButton: false,
                timer: 1500
              });
        })
    }

    useEffect(() => {
        console.log('Usuario seleccionado en modal',usuarioSeleccionado)
        if(modalUsuario && usuarioSeleccionado?.idUsuario) {
            setDatosUsuario(usuarioSeleccionado)
            setValorGenero({id:usuarioSeleccionado?.idGenero, label:usuarioSeleccionado?.genero})
            setValorEstadoCivil({id:usuarioSeleccionado?.idEstadoCivil, label:usuarioSeleccionado?.estadoCivil})
            setValorCiudad({id:usuarioSeleccionado?.idCiudad, label:usuarioSeleccionado?.nombreCiudad})
            setValorMunicipio({id:usuarioSeleccionado?.idMunicipio, label:usuarioSeleccionado?.municipio})
            setValorOcupacion({id:usuarioSeleccionado?.idOcupacion, label:usuarioSeleccionado?.ocupacion})
            setValorEtiqueta({id:usuarioSeleccionado?.idEtiqueta, label:usuarioSeleccionado?.etiqueta})
        }
    }, [modalUsuario])
    

    useEffect(() => {
        console.log('datos de usuario', datosUsuario)
    }, [datosUsuario])

    useEffect(() => {
        obtenerInstituciones();
        obtenerGeneros();
        obtenerEstadosCiviles();
        obtenerCiudades();
        obtenerMunicipios();
        obtenerOcupaciones();
    }, [])

    useEffect(() => {
        console.log('instituciones en modal', instituciones)
    }, [instituciones])

  return (
    <Dialog

        maxWidth='md'
        fullWidth
        open={modalUsuario}
        onClose={handleClose}
    >
        <form onSubmit={handleSubmit} >
            <DialogContent>
                <div
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        marginTop:10
                    }}
                >
                    <TextField
                        fullWidth
                        autoComplete='off'
                        
                        InputLabelProps={{
                            shrink:true
                        }}
                        value={datosUsuario?.nombre}
                        onChange={(e) => {actualizaDatos('nombre', e.target.value)}}
                        variant='standard'
                        label='Nombre'
                        required
                    />
                    <TextField
                        autoComplete='off'
                        onChange={(e) => {actualizaDatos('apellidoPaterno', e.target.value)}}
                        value={datosUsuario?.apellidoPaterno}
                        InputLabelProps={{
                            shrink:true
                        }}
                        style={{
                            marginLeft:10
                        }}
                        fullWidth
                        variant='standard'
                        label='Apellido Paterno'
                        required
                    />
                    <TextField
                        autoComplete='off'
                        fullWidth
                        value={datosUsuario?.apellidoMaterno}
                        InputLabelProps={{
                            shrink:true
                        }}
                        onChange={(e) => {actualizaDatos('apellidoMaterno', e.target.value)}}
                        style={{
                            marginLeft:10
                        }}
                        variant='standard'
                        label='Apellido Materno'
                        required
                    />
                </div>
                <div
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        marginTop:10
                    }}
                >
                    <TextField 
                        autoComplete='off'
                        type='text'
                        required
                        value={datosUsuario?.institucion}
                        InputLabelProps={{
                            shrink:true
                        }}
                        onChange={(e) => {actualizaDatos('institucion', e.target.value)}}
                        variant='standard'
                        label='Institución'
                        style={{
                            marginLeft:0,
                            marginTop:8,
                            minWidth:350
                            
                        }}
                    />
                    {/* <Autocomplete
                        InputLabelProps={{shrink:true}}
                        variant="standard"
                        onChange={(event, newValue) => {setValorInstitucion(newValue); actualizaDatos('idInstitucion', newValue.id)}}
                        value={valorInstitucion?.id ? valorInstitucion:null}
                        //defaultValue={tiposActivo[index]}
                        //disablePortal
                        id="combo-box-demo"
                        options={instituciones}
                        sx={{ zIndex:999999999 }}
                        style={{zIndex:99999999, flex:25, margintop:3, marginLeft:0}}
                        renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Institución" />}
                    /> */}
                    <Autocomplete
                        InputLabelProps={{shrink:true}}
                        variant="standard"
                        onChange={(event, newValue) => {setValorEtiqueta(newValue); actualizaDatos('idEtiqueta', newValue.id)}}
                        value={valorEtiqueta?.id ? valorEtiqueta:null}
                        //defaultValue={tiposActivo[index]}
                        //disablePortal
                        id="combo-box-demo"
                        options={etiquetas}
                        sx={{ zIndex:999999999 }}
                        style={{zIndex:99999999, flex:25, margintop:3, marginLeft:10}}
                        renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Etiqueta" />}
                    />
                    <TextField 
                        autoComplete='off'
                        type='email'
                        value={usuarioSeleccionado?.email}
                        InputLabelProps={{
                            shrink:true
                        }}
                        onChange={(e) => {actualizaDatos('email', e.target.value)}}
                        variant='standard'
                        label='Email'
                        style={{
                            marginLeft:10,
                            marginTop:8,
                            minWidth:350
                            
                        }}
                    />
                    {/* <div
                        style={{
                            display:'flex',
                            flexDirection:'row',
                            alignItems:'center',
                            fontSize:12
                        }}
                    >
                        <Checkbox 
                            onChange={(e) => {actualizaDatos('autorizaApoyos', e.target.checked ? 1:0)}}
                            checked={usuarioSeleccionado?.autorizaApoyos==1 ? true:false}
                        /> Autoriza apoyos
                    </div> */}
                </div>
                <div
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        marginTop:10
                    }}
                >
                    <TextField
                        autoComplete='off'
                        type='date'
                        value={usuarioSeleccionado?.fechaNacimiento}
                        required
                        InputLabelProps={{
                            shrink:true
                        }}
                        onChange={(e) => {actualizaDatos('fechaNacimiento', e.target.value)}}
                        style={{
                            marginLeft:0,
                            marginTop:8,
                            
                        }}
                        variant='standard'
                        label='Fecha de Nacimiento'
                        
                    />

                    <Autocomplete
                        InputLabelProps={{shrink:true}}
                        variant="standard"
                        onChange={(event, newValue) => {setValorGenero(newValue); actualizaDatos('idGenero', newValue.id)}}
                        value={valorGenero?.id ? valorGenero:null}
                        //defaultValue={tiposActivo[index]}
                        //disablePortal
                        id="combo-box-demo"
                        options={generos}
                        sx={{ zIndex:999999999 }}
                        style={{zIndex:99999999, flex:25, margintop:3, marginLeft:10}}
                        renderInput={(params) => <TextField required variant="standard"    style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Genero" />}
                    />
                    <Autocomplete
                        InputLabelProps={{shrink:true}}
                        variant="standard"
                        onChange={(event, newValue) => {setValorEstadoCivil(newValue); actualizaDatos('idEstadoCivil', newValue.id)}}
                        value={valorEstadoCivil?.id ? valorEstadoCivil:null}
                        //defaultValue={tiposActivo[index]}
                        //disablePortal
                        id="combo-box-demo"
                        options={estadosCiviles}
                        sx={{ zIndex:999999999 }}
                        style={{zIndex:99999999, flex:25, margintop:3, marginLeft:10}}
                        renderInput={(params) => <TextField  variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Estado Civil" />}
                    />
                </div>
                <div
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        marginTop:10
                    }}
                >
                    <TextField
                        autoComplete='off'

                        fullWidth
                        value={usuarioSeleccionado?.curp}
                        InputLabelProps={{
                            shrink:true
                        }}
                        onChange={(e) => {actualizaDatos('curp', e.target.value)}}
                        variant='standard'
                        label='CURP'
                        required={false}
                    />
                    {/* <TextField
                        autoComplete='off'

                        fullWidth
                        value={usuarioSeleccionado?.claveElector}
                        InputLabelProps={{
                            shrink:true
                        }}
                        onChange={(e) => {actualizaDatos('claveElector', e.target.value)}}
                        style={{
                            marginLeft:10
                        }}
                        variant='standard'
                        label='Clave de Elector'
                        
                    /> */}
                    <TextField
                        autoComplete='off'

                        fullWidth
                        value={usuarioSeleccionado?.telefono}
                        InputLabelProps={{
                            shrink:true
                        }}
                        required
                        onChange={(e) => {actualizaDatos('telefono', e.target.value)}}
                        style={{
                            marginLeft:10
                        }}
                        variant='standard'
                        label='Teléfono'
                        
                    />
                </div>
                <div
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        marginTop:10
                    }}
                >
                    <TextField
                        autoComplete='off'

                        fullWidth
                        value={usuarioSeleccionado?.calle}
                        InputLabelProps={{
                            shrink:true
                        }}
                        onChange={(e) => {actualizaDatos('calle', e.target.value)}}
                        variant='standard'
                        label='Calle'
                   //     required
                    />
                    <TextField
                        autoComplete='off'

                        fullWidth
                        value={usuarioSeleccionado?.numeroExterior}
                      //  required
                        InputLabelProps={{
                            shrink:true
                        }}
                        onChange={(e) => {actualizaDatos('numeroExterior', e.target.value)}}
                        style={{
                            marginLeft:10
                        }}
                        variant='standard'
                        label='Número Exterior'
                        
                    />
                    <TextField
                        autoComplete='off'

                        fullWidth
                        value={usuarioSeleccionado?.numeroInterior}
                        InputLabelProps={{
                            shrink:true
                        }}
                        
                        onChange={(e) => {actualizaDatos('numeroInterior', e.target.value)}}
                        style={{
                            marginLeft:10
                        }}
                        variant='standard'
                        label='Número Interior'
                        
                    />
                    <TextField
                        autoComplete='off'

                        fullWidth
                        value={usuarioSeleccionado?.colonia}
                        InputLabelProps={{
                            shrink:true
                        }}
                        type='text'
                      //  required
                        onChange={(e) => {actualizaDatos('colonia', e.target.value)}}
                        style={{
                            marginLeft:10
                        }}
                        variant='standard'
                        label='Colonia'
                        
                    />
                </div>
                <div
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        marginTop:20
                    }}
                >
                    <TextField
                        autoComplete='off'

                        type='number'
                        value={usuarioSeleccionado?.cp}
                        InputLabelProps={{
                            shrink:true
                        }}
                        onChange={(e) => {actualizaDatos('cp', e.target.value)}}
                        style={{
                            marginLeft:0,
                        }}
                        variant='standard'
                        label='C.P.'
                        
                    />
                    <Autocomplete
                        InputLabelProps={{shrink:true}}
                        variant="standard"
                        onChange={(event, newValue) => {setValorCiudad(newValue); actualizaDatos('idCiudad', newValue.id)}}
                        value={valorCiudad?.id ? valorCiudad:null}
                        //defaultValue={tiposActivo[index]}
                        //disablePortal
                        id="combo-box-demo"
                        options={ciudades}
                        sx={{ zIndex:999999999 }}
                        style={{zIndex:99999999, flex:25, margintop:0, marginLeft:10}}
                        renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:0, }} {...params} label="Ciudad" />}
                    />
                    <Autocomplete
                        InputLabelProps={{shrink:true}}
                        variant="standard"
                        onChange={(event, newValue) => {setValorMunicipio(newValue); actualizaDatos('idMunicipio', newValue.id)}}
                        value={valorMunicipio?.id ? valorMunicipio:null}
                        //defaultValue={tiposActivo[index]}
                        //disablePortal
                        id="combo-box-demo"
                        options={municipios}
                        sx={{ zIndex:999999999 }}
                        style={{zIndex:99999999, flex:25, margintop:3, marginLeft:10}}
                        renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:0, }} {...params} label="Municipio" />}
                    />
                    <Autocomplete
                        InputLabelProps={{shrink:true}}
                        variant="standard"
                        onChange={(event, newValue) => {setValorOcupacion(newValue); actualizaDatos('idOcupacion', newValue.id)}}
                        value={valorOcupacion?.id ? valorOcupacion:null}
                        //defaultValue={tiposActivo[index]}
                        //disablePortal
                        id="combo-box-demo"
                        options={ocupaciones}
                        sx={{ zIndex:999999999 }}
                        style={{zIndex:99999999, flex:25, margintop:3, marginLeft:10}}
                        renderInput={(params) => <TextField  variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:0, }} {...params} label="Ocupación" />}
                    />

                </div>
                <div
                    fullWidth
                    style={{
                        display:'flex',
                        flex:50,
                        flexDirection:'row',
                        marginTop:20
                    }}
                >
                    <TextField
                        autoComplete='off'

                        onClick={() => {setModalDeportesFavoritos(true)}}
                        fullWidth
                        type='text'
                        multiline={true}
                        rows={3}
                        value={deportesFavoritos?.filter(deporte=> deporte?.idUsuario).map(deporte => ' '+deporte.deporte)}
                        InputLabelProps={{
                            shrink:true
                        }}
                        InputProps={{
                            readOnly:true
                        }}
                        //onChange={(e) => {actualizaDatos('cp', e.target.value)}}
                        style={{
                            marginLeft:0,
                        }}
                        variant='standard'
                        label='Deportes favoritos'
                        
                    />
                    <TextField
                        autoComplete='off'

                        onClick={() => {setModalDeportesPractica(true)}}
                        fullWidth
                        type='text'
                        multiline={true}
                        rows={3}

                        value={deportesPractica?.filter(deporte=> deporte?.idUsuario).map(deporte => ' '+deporte.deporte)}
                        InputLabelProps={{
                            shrink:true
                        }}
                        InputProps={{
                            readOnly:true
                        }}
                        //onChange={(e) => {actualizaDatos('cp', e.target.value)}}
                        style={{
                            marginLeft:10,
                        }}
                        variant='standard'
                        label='Deportes que práctica'
                        
                    />
                </div>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button type="submit">Guardar</Button>
            </DialogActions>
        </form>
    </Dialog>
    
  )
}
