import { Button, Dialog, DialogActions, DialogContent, Divider, ImageList, ImageListItem } from '@mui/material'
import React, { useContext } from 'react'
import { UsuariosContext } from '../../context/UsuariosContext'

export const ModalImagenApoyo = () => {
    const {modalImagenApoyo, setModalImagenApoyo, apoyoSeleccionado} = useContext(UsuariosContext)

    const handleClose = () => {
        setModalImagenApoyo(false)
    }

  return (
    <Dialog
        maxWidth='lg'
        fullWidth
        open={modalImagenApoyo}
        onClose={handleClose}
    >
        <DialogContent>
            <ImageList sx={{ width: 'auto', height: '100%' }} variant="woven" cols={1} gap={8}>
          {apoyoSeleccionado?.imagenes?.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${process.env.REACT_APP_API_URLIMAGENES+'/apoyos/'+item}?w=191&fit=&auto=format&dpr=2 2x`}
                src={`${process.env.REACT_APP_API_URLIMAGENES+'/apoyos/'+item}?w=191&fit=&auto=format`}
                alt={item}
                loading="lazy"
                
              />
            </ImageListItem>
            ))}
            </ImageList>
             {/* <img style={{
                width:'auto',
                height:'70vh'
              }}src={process.env.REACT_APP_API_URLIMAGENES+'/apoyos/'+apoyoSeleccionado?.imagen} /> */}
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button onClick={() => {handleClose()}}>Cerrar</Button>
        </DialogActions>
    </Dialog>
  )
}
