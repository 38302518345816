import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import MuiListItem from '@mui/material/ListItem';
//import MuiListItem from "@material-ui/core/ListItem";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Person from '@mui/icons-material/Person';
import { Avatar, makeStyles, withStyles } from '@mui/material';
import { useState } from 'react';
import GridViewIcon from '@mui/icons-material/GridView';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PublicIcon from '@mui/icons-material/Public';
import { Route, Routes, useNavigate, useNavigation } from 'react-router-dom';
import { ClassNames } from '@emotion/react';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { LoginPage } from './LoginPage';
import { AuthContext } from '../context/AuthContext';
import { MapSharp } from '@mui/icons-material';
import { CalendarioPage } from './CalendarioPage';
import { EscuelasDeportivasPage } from './EscuelasDeportivasPage';
import { TiendaDeportiPage } from './TiendaDeportiPage';
import { LigasDeportivasPage } from './LigasDeportivasPage';
import { AdministracionPage } from './AdministracionPage';
import { MapaPage } from './MapaPage';
import { RecintosDeportivosPage } from './RecintosDeportivosPage';
import { DeportesPage } from './DeportesPage';
import { UsuariosPage } from './UsuariosPage';
import { UsuariosProvider, UsuariosContext } from '../context/UsuariosContext';
import { TorneosDeportivosPage } from './TorneosDeportivosPage';
import { TorneosContext, TorneosProvider } from '../context/TorneosContext';
import { InicialPage } from './InicialPage';
import { FiltrosMapa } from '../components/mapa/FiltrosMapa';
import { EntregaArticulosPage } from './EntregaArticulosPage';
import { ReportesPage } from './ReportesPage';
const drawerWidth = 240;


const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



export default function HomePage() {
  const {usuario, setUsuario, setLogueado, opcionSeleccionada, setOpcionSeleccionada} = React.useContext(AuthContext);

  console.log('usuario logueado', usuario);

  const navigate = useNavigate();
  const [seleccion, setSeleccion] = useState('dashboard');

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
//  const [opcionSeleccionada, setOpcionSeleccionada] = useState('dashboard');

  const handleDrawerOpen = () => {
    if(open==true) {
        setOpen(false);
    } else {
        setOpen(true);
    }
  };


  const cerrarSesion = () => {
    setUsuario();
    setLogueado(false);
    
  }

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor:'black' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
            style={{backgroundColor:'white', }}
        >
                      <img  
                src={require("../assets/logo.png") } 
                style={{
                  visibility: open ? 'hidden':'visible',
                  width:40,
                  height:40,
                  marginRight:20,
                  backgroundColor:'black'
                  
                 // width:150,
                //  height:150
                }}
              />
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"

            sx={{
              marginRight: 5,
              ...(open && { display: '' }),
            }}
          >
            <MenuIcon />
 
          </IconButton>
          <div
            style={{
                display:'flex',
                flex:1,
                flexDirection:'row',
                justifyContent:'flex-end',
                backgroundColor:'transparent'
            }}
          >
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    marginRight:10
                }}
            >
                <NotificationsNoneIcon />
                
            </div>
            {
                opcionSeleccionada=='mapa' ? (
                    <div
                        style={{
                            display:'flex',
                            flex:1,
                            flexDirection:'row',
                            backgroundColor:'transparent',
                            right:20
                        }}
                    >
                        <FiltrosMapa />

                    </div>
                ):null
            }
            <div
                style={{
                    display:'flex',
                    flexDirection:'column'
                }}
            >
                <div 
                    style={{
                        fontSize:14,
                        color:'black'
                    }}
                >
                        {usuario?.nombre+' '+usuario?.apellidoPaterno+' '+usuario?.apellidoMaterno}
                </div>
                <div 
                    style={{
                        opacity:0.4, 
                        fontSize:12,
                        display:'flex',
                        flexDirection:'row',
                        flex:1,
                        justifyContent:'flex-end'
                    }}
                >
                    {
                      usuario?.Usuarios_IdTipoUsuario=='3' ? (
                        <Typography style={{fontSize:11, color:'black'}}>Admin</Typography>
                      )
                      : (
                        <Typography style={{fontSize:11, color:'black'}}>Usuario</Typography>

                      )

                    }
                </div>
            </div>
            <div
                style={{
                    marginLeft:0
                }}
            >
                <Avatar>
                    <Person />
                </Avatar>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer style={{backgroundColor:'#2B7CAF', }} variant="permanent" open={open}>
        <DrawerHeader
            style={{backgroundColor:'#2B7CAF', justifyContent:'center'}}
        >
            <div
                style={{
                    //padding:20,
                    backgroundColor:'#2B7CAF'
                }}
            >
          <div
            style={{
                display:'flex',
                flex:1,
                backgroundColor:'white',
                justifyContent:'center',
                borderRadius:30,
                padding:5,
                marginTop:5,
                paddingLeft:30,
                paddingRight:30
            }}
          >
            <img  
                src={require("../assets/logo.png") } 
                style={{
                  width:70,
                  //height:30
                }}
              />

          </div>
          </div>
          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton> */}
        </DrawerHeader>
        {/* <Divider /> */}
        <List
            style={{
                backgroundColor:'#2B7CAF',
                height:'100vh'
//                marginTop:30
            }}
        >
            {
                (usuario?.permisos)?.substr(0,1)==1 ? (
            <div
                style={{
                    backgroundColor: opcionSeleccionada=='calendario' ?'#03ABC6':'transparent',
                    marginLeft:40,
                    borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                }}
            >
                <ListItem 
                    style={{
                        backgroundColor: opcionSeleccionada=='calendario' ?'#03ABC6':'transparent',
                        marginTop:30,
                        borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                    }}

                key='calendario' disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={(e) => { setOpcionSeleccionada('calendario'); navigate("/calendario") }}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                >
                    <ListItemText style={{ color:'white'}} primary='Calendario' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </ListItem>
            </div>
                ):null
            }
           {
                (usuario?.permisos)?.substr(1,1)==1 ? (            
            <div
                style={{
                    backgroundColor: opcionSeleccionada=='escuelasDeportivas' ?'#03ABC6':'transparent',
                    marginLeft:40,
                    borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                }}
            >
                <ListItem 
                    style={{
                        backgroundColor: opcionSeleccionada=='escuelasDeportivas' ?'#03ABC6':'transparent',
                        marginTop:10,
                        borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                    }}

                key='escuelasDeportivas' disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={(e) => { setOpcionSeleccionada('escuelasDeportivas'); navigate("/escuelasDeportivas") }}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                >
                    <ListItemText style={{ color:'white'}} primary='Escuelas deportivas' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </ListItem>
            </div>
                ):null
            }
           {
                (usuario?.permisos)?.substr(2,1)==1 ? (                      
            <div
                style={{
                    backgroundColor: opcionSeleccionada=='tiendaDeporti' ?'#03ABC6':'transparent',
                    marginLeft:40,
                    borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                }}
            >
                <ListItem 
                    style={{
                        backgroundColor: opcionSeleccionada=='tiendaDeporti' ?'#03ABC6':'transparent',
                        marginTop:10,
                        borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                    }}

                key='tiendaDeporti' disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={(e) => { setOpcionSeleccionada('tiendaDeporti'); navigate("/tiendaDeporti") }}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                >
                    <ListItemText style={{ color:'white'}} primary='Tienda Deporti' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </ListItem>
            </div>
                ):null
}
           {
                (usuario?.permisos)?.substr(15,1)==1 ? (                      
            <div
                style={{
                    backgroundColor: opcionSeleccionada=='entregaArticulos' ?'#03ABC6':'transparent',
                    marginLeft:40,
                    borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                }}
            >
                <ListItem 
                    style={{
                        backgroundColor: opcionSeleccionada=='entregaArticulos' ?'#03ABC6':'transparent',
                        marginTop:10,
                        borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                    }}

                key='tiendaDeporti' disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={(e) => { setOpcionSeleccionada('entregaArticulos'); navigate("/entregaArticulos") }}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                >
                    <ListItemText style={{ color:'white'}} primary='Entrega de artículos' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </ListItem>
            </div>
                ):null
}
{
                (usuario?.permisos)?.substr(3,1)==1 ? (          
            <div
                style={{
                    backgroundColor: opcionSeleccionada=='ligasDeportivas' ?'#03ABC6':'transparent',
                    marginLeft:40,
                    borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                }}
            >
                <ListItem 
                    style={{
                        backgroundColor: opcionSeleccionada=='ligasDeportivas' ?'#03ABC6':'transparent',
                        marginTop:10,
                        borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                    }}

                key='ligasDeportivas' disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={(e) => { setOpcionSeleccionada('ligasDeportivas'); navigate("/ligasDeportivas") }}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                >
                    <ListItemText style={{ color:'white'}} primary='Ligas deportivas' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </ListItem>
            </div>
                ):null
            }
           {
                (usuario?.permisos)?.substr(9,1)==1 ? (                      
            <div
                style={{
                    backgroundColor: opcionSeleccionada=='torneosDeportivos' ?'#03ABC6':'transparent',
                    marginLeft:40,
                    borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                }}
            >
                <ListItem 
                    style={{
                        backgroundColor: opcionSeleccionada=='torneosDeportivos' ?'#03ABC6':'transparent',
                        marginTop:10,
                        borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                    }}

                key='torneosDeportivos' disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={(e) => { setOpcionSeleccionada('torneosDeportivos'); navigate("/torneosDeportivos") }}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                >
                    <ListItemText style={{ color:'white'}} primary='Torneos deportivos' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </ListItem>
            </div>
                ):null
}
{
                (usuario?.permisos)?.substr(4,1)==1 ? (          
            <div
                style={{
                    backgroundColor: opcionSeleccionada=='recintosDeportivos' ?'#03ABC6':'transparent',
                    marginLeft:40,
                    borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                }}
            >
                <ListItem 
                    style={{
                        backgroundColor: opcionSeleccionada=='recintosDeportivos' ?'#03ABC6':'transparent',
                        marginTop:10,
                        borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                    }}

                key='recintosDeportivos' disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={(e) => { setOpcionSeleccionada('recintosDeportivos'); navigate("/recintosDeportivos") }}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                >
                    <ListItemText style={{ color:'white'}} primary='Recintos deportivos' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </ListItem>
            </div>
                ):null
}
{
                (usuario?.permisos)?.substr(5,1)==1 ? (          
            <div
                style={{
                    backgroundColor: opcionSeleccionada=='deportes' ?'#03ABC6':'transparent',
                    marginLeft:40,
                    borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                }}
            >
                <ListItem 
                    style={{
                        backgroundColor: opcionSeleccionada=='deportes' ?'#03ABC6':'transparent',
                        marginTop:10,
                        borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                    }}

                key='recintosDeportivos' disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={(e) => { setOpcionSeleccionada('deportes'); navigate("/deportes") }}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                >
                    <ListItemText style={{ color:'white'}} primary='Deportes/Disciplinas' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </ListItem>
            </div>
                ):null
}
{
                (usuario?.permisos)?.substr(6,1)==1 ? (          
            <div
                style={{
                    backgroundColor: opcionSeleccionada=='usuarios' ?'#03ABC6':'transparent',
                    marginLeft:40,
                    borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                }}
            >
                <ListItem 
                    style={{
                        backgroundColor: opcionSeleccionada=='usuarios' ?'#03ABC6':'transparent',
                        marginTop:10,
                        borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                    }}

                key='mapa' disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={(e) => { setOpcionSeleccionada('usuarios'); navigate("/usuarios") }}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                >
                    <ListItemText style={{ color:'white'}} primary='Usuarios' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </ListItem>
            </div>
                ):null
}
{
                (usuario?.permisos)?.substr(7,1)==1 ? (          
            <div
                style={{
                    backgroundColor: opcionSeleccionada=='mapa' ?'#03ABC6':'transparent',
                    marginLeft:40,
                    borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                }}
            >
                <ListItem 
                    style={{
                        backgroundColor: opcionSeleccionada=='mapa' ?'#03ABC6':'transparent',
                        marginTop:10,
                        borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                    }}

                key='mapa' disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={(e) => { setOpcionSeleccionada('mapa'); navigate("/mapa") }}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                >
                    <ListItemText style={{ color:'white'}} primary='Mapa' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </ListItem>
            </div>
                ):null
}
{
                (usuario?.permisos)?.substr(16,1)==1 ? (          
            <div
                style={{
                    backgroundColor: opcionSeleccionada=='reportes' ?'#03ABC6':'transparent',
                    marginLeft:40,
                    borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                }}
            >
                <ListItem 
                    style={{
                        backgroundColor: opcionSeleccionada=='reportes' ?'#03ABC6':'transparent',
                        marginTop:10,
                        borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                    }}

                key='mapa' disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={(e) => { setOpcionSeleccionada('reportes'); navigate("/reportes") }}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                >
                    <ListItemText style={{ color:'white'}} primary='Reportes' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </ListItem>
            </div>
                ):null
}
<div
                style={{
                    backgroundColor: opcionSeleccionada=='cerrar' ?'#03ABC6':'transparent',
                    marginLeft:40,
                    borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                }}
            >
                <ListItem 
                    style={{
                        backgroundColor: opcionSeleccionada=='cerrar' ?'#03ABC6':'transparent',
                        marginTop:10,
                        borderTopLeftRadius:20,
                    borderBottomLeftRadius:20
                    }}

                key='mapa' disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={(e) => { localStorage.clear('logueado'); localStorage.clear(usuario); setLogueado(false); navigate("/") }}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                >
                    <ListItemText style={{ color:'white'}} primary='Cerrar sesión' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </ListItem>
            </div>
  
        </List>
      </Drawer>
      <Box style={{
        backgroundColor:'white',
        display:'flex',
        flexDirection:'column',
        flex:1,
        height:'100vh'

      }} component="main" sx={{ flexGrow: 1, justifyContent:'left' }}>
        <DrawerHeader />
      <Routes>
            <Route exact path="/" element={<InicialPage />} />
            {/* <Route exact path="/login" element={<LoginPage />} /> */}
            <Route exact path="/calendario" element={<CalendarioPage />} />
            <Route exact path="/escuelasDeportivas" element={<EscuelasDeportivasPage />} />
            <Route exact path="/tiendaDeporti" element={<TiendaDeportiPage />} />
            <Route exact path="/entregaArticulos" element={<EntregaArticulosPage />} />
            <Route exact path="/ligasDeportivas" element={<LigasDeportivasPage />} />
            <Route exact path="/torneosDeportivos" element={<TorneosProvider><TorneosDeportivosPage /></TorneosProvider>} />
            <Route exact path="/recintosDeportivos" element={<RecintosDeportivosPage />} />
            <Route exact path="/deportes" element={<DeportesPage />} />
            <Route exact path="/mapa" element={<UsuariosProvider><MapaPage /></UsuariosProvider>} />
            <Route exact path="/reportes" element={<ReportesPage />} />
            <Route exact path="/usuarios" element={<UsuariosProvider><UsuariosPage /></UsuariosProvider>} />
            {/* <Route exact path="/reportes" element={<ReportesPage />} />
            <Route exact path="/ciudad" element={<CiudadPage />} />
            <Route exact path="/ciudadanos" element={<CiudadanosPage />} />
            <Route exact path="/servicios" element={<ServiciosPage />} />
            <Route exact path="/tramites" element={<TramitesPage />} />
            <Route exact path="/sectores" element={<SectoresPage />} /> */}
        </Routes> 
      </Box>
    </Box>
  );
}