import { AddCircle, BadgeOutlined, Delete, DocumentScannerOutlined, Edit, Email, EmailOutlined, Facebook, Image, ImageSearch, Instagram, LocationCity, LocationCityTwoTone, PermIdentity, Person2, Person2Outlined, Phone } from '@mui/icons-material';
import { Badge, Button, Card, CardContent, CardHeader, Divider, IconButton, Typography } from '@mui/material'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2';
import { ModalAgregaDeporteComponent } from '../components/deportes/ModalAgregaDeporte';
import { ModalAgregaClase } from '../components/escuelas/ModalAgregaClase';
import { ModalAgregaEscuela } from '../components/escuelas/ModalAgregaEscuela';
import { TablaClases } from '../components/escuelas/TablaClases';
import { ObtenerDatos } from '../helpers/ApiHelper';
import logo from '../assets/logo.png'

export const EscuelasContext = createContext();

export const EscuelasDeportivasPage = () => {
    const [escuelas, setEscuelas] = useState();
  const [modalAgregaEscuela, setModalAgregaEscuela] = useState(false);
  const [modalAgregaClase, setModalAgregaClase] = useState(false)
  const [escuelaSeleccionada, setEscuelaSeleccionada] = useState();
  const [claseSeleccionada, setClaseSeleccionada] = useState();
  const [clases, setClases] = useState();
  const uploadInputRef = useRef(null);
    const imgMilitante = useRef(null);
    const [file, setFile] = useState()
  const uploadInputRef1 = useRef(null);
  const uploadInputRef2 = useRef(null);
  const uploadInputRef3 = useRef(null);
  const uploadInputRef4 = useRef(null);
  const uploadInputRef5 = useRef(null);
    const imgMilitante1 = useRef(null);
    const [file1, setFile1] = useState()
    const imgMilitante2 = useRef(null);
    const [file2, setFile2] = useState()
    const imgMilitante3 = useRef(null);
    const [file3, setFile3] = useState()
    const imgMilitante4 = useRef(null);
    const [file4, setFile4] = useState()
    const imgMilitante5 = useRef(null);
    const [file5, setFile5] = useState()

    const refForm = useRef();


  useEffect(() => {
    obtenerEscuelas();
  }, [])


  const SeleccionImagen = (event) => {
    setFile({file:event.target.files[0]});
    console.log('selecciono imagen',event);
    var reader = new FileReader();
    reader.onload = function(){
        imgMilitante.current.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
};

useEffect(() => {
  console.log('state file', file);
  const data= new FormData();
  data.append('archivo', uploadInputRef.current?.files[0]);
  data.append('idEscuela', escuelaSeleccionada?.idEscuela);
  fetch(process.env.REACT_APP_API_URL+'/escuelas.php?funcion=subirLogo', {
    method:'POST',
    body:data
}).then(response => {
  obtenerEscuelas();
})

}, [file])

useEffect(() => {
  console.log('state file', file1);
  const data= new FormData();
  data.append('archivo', uploadInputRef1.current?.files[0]);
  data.append('idEscuela', escuelaSeleccionada?.idEscuela);
  fetch(process.env.REACT_APP_API_URL+'/escuelas.php?funcion=subirIne', {
    method:'POST',
    body:data
})

}, [file1])

useEffect(() => {
  console.log('state file', file2);
  const data= new FormData();
  data.append('archivo', uploadInputRef2.current?.files[0]);
  data.append('idEscuela', escuelaSeleccionada?.idEscuela);
  fetch(process.env.REACT_APP_API_URL+'/escuelas.php?funcion=subirDescargo', {
    method:'POST',
    body:data
})

}, [file2])

useEffect(() => {
  console.log('state file', file3);
  const data= new FormData();
  data.append('archivo', uploadInputRef3.current?.files[0]);
  data.append('idEscuela', escuelaSeleccionada?.idEscuela);
  fetch(process.env.REACT_APP_API_URL+'/escuelas.php?funcion=subirCurriculumDeportivo', {
    method:'POST',
    body:data
})

}, [file3])

useEffect(() => {
  console.log('state file', file4);
  const data= new FormData();
  data.append('archivo', uploadInputRef4.current?.files[0]);
  data.append('idEscuela', escuelaSeleccionada?.idEscuela);
  fetch(process.env.REACT_APP_API_URL+'/escuelas.php?funcion=subirListadoAtletas', {
    method:'POST',
    body:data
})

}, [file4])

useEffect(() => {
  console.log('state file', file5);
  const data= new FormData();
  data.append('archivo', uploadInputRef5.current?.files[0]);
  data.append('idEscuela', escuelaSeleccionada?.idEscuela);
  fetch(process.env.REACT_APP_API_URL+'/escuelas.php?funcion=subirPlanEntrenamiento', {
    method:'POST',
    body:data
})

}, [file5])

  
  const SeleccionImagen1 = (event) => {
    setFile1({file:event.target.files[0]});
    console.log('selecciono imagen 1',event);
    var reader = new FileReader();
    reader.onload = function(){
        imgMilitante1.current.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
};
  const SeleccionImagen2 = (event) => {
    setFile2({file:event.target.files[0]});
    console.log('selecciono imagen 2',event);
    var reader = new FileReader();
    reader.onload = function(){
        imgMilitante2.current.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
};
  const SeleccionImagen3 = (event) => {
    setFile3({file:event.target.files[0]});
    console.log('selecciono imagen 3',event);
    var reader = new FileReader();
    reader.onload = function(){
        imgMilitante3.current.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
};
  const SeleccionImagen4 = (event) => {
    setFile4({file:event.target.files[0]});
    console.log('selecciono imagen 3',event);
    var reader = new FileReader();
    reader.onload = function(){
        imgMilitante4.current.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
};
  
  const SeleccionImagen5 = (event) => {
    setFile5({file:event.target.files[0]});
    console.log('selecciono imagen 5',event);
    var reader = new FileReader();
    reader.onload = function(){
        imgMilitante5.current.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
};
  
  const obtenerEscuelas = () => {
    ObtenerDatos('/escuelas.php?funcion=obtenerEscuelas', null).then(response => {
      setEscuelas(response);
      console.log('escuelas', response);
    });
  }

  useEffect(() => {
    console.log(escuelaSeleccionada)
  }, [escuelaSeleccionada])
  
  
  const columna = (titulo, ancho, fondo, anchoLetra, escuela) => {
    return (
      <Typography
      style={{
        flex:ancho,
        fontWeight:anchoLetra,
        fontSize:14,
        paddingLeft:5,
        paddingRight:5,
        padding:5,
        color:escuela?.idEscuela == escuelaSeleccionada?.idEscuela ? 'white':'black',
        backgroundColor:escuela?.idEscuela == escuelaSeleccionada?.idEscuela ? fondo:'white'
      }}
    >
      {titulo}
    </Typography>
  
    )
  }

  const columnaTitulo = (titulo, ancho, fondo, anchoLetra, escuela) => {
    return (
      <Typography
      style={{
        flex:ancho,
        fontWeight:anchoLetra,
        fontSize:14,
        paddingLeft:5,
        paddingRight:5,
        padding:5,
        color:escuela?.idEscuela == escuelaSeleccionada?.idEscuela ? 'white':'black',
        backgroundColor:fondo
      }}
    >
      {titulo}
    </Typography>
  
    )
  }

  const eliminarEscuela = (idEscuela) => {
    Swal.fire({
      title: '¿Estás seguro(a)?',
      text: "Se eliminará la escuela y sus clases, esto no puede ser revertido",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = new FormData();
        data.append('idEscuela', idEscuela)
        ObtenerDatos('/escuelas.php?funcion=eliminarEscuela', data).then(response => {
          if(response==1) {
            Swal.fire(
              'Eliminado!',
              'La escuela ha sido eliminada.',
              'success'
            )
            obtenerEscuelas();
          } else {
            Swal.fire(
              'Error!',
              'La escuela no pudo ser eliminada!.',
              'error'
            )

          }
        })
      }
    })
  }

  const obtenerClases = () => {
    const data = new FormData();
    data.append('idEscuela', escuelaSeleccionada?.idEscuela)
    ObtenerDatos('/escuelas.php?funcion=obtenerClasesEscuela', data).then(response => {
        console.log('clases de la escuela', response)
        setClases(response)
    })
}

  return (
    <EscuelasContext.Provider value={{modalAgregaEscuela, setModalAgregaEscuela, obtenerEscuelas, escuelaSeleccionada, modalAgregaClase, setModalAgregaClase, claseSeleccionada, setClaseSeleccionada, clases,setClases, obtenerClases}} >
    <>
    <div
      style={{
        marginLeft:20,
        marginTop:20,
        marginRight:20,
        display:'flex',
        flexDirection:'row',
        flex:5,
        justifyContent:'space-between',
        alignItems:'center'
      }}
    > 
      <Typography
        style={{
          fontSize:20,
          fontWeight:600
        }}
      >
        Escuelas de Iniciación Deportiva
      </Typography>
      <Button
        onClick={() => {setEscuelaSeleccionada(); setModalAgregaEscuela(true)}}
        variant="contained"
      >
        Agregar
      </Button>
    </div>
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        flex:95
      }}
    >
    <div
      style={{
        marginLeft:20,
        marginTop:20,
        display:'flex',
        flexDirection:'column',
        flex:60,
        minWidth:'40%'
      }}
    >
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              width:'100%',
              backgroundColor:'red'
              //flex:1
            }}
          >
            {columnaTitulo('Nombre de Escuela',20,'#03ABC6',500)}
            {columnaTitulo('Deporte/Disciplina',20,'#03ABC6',500)}
            {columnaTitulo('Ciudad',20,'#03ABC6',500)}
            {columnaTitulo('',5, '#03ABC6',500)}
          </div>

      {
        escuelas?.map(escuela => (
          <div
            onClick={() => {
              setEscuelaSeleccionada(escuela)
            }}
            style={{
              display:'flex',
              flexDirection:'row',
              //flex:1
            }}
          >
            {columna(escuela.nombre,20,'#03ABC6',400, escuela)}
            {columna(escuela.deporte,20,'#03ABC6',400, escuela)}
            {columna(escuela.nombreCiudad,20,'#03ABC6',400, escuela)}
            <div
              style={{
                display:'flex',
                flex:5,
                fontWeight:500,
                color:escuela?.idEscuela == escuelaSeleccionada?.idEscuela ? 'white':'black',
                backgroundColor:escuela?.idEscuela == escuelaSeleccionada?.idEscuela ? '#03ABC6':'white'
              }}
            >
            <IconButton
              onClick={() => {setEscuelaSeleccionada(escuela); setModalAgregaEscuela(true)}}
            >
              <Edit style={{color:escuela?.idEscuela == escuelaSeleccionada?.idEscuela ? 'white':'green',}} />
            </IconButton>
            <IconButton
              onClick={() => eliminarEscuela(escuela.idEscuela)}
            >
              <Delete style={{color:escuela?.idEscuela == escuelaSeleccionada?.idEscuela ? 'white':'red',}} />
            </IconButton>
            </div>
          </div>
        ))
      }
    </div>
    <div
      style={{
        marginLeft:20,
        marginTop:20,
        display:'flex',
        flexDirection:'column',
        flex:40,
        minWidth:'40%'
      }}
    >
      <Card>
        <CardContent
          style={{
            color:'black'
          }}
        >
          <Typography style={{color:'#03ABC6'}}>
          {escuelaSeleccionada?.nombre}
          </Typography>
          <Divider />
          <div
            style={{
              display:'flex',
              flexDirection:'row'
            }}
          >
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:80,
                minWidth:'50%'
              }}
            >
              <Typography
                style={{fontSize:12, marginTop:20, display:'flex', flexDirection:'row', alignItems:'center',}}
              >
              <Person2  style={{fontSize:16, color:'#03ABC6'}} /> {escuelaSeleccionada?.nombreResponsable} / {escuelaSeleccionada?.cargoResponsable}
              </Typography>
              <Typography
                style={{fontSize:12, marginTop:0, display:'flex', flexDirection:'row', alignItems:'center'}}
              >
                <Facebook style={{fontSize:16, color:'#3477eb'}} /> {escuelaSeleccionada?.facebook}
              </Typography>
              <Typography
                style={{fontSize:12, marginTop:0, display:'flex', flexDirection:'row', alignItems:'center'}}
              >
                <Instagram style={{fontSize:16, color:'blueviolet'}} /> {escuelaSeleccionada?.instagram}
              </Typography>
              <Typography
                style={{fontSize:12, marginTop:0, display:'flex', flexDirection:'row', alignItems:'center'}}
              >
                <EmailOutlined style={{fontSize:16, color:'blue'}} /> {escuelaSeleccionada?.correo}
              </Typography>
              <Typography
                style={{fontSize:12, marginTop:0, display:'flex', flexDirection:'row', alignItems:'center'}}
              >
                <Phone style={{fontSize:16, color:'#03ABC6'}} /> {escuelaSeleccionada?.telefono}
              </Typography>
              <Typography
                style={{fontSize:12, marginTop:0, display:'flex', flexDirection:'row', alignItems:'center'}}
              >
                <LocationCityTwoTone style={{fontSize:16, color:'#03ABC6'}} /> {escuelaSeleccionada?.nombreCiudad}
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card
        style={{
          marginTop:20
        }}
      >
        <CardContent
          style={{
            color:'black'
          }}
        >
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'space-between'
            }}
          >
            <Typography style={{color:'#03ABC6'}}>
              Información de clases
            </Typography>
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center'
              }}
            >
              <Button
                onClick={()=> {setClaseSeleccionada();setModalAgregaClase(true)}}
                style={{
                  textTransform:'unset'
                }}
              >
                <Typography style={{fontSize:13}}>Agregar</Typography>  <AddCircle style={{color:'#03ABC6', fontSize:16}} />
              </Button>
            </div>
            </div>
            <Divider />
            <div
              style={{
                display:'flex',
                flexDirection:'row'
              }}
            >
              <TablaClases />
            </div>
 
        </CardContent>
      </Card>
      {
        escuelaSeleccionada?.idEscuela ? (
      <Card
        style={{
          marginTop:20
        }}
      >
        <CardContent>
          <div
            style={{
              display:'flex',
              flexWrap:'wrap',
              flexDirection:'row',
              alignItems:'flex-end',
              overflowX:'auto'
            }}
          >

        <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:10,
                alignItems:'center',
                justifyContent:'flex-end',
                maxWidth:'20%'
              }}
            >
              <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden'}} accept='.jpg,.png' type="file" />
              <img style={{
                width:50,
                height:'auto'
              }} ref={imgMilitante} src={escuelaSeleccionada?.imagenLogo ? process.env.REACT_APP_API_URLIMAGENES+'/escuelas/'+escuelaSeleccionada?.imagenLogo : logo} />
                {/* {
                  !file?.file ? (
                <Image style={{width:'70%', height:'auto', color:'gray'}} />
                  ):null
                } */}
              <Button
                style={{
                  textTransform:'unset',
                  maxWidth:140,
                  minWidth:'30%',
                  marginTop:20,
                  fontSize:10
                }}
                variant="contained"
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                >Logo</Button>
            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:10,
                alignItems:'center',
                justifyContent:'flex-end',
                maxWidth:'25%'
              }}
            >
              <input name="imgMilitante1" onChange={event => SeleccionImagen1(event) } ref={uploadInputRef1} style={{visibility:'hidden'}} accept='.jpg,.png' type="file" />
              <img style={{
                width:60,
                height:'auto'
              }} ref={imgMilitante1} src={escuelaSeleccionada?.imagenIne ? process.env.REACT_APP_API_URLIMAGENES+'/escuelas/'+escuelaSeleccionada?.imagenIne : logo} />
                {/* {
                  !file1?.file ? (
                <Image style={{width:'70%', height:'auto', color:'gray'}} />
                  ):null
                } */}
              <Button
                style={{
                  textTransform:'unset',
                  maxWidth:140,
                  minWidth:'40%',
                  marginTop:20,
                  fontSize:10,
                  
                }}
                variant="contained"
                onClick={() => uploadInputRef1.current && uploadInputRef1.current.click()}
                >Identificación Oficial</Button>
            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:7,
                alignItems:'center',
                justifyContent:'flex-end',
                maxWidth:'30%'
              }}
            >
              <input name="imgMilitante2" onChange={event => SeleccionImagen2(event) } ref={uploadInputRef2} style={{visibility:'hidden'}} accept='.jpg,.png' type="file" />
              <img style={{
                width:50,
                height:'auto'
              }} ref={imgMilitante2} src={escuelaSeleccionada?.imagenDescargo ? process.env.REACT_APP_API_URLIMAGENES+'/escuelas/'+escuelaSeleccionada?.imagenDescargo : logo} />
                {/* {
                  !file2?.file ? (
                <Image style={{width:'70%', height:'auto', color:'gray'}} />
                  ):null
                } */}
              <Button
                style={{
                  textTransform:'unset',
                  maxWidth:140,
                  minWidth:'30%',
                  marginTop:20,
                  fontSize:10
                }}
                variant="contained"
                onClick={() => uploadInputRef2.current && uploadInputRef2.current.click()}
                >Descargo</Button>
            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:7,
                alignItems:'center',
                justifyContent:'flex-end',
                maxWidth:'30%'
              }}
            >
              <input name="imgMilitante3" onChange={event => SeleccionImagen3(event) } ref={uploadInputRef3} style={{visibility:'hidden'}} accept='.jpg,.png' type="file" />
              <img style={{
                width:50,
                height:'auto'
              }} ref={imgMilitante3} src={escuelaSeleccionada?.imagenCurriculumDeportivo ? process.env.REACT_APP_API_URLIMAGENES+'/escuelas/'+escuelaSeleccionada?.imagenCurriculumDeportivo : logo} />
                {/* {
                  !file2?.file ? (
                <Image style={{width:'70%', height:'auto', color:'gray'}} />
                  ):null
                } */}
              <Button
                style={{
                  textTransform:'unset',
                  maxWidth:140,
                  minWidth:'30%',
                  marginTop:20,
                  fontSize:10,
                }}
                variant="contained"
                onClick={() => uploadInputRef3.current && uploadInputRef3.current.click()}
                >Curr. Deportivo</Button>
            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:7,
                alignItems:'center',
                justifyContent:'flex-end',
                maxWidth:'30%'
              }}
            >
              <input name="imgMilitante4" onChange={event => SeleccionImagen4(event) } ref={uploadInputRef4} style={{visibility:'hidden'}} accept='.jpg,.png' type="file" />
              <img style={{
                width:50,
                height:'auto'
              }} ref={imgMilitante4} src={escuelaSeleccionada?.imagenListadoAtletas ? process.env.REACT_APP_API_URLIMAGENES+'/escuelas/'+escuelaSeleccionada?.imagenListadoAtletas : logo} />
                {/* {
                  !file2?.file ? (
                <Image style={{width:'70%', height:'auto', color:'gray'}} />
                  ):null
                } */}
              <Button
                style={{
                  textTransform:'unset',
                  maxWidth:140,
                  minWidth:'30%',
                  marginTop:20,
                  fontSize:10
                }}
                variant="contained"
                onClick={() => uploadInputRef4.current && uploadInputRef4.current.click()}
                >Listado Atletas</Button>
            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:7,
                alignItems:'center',
                justifyContent:'flex-end',
                maxWidth:'30%'
              }}
            >
              <input name="imgMilitante5" onChange={event => SeleccionImagen5(event) } ref={uploadInputRef5} style={{visibility:'hidden'}} accept='.jpg,.png' type="file" />
              <img style={{
                width:50,
                height:'auto'
              }} ref={imgMilitante5} src={escuelaSeleccionada?.imagenPlanEntrenamiento ? process.env.REACT_APP_API_URLIMAGENES+'/escuelas/'+escuelaSeleccionada?.imagenPlanEntrenamiento : logo} />
                {/* {
                  !file2?.file ? (
                <Image style={{width:'70%', height:'auto', color:'gray'}} />
                  ):null
                } */}
              <Button
                style={{
                  textTransform:'unset',
                  maxWidth:140,
                  minWidth:'30%',
                  marginTop:20,
                  fontSize:10
                }}
                variant="contained"
                onClick={() => uploadInputRef5.current && uploadInputRef5.current.click()}
                >Plan Entrenamiento</Button>
            </div>
            </div>

        </CardContent>
      </Card>
        ):null
      }
    </div>
    </div>
    </>
    <ModalAgregaEscuela />
    <ModalAgregaClase />
    </EscuelasContext.Provider>
  )
}

