import { CameraAlt } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Typography } from '@mui/material'
import dayjs from 'dayjs';
import React, { useContext, useEffect } from 'react'
import { UsuariosContext } from '../../context/UsuariosContext'

export const ModalTablaApoyos = () => {
    const {modalTablaApoyos, setModalTablaApoyos, obtenerApoyos, datosApoyos, apoyoSeleccionado, setApoyoSeleccionado, setModalImagenApoyo} = useContext(UsuariosContext);

    const handleClose = () => {
        setModalTablaApoyos(false);
    }

    useEffect(() => {
        if(modalTablaApoyos) {
            obtenerApoyos();
        }
    }, [modalTablaApoyos])
    

  return (
    <Dialog
        maxWidth='md'
        open={modalTablaApoyos}
        onClose={handleClose}
        fullWidth
    >
        <DialogContent>
            <div
                style={{
                    display:'flex',
                    flexDirection:'column'
                }}
            >
                <div
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        backgroundColor:'#03ABC6',
                        color:'white',
                        borderRadius:10,
                        padding:10,
                        fontWeight:600
                    }}
                >
                    <Typography style={{display:'flex', flex:5}}>Fecha</Typography>
                    <Typography style={{display:'flex', flex:5}}>Autorizó</Typography>
                    <Typography style={{display:'flex', flex:10}}>Apoyo</Typography>
                    <Typography style={{display:'flex', flex:10}}>Comentarios</Typography>
                    <Typography style={{display:'flex', flex:2}}></Typography>
                </div>
                {
                    datosApoyos?.map(apoyo => (
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                backgroundColor:'white',
                                color:'black',
                                borderRadius:10,
                                padding:10,
                                fontWeight:600,
                                fontSize:12,
                                alignItems:'center'
                            }}
                        >

                                    <Typography style={{display:'flex', flex:5, fontSize:12}}>{dayjs(apoyo?.fecha).format('DD/MM/YYYY')}</Typography>
                                    <Typography style={{display:'flex', flex:5, fontSize:12}}>{apoyo?.usuarioAutoriza}</Typography>
                                    <Typography style={{display:'flex', flex:10, fontSize:12}}>{apoyo?.apoyo}</Typography>
                                    <Typography style={{display:'flex', flex:10, fontSize:12}}>{apoyo?.comentarios}</Typography>
                                    <Typography style={{display:'flex', flex:2, fontSize:12}}>{apoyo?.imagenes?.length ? (
                                        <IconButton
                                            onClick={()=>{setApoyoSeleccionado(apoyo);setModalImagenApoyo(true)}}
                                        >
                                            <CameraAlt style={{color:'green'}} />
                                        </IconButton>)
                                        :
                                        (<IconButton><CameraAlt style={{color:'gray'}} /></IconButton>)}</Typography>
                
                        </div>
                        ))
                    }
            </div>
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button
                onClick={() => {handleClose()}}
            >
                Cerrar
            </Button>
        </DialogActions>
    </Dialog>
  )
}
