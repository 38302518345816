import { Button, Divider, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { TorneosContext } from '../../context/TorneosContext'

export const TablaPosiciones = () => {
    const {torneoSeleccionado, obtenerTablaPosiciones, tablaPosiciones} = useContext(TorneosContext)
    const uploadInputRef = useRef(null);
    const imgMilitante = useRef(null);
    const [file, setFile] = useState()

    const refForm = useRef();

    const SeleccionImagen = (event) => {
      setFile({file:event.target.files[0]});
      console.log('selecciono imagen',event);
      var reader = new FileReader();
      reader.onload = function(){
//              imgMilitante.current.src = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
  };

    useEffect(() => {
        obtenerTablaPosiciones();
    }, [torneoSeleccionado])

    useEffect(() => {
        console.log('state file', file, torneoSeleccionado);
        const data= new FormData();
        data.append('archivo', uploadInputRef.current?.files[0]);
        data.append('idTorneo', torneoSeleccionado?.idTorneoDeportivo);
        fetch(process.env.REACT_APP_API_URL+'/importar.posiciones.php', {
          method:'POST',
          body:data
      })
      .then(response => response.text())
      .then(response =>{
        console.log('respuesta del servidor', response)
      })
      
      }, [file])

    

  return (
    <div
        style={{
            display:'flex',
            flexDirection:'column',
            marginLeft:10,
            overflowY:'auto',
            maxHeight:'80vh'
        }}
    >
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              marginLeft:10,
              justifyContent:'space-between',
              alignItems:'center'
            }}
          >
            <Typography style={{fontWeight:'600'}}>Tabla de Posiciones</Typography>
            <Button
                style={{
                  textTransform:'unset'
                }}
             //   variant="contained"
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                >Actualizar</Button>
            <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden', width:1}} accept='.xls,.xlsx,.xlsm' type="file" />
          </div>        
        <div
            style={{
                display:'flex',
                flexDirection:'row'
            }}
        >
            <Typography
                style={{
                    display:'flex',
                    color:'#03ABC6',
                    fontSize:12,
                    flex:10
                }}
            >
                Pos
            </Typography>
            <Typography
                style={{
                    display:'flex',
                    color:'#03ABC6',
                    fontSize:12,
                    flex:50
                }}
            >
                Equipo
            </Typography>
            <Typography
                style={{
                    display:'flex',
                    color:'#03ABC6',
                    fontSize:12,
                    flex:10
                }}
            >
                J
            </Typography>
            <Typography
                style={{
                    display:'flex',
                    color:'#03ABC6',
                    fontSize:12,
                    flex:10
                }}
            >
                G
            </Typography>
            <Typography
                style={{
                    display:'flex',
                    color:'#03ABC6',
                    fontSize:12,
                    flex:10
                }}
            >
                E
            </Typography>
            <Typography
                style={{
                    display:'flex',
                    color:'#03ABC6',
                    fontSize:12,
                    flex:10
                }}
            >
                P
            </Typography>
            <Typography
                style={{
                    display:'flex',
                    color:'#03ABC6',
                    fontSize:12,
                    flex:10
                }}
            >
                Pts.
            </Typography>
        </div>
        <Divider 
            style={{
                backgroundColor:'lightgray'
            }}
        />
        {
            tablaPosiciones?.map(posicion => (
                <>
                <div
                    style={{
                        display:'flex',
                        flexDirection:'row'
                    }}
                >
                    <Typography style={{fontSize:12, color:'black', display:'flex', flex:10}}>{posicion.posicion}</Typography>
                    <Typography style={{fontSize:12, color:'black', display:'flex', flex:50}}>{posicion.equipo}</Typography>
                    <Typography style={{fontSize:12, color:'black', display:'flex', flex:10}}>{posicion.jugados}</Typography>
                    <Typography style={{fontSize:12, color:'black', display:'flex', flex:10}}>{posicion.ganados}</Typography>
                    <Typography style={{fontSize:12, color:'black', display:'flex', flex:10}}>{posicion.empatados}</Typography>
                    <Typography style={{fontSize:12, color:'black', display:'flex', flex:10}}>{posicion.perdidos}</Typography>
                    <Typography style={{fontSize:12, color:'black', display:'flex', flex:10}}>{posicion.puntos}</Typography>
                </div>
                <Divider style={{backgroundColor:'#f3f3f3'}} />
                </>
            ))
        }
    </div>
  )
}
