import { Button, Checkbox, FilledInput, FormControl, FormControlLabel, IconButton, Input, InputAdornment, InputLabel, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import logo from '../assets/logo.png'
import { AuthContext } from '../context/AuthContext';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Person } from '@mui/icons-material';
import { Login } from '../helpers/UsuariosHelper';


export const LoginPage = () => {
    const [password, setPassword] = useState();
    const {usuario, setUsuario, logueado, setLogueado} = useContext(AuthContext);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
  
      const login =() => {
          Login(usuario,password).then(response => {
            if(response?.idUsuario) {
                setLogueado(true)
                setUsuario(response)

            }
          });
      }
    
  return (
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            flex:1,
            height:'100vh',
            backgroundColor:'transparent'
        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex:1,
                backgroundColor:'transparent',
                justifyContent:'center',
                alignItems:'center'
            }}
        >
            <div>
                <img 
                style={{
                    width:354,

                }}
                src={logo}
                />
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:30,
                                borderRadius:20
                            }}
                        >
                            <FilledInput
                                placeholder='Username'
                                disableUnderline
                                onChange={(e) => setUsuario(e.target.value)}
                                label="Username"
                                style={{
                                    display:'flex',
                                    flex:1,
                                    maxWidth:'100%',
                                    borderRadius:20,
                                    backgroundColor:'#F6F6F6',
                                    padding:10
                                }}
                                inputProps={{
                                    style:{
                                        borderRadius:10,
                                        backgroundColor:'#F6F6F6'
                                    }
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        >
                                        <Person />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                size='small' variant="filled" />
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:30, borderRadius:20
                            }}
                        >
                            <FormControl
                                style={{
                                    display:'flex',
                                    flex:1,
                                    maxWidth:'100%'

                                }}
                                placeholder="Password"
                                sx={{ m: 1, width: '25ch' }} variant="filled">
                            {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                            <FilledInput
                                                      style={{
                                                        display:'flex',
                                                        flex:1,
                                                        maxWidth:'100%',
                                                        borderRadius:20,
                                                        backgroundColor:'#F6F6F6',
                                                        padding:10
                                                    }}
                                                    inputProps={{
                                                        style:{
                                                            borderRadius:10,
                                                            backgroundColor:'#F6F6F6'
                                                        }
                                                    }}                                                    
                                disableUnderline
                                onChange={(e) => setPassword(e.target.value)}
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
//                                label="Password"
                            />
                            </FormControl>
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:5,
                            }}
                        >
                             {/* <FormControlLabel control={<Checkbox size='small'  />} label={<Typography style={{fontSize:12}}>Mantener sesión</Typography>} />
                             <Button
                             
                                style={{
                                    textTransform:'unset',
                                    color:'#642644',
                                }}
                             >¿Olvidaste tu contraseña?</Button> */}
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:5,
                            }}
                        >
                             <Button
                             onClick={() => login()}
                                style={{
                                    textTransform:'unset',
                                    backgroundColor:'black',
                                    color:'white',
                                    minWidth:'100%',
                                    borderRadius:30,
                                    marginTop:20,
                                    padding:15
                                }}
                             >
                                Login to Continue
                             </Button>
                        </div>
            </div>
        </div>
    </div>
  )
}
