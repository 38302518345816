import { Delete, Edit } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material'
import React, { createContext, useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { ModalAgregaRecintoComponent } from '../components/recintos/ModalAgregaRecinto';
import { ObtenerDatos } from '../helpers/ApiHelper';

export const RecintosContext = createContext();

export const RecintosDeportivosPage = () => {
  const [recintos, setRecintos] = useState();
  const [modalAgregaRecinto, setModalAgregaRecinto] = useState(false);
  const [recintoSeleccionado, setRecintoSeleccionado] = useState();

  useEffect(() => {
    obtenerRecintos();
  }, [])
  
  const obtenerRecintos = () => {
    ObtenerDatos('/recintos.php?funcion=obtenerRecintos', null).then(response => {
      setRecintos(response);
      console.log('recintos', response);
    });
  }

  const eliminarRecinto = (idRecinto) => {
    Swal.fire({
      title: '¿Estás seguro(a)?',
      text: "Solo se pueden eliminar recintos que no han sido utilizados en el sistema",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = new FormData();
        data.append('idRecinto', idRecinto)
        ObtenerDatos('/recintos.php?funcion=eliminarRecinto', data).then(response => {
          if(response==1) {
            Swal.fire(
              'Eliminado!',
              'El recinto ha sido eliminado.',
              'success'
            )
            obtenerRecintos();
          } else {
            Swal.fire(
              'Error!',
              'El recinto no puede ser eliminado!.',
              'error'
            )

          }
        })
      }
    })
  }
  
  const columna = (titulo, ancho, fondo, anchoLetra, recinto) => {
    return (
      <Typography
      style={{
        flex:ancho,
        fontWeight:anchoLetra,
        fontSize:14,
        paddingLeft:5,
        paddingRight:5,
        padding:5,
        color:recinto?.idRecinto == recintoSeleccionado?.idRecinto ? 'white':'black',
        backgroundColor:recinto?.idRecinto == recintoSeleccionado?.idRecinto ? '#03ABC6':'white'
      }}
    >
      {titulo}
    </Typography>
  
    )
  }

  const columna1 = (titulo, ancho, fondo, anchoLetra, recinto) => {
    return (
      <Typography
      style={{
        flex:ancho,
        fontWeight:anchoLetra,
        fontSize:14,
        paddingLeft:5,
        paddingRight:5,
        padding:5,
        color:'black',
        backgroundColor:fondo
      }}
    >
      {titulo}
    </Typography>
  
    )
  }

  return (
    <RecintosContext.Provider value={{modalAgregaRecinto, setModalAgregaRecinto, obtenerRecintos, recintoSeleccionado}} >
    <>
    <div
      style={{
        marginLeft:20,
        marginTop:20,
        marginRight:20,
        display:'flex',
        flexDirection:'row',
        flex:5,
        justifyContent:'space-between',
        alignItems:'center'
      }}
    > 
      <Typography
        style={{
          fontSize:20,
          fontWeight:600
        }}
      >
        Recintos deportivos
      </Typography>
      <Button
        onClick={() => {setRecintoSeleccionado(); setModalAgregaRecinto(true)}}
        variant="contained"
      >
        Agregar
      </Button>
    </div>
    <div
      style={{
        marginLeft:20,
        marginTop:20,
        display:'flex',
        flexDirection:'column',
        flex:90
      }}
    >
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              width:'100%',
              backgroundColor:'red'
              //flex:1
            }}
          >
            {columna1('Nombre',20,'#03ABC6',500)}
            {columna1('Dirección',20,'#03ABC6',500)}
            {columna1('Ciudad',20,'#03ABC6',500)}
            {columna1('Tipo',20, '#03ABC6',500)}
            {columna1('',5, '#03ABC6',500)}
            {columna1('',5, '#03ABC6',500)}
          </div>

      {
        recintos?.map(recinto => (
          <div
            onClick={() => {
              setRecintoSeleccionado(recinto)
            }}
            style={{
              display:'flex',
              flexDirection:'row',
              //flex:1
            }}
          >
            {columna(recinto.nombre,20,'white',300, recinto)}
            {columna(recinto.direccion,20,'white',300, recinto)}
            {columna(recinto.nombreCiudad,20,'white',300, recinto)}
            {columna(recinto.descripcion,20, 'white',300, recinto)}
            <div
              style={{
                display:'flex',
                flex:5,
                color:recinto?.idRecinto == recintoSeleccionado?.idRecinto ? 'white':'black',
                backgroundColor:recinto?.idRecinto == recintoSeleccionado?.idRecinto ? '#03ABC6':'white'
              }}
            >
            <IconButton
              onClick={() => setModalAgregaRecinto(true)}
            >
              <Edit style={{color:recinto?.idRecinto == recintoSeleccionado?.idRecinto ? 'white':'green',}} />
            </IconButton>
            </div>
            <div
              style={{
                display:'flex',
                flex:5,
                color:recinto?.idRecinto == recintoSeleccionado?.idRecinto ? 'white':'black',
                backgroundColor:recinto?.idRecinto == recintoSeleccionado?.idRecinto ? '#03ABC6':'white'
              }}
            >
            <IconButton
              onClick={() => eliminarRecinto(recinto.idRecinto)}
            >
              <Delete style={{color:recinto?.idRecinto == recintoSeleccionado?.idRecinto ? 'white':'red',}} />
            </IconButton>
            </div>
          </div>
        ))
      }
    </div>
    </>
    <ModalAgregaRecintoComponent />
    </RecintosContext.Provider>
  )
}

