import { AddCircle, Delete, Edit, Email, EmailOutlined, Facebook, Instagram, LocationCity, LocationCityTwoTone, Person2, Person2Outlined, Phone } from '@mui/icons-material';
import { Button, Card, CardContent, CardHeader, Divider, IconButton, Typography } from '@mui/material'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { ModalAgregaDeporteComponent } from '../components/deportes/ModalAgregaDeporte';
import { ModalAgregaLiga } from '../components/ligas/ModalAgregaLiga';
import { TablaLigas } from '../components/ligas/TablaLigas';
import { AuthContext } from '../context/AuthContext';
import { ObtenerDatos } from '../helpers/ApiHelper';
import Swal from 'sweetalert2';

export const LigasContext = createContext();

export const LigasDeportivasPage = () => {
  const {usuario} = useContext(AuthContext)
    const [ligas, setLigas] = useState();
  const [modalAgregaLiga, setModalAgregaLiga] = useState(false);
  const [modalAgregaClase, setModalAgregaClase] = useState(false)
  const [ligaSeleccionada, setLigaSeleccionada] = useState();

  useEffect(() => {
    obtenerLigas();
  }, [])
  
  const obtenerLigas = () => {
    const data = new FormData();
    data.append('idUsuario', usuario.idUsuario)
    ObtenerDatos('/ligas.php?funcion=obtenerLigas', data).then(response => {
      setLigas(response);
      console.log('ligas', response);
    });
  }

  useEffect(() => {
    console.log(ligaSeleccionada)
  }, [ligaSeleccionada])

  useEffect(() => {
    console.log('selecciono liga en tabla', ligaSeleccionada)
  }, [setLigaSeleccionada])

  const eliminarLiga = (liga) => {
    Swal.fire({
      title: '¿Estás seguro(a)?',
      text: "Se eliminará la liga, esto no puede ser revertido",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = new FormData();
        data.append('idLiga', liga?.idLiga)
        ObtenerDatos('/ligas.php?funcion=eliminarLiga', data).then(response => {
          if(response==1) {
            Swal.fire(
              'Eliminado!',
              'El usuario ha sido eliminado.',
              'success'
            )
            obtenerLigas();
          } else {
            Swal.fire(
              'Error!',
              'La liga no pudo ser eliminada!.',
              'error'
            )

          }
        })
      }
    })
  }
  
  
  
  const columna = (titulo, ancho, fondo, anchoLetra, liga) => {
    return (
      <Typography
      style={{
        flex:ancho,
        fontWeight:anchoLetra,
        fontSize:14,
        paddingLeft:5,
        paddingRight:5,
        padding:5,
        color:liga?.idLiga == ligaSeleccionada?.idLiga ? 'white':'black',
        backgroundColor:liga?.idLiga == ligaSeleccionada?.idLiga ? '#03ABC6':'white'
      }}
    >
      {titulo}
    </Typography>
  
    )
  }

  const columna1 = (titulo, ancho, fondo, anchoLetra, liga) => {
    return (
      <Typography
      style={{
        flex:ancho,
        fontWeight:anchoLetra,
        fontSize:14,
        paddingLeft:5,
        paddingRight:5,
        padding:5,
        color:liga?.idLiga =='black',
        backgroundColor:fondo
      }}
    >
      {titulo}
    </Typography>
  
    )
  }

  return (
    <LigasContext.Provider value={{modalAgregaLiga, setModalAgregaLiga, obtenerLigas, ligaSeleccionada, modalAgregaClase, setModalAgregaClase}} >
    <>
    <div
      style={{
        marginLeft:20,
        marginTop:20,
        marginRight:20,
        display:'flex',
        flexDirection:'row',
        flex:5,
        justifyContent:'space-between',
        alignItems:'center'
      }}
    > 
      <Typography
        style={{
          fontSize:20,
          fontWeight:600
        }}
      >
        Ligas deportivas
      </Typography>
      <Button
        onClick={() => {setLigaSeleccionada(); setModalAgregaLiga(true)}}
        variant="contained"
      >
        Agregar
      </Button>
    </div>
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        flex:95
      }}
    >
    <div
      style={{
        marginLeft:20,
        marginTop:20,
        display:'flex',
        flexDirection:'column',
        flex:60
      }}
    >
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              width:'100%',
              backgroundColor:'red'
              //flex:1
            }}
          >
            {columna1('Nombre de la liga',20,'#03ABC6',500)}
            {columna1('Deporte/Disciplina',20,'#03ABC6',500)}
            {columna1('Ciudad',20,'#03ABC6',500)}
            {columna1('',5, '#03ABC6',500)}
            {columna1('',5, '#03ABC6',500)}
          </div>

      {
        ligas?.map(liga => (
          <div
            onClick={() => {
              setLigaSeleccionada(liga)
            }}
            style={{
              display:'flex',
              flexDirection:'row',
              //flex:1
            }}
          >
            {columna(liga.nombre,20,'white',400, liga)}
            {columna(liga.deporte,20,'white',400, liga)}
            {columna(liga.nombreCiudad,20,'white',400, liga)}
            <div
              style={{
                display:'flex',
                flex:5,
                fontWeight:500,
                color:liga?.idLiga == ligaSeleccionada?.idLiga ? 'white':'black',
                backgroundColor:liga?.idLiga == ligaSeleccionada?.idLiga ? '#03ABC6':'white'
              }}
            >
            <IconButton
              onClick={() => {setLigaSeleccionada(liga); setModalAgregaLiga(true)}}
            >
              <Edit style={{color:liga?.idLiga == ligaSeleccionada?.idLiga ? 'white':'green',}} />
            </IconButton>
            </div>
            <div
              style={{
                display:'flex',
                flex:5,
                fontWeight:500,
                color:liga?.idLiga == ligaSeleccionada?.idLiga ? 'white':'black',
                backgroundColor:liga?.idLiga == ligaSeleccionada?.idLiga ? '#03ABC6':'white'
              }}
            >
            <IconButton
              onClick={() => {setLigaSeleccionada(liga); eliminarLiga(liga)}}
            >
              <Delete style={{color:liga?.idLiga == ligaSeleccionada?.idLiga ? 'white':'red',}} />
            </IconButton>
            </div>
          </div>
        ))
      }
    </div>
    <div
      style={{
        marginLeft:20,
        marginTop:20,
        display:'flex',
        flexDirection:'column',
        flex:40
      }}
    >
      <Card>
        <CardContent
          style={{
            color:'black'
          }}
        >

          <Typography style={{color:'#03ABC6'}}>
          {ligaSeleccionada?.nombre}
          </Typography>
          <Divider />
          <Typography
            style={{fontSize:12, marginTop:20, display:'flex', flexDirection:'row', alignItems:'center',}}
          >
          <Person2  style={{fontSize:16, color:'#03ABC6'}} /> {ligaSeleccionada?.nombreResponsable} / {ligaSeleccionada?.cargoResponsable}
          </Typography>
          <Typography
            style={{fontSize:12, marginTop:0, display:'flex', flexDirection:'row', alignItems:'center'}}
          >
            <Facebook style={{fontSize:16, color:'#3477eb'}} /> {ligaSeleccionada?.facebook}
          </Typography>
          <Typography
            style={{fontSize:12, marginTop:0, display:'flex', flexDirection:'row', alignItems:'center'}}
          >
            <Instagram style={{fontSize:16, color:'blueviolet'}} /> {ligaSeleccionada?.instagram}
          </Typography>
          <Typography
            style={{fontSize:12, marginTop:0, display:'flex', flexDirection:'row', alignItems:'center'}}
          >
            <EmailOutlined style={{fontSize:16, color:'blue'}} /> {ligaSeleccionada?.correo}
          </Typography>
          <Typography
            style={{fontSize:12, marginTop:0, display:'flex', flexDirection:'row', alignItems:'center'}}
          >
            <Phone style={{fontSize:16, color:'#03ABC6'}} /> {ligaSeleccionada?.telefono}
          </Typography>
          <Typography
            style={{fontSize:12, marginTop:0, display:'flex', flexDirection:'row', alignItems:'center'}}
          >
            <LocationCityTwoTone style={{fontSize:16, color:'#03ABC6'}} /> {ligaSeleccionada?.nombreCiudad}
          </Typography>
        </CardContent>
      </Card>
      {/* <Card
        style={{
          marginTop:20
        }}
      >
        <CardContent
          style={{
            color:'black'
          }}
        >
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'space-between'
            }}
          >
            <Typography style={{color:'#03ABC6'}}>
              Información de clases
            </Typography>
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center'
              }}
            >
              <Button
                onClick={()=> {setModalAgregaClase(true)}}
                style={{
                  textTransform:'unset'
                }}
              >
                <Typography style={{fontSize:13}}>Agregar</Typography>  <AddCircle style={{color:'#03ABC6', fontSize:16}} />
              </Button>
            </div>
            </div>
            <Divider />
            <div
              style={{
                display:'flex',
                flexDirection:'row'
              }}
            >
              <TablaLigas />
            </div>
 
        </CardContent>
      </Card> */}
    </div>
    </div>
    </>
    {/* <ModalAgregaEscuela />
    <ModalAgregaClase /> */}
    <ModalAgregaLiga />
    </LigasContext.Provider>
  )
}

