import { Delete, Edit } from '@mui/icons-material';
import { Divider, IconButton, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { ObtenerDatos } from '../../helpers/ApiHelper';
import { EscuelasContext } from '../../pages/EscuelasDeportivasPage'

export const TablaClases = () => {
    const {escuelaSeleccionada, modalAgregaClase, setModalAgregaClase, setClaseSeleccionada, clases, obtenerClases, setClases} = useContext(EscuelasContext);

    useEffect(() => {
        obtenerClases()
    }, [escuelaSeleccionada])
    

 

    const eliminarClase = (idClase) => {
        Swal.fire({
          title: '¿Estás seguro(a)?',
          text: "Se eliminará la clase, esto no puede ser revertido",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminar!'
        }).then((result) => {
          if (result.isConfirmed) {
            const data = new FormData();
            data.append('idClase', idClase)
            ObtenerDatos('/escuelas.php?funcion=eliminarClase', data).then(response => {
              if(response==1) {
                Swal.fire(
                  'Eliminado!',
                  'La clase ha sido eliminada.',
                  'success'
                )
                obtenerClases();
              } else {
                Swal.fire(
                  'Error!',
                  'La clase no pudo ser eliminada!.',
                  'error'
                )
    
              }
            })
          }
        })
      }

  return (
    <div
        style={{
            display:'flex',
            flexDirection:'column'

        }}
    >
        {
            clases?.map(clase => (
                <>
                <div
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center',
                        justifyContent:'space-between'

                    }}
                >
                    <Typography
                        style={{
                            fontSize:12,
                            color:'#03ABC6'
                        }}
                    >{clase.nombre} - {clase.direccion}</Typography>
                    <div>
                        <IconButton
                            onClick={() =>{setClaseSeleccionada(clase);setModalAgregaClase(true)}}
                        >
                            <Edit style={{color:'green', fontSize:14}} />
                        </IconButton>
                        <IconButton
                            onClick={() =>{eliminarClase(clase.idEscuelaClase)}}
                        >
                            <Delete style={{color:'red', fontSize:14}} />
                        </IconButton>
                    </div>
                </div>
                <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    

                }}
            >

                    {
                        clase?.lunesInicio ? (
                            <div>
                                <Typography style={{fontSize:11, color:'gray', marginLeft:5}}>Lun {(clase?.lunesInicio)?.substr(0,5)}-{(clase?.lunesFin)?.substr(0,5)}</Typography>
                            </div>
                        ):null 
                    }
                    {
                        clase?.martesInicio ? (
                            <div>
                                <Typography style={{fontSize:11, color:'gray', marginLeft:5}}>Mar  {(clase?.martesInicio)?.substr(0,5)}-{(clase?.martesFin)?.substr(0,5)}</Typography>
                            </div>
                        ):null 
                    }
                    {
                        clase?.miercolesInicio ? (
                            <div>
                                <Typography style={{fontSize:11, color:'gray', marginLeft:5}}>Mie {(clase?.miercolesInicio)?.substr(0,5)}-{(clase?.miercolesFin)?.substr(0,5)}</Typography>
                            </div>
                        ):null 
                    }
                    {
                        clase?.juevesInicio ? (
                            <div>
                                <Typography style={{fontSize:11, color:'gray', marginLeft:5}}>Jue {(clase?.juevesInicio)?.substr(0,5)}-{(clase?.juevesFin)?.substr(0,5)}</Typography>
                            </div>
                        ):null 
                    }
                    {
                        clase?.viernesInicio ? (
                            <div>
                                <Typography style={{fontSize:11, color:'gray', marginLeft:5}}>Vie {(clase?.viernesInicio)?.substr(0,5)}-{(clase?.viernesFin)?.substr(0,5)}</Typography>
                            </div>
                        ):null 
                    }
                    {
                        clase?.sabadoInicio ? (
                            <div>
                                <Typography style={{fontSize:11, color:'gray', marginLeft:5}}>Sab {(clase?.sabadoInicio)?.substr(0,5)}-{(clase?.sabadoFin)?.substr(0,5)}</Typography>
                            </div>
                        ):null 
                    }
                    {
                        clase?.domingoInicio ? (
                            <div>
                                <Typography style={{fontSize:11, color:'gray', marginLeft:5}}>Dom {(clase?.domingoInicio)?.substr(0,5)}-{(clase?.domingoFin)?.substr(0,5)}</Typography>
                            </div>
                        ):null 
                    }
                </div>
                <Divider/>
                </>
            ))
        }
    </div>
  )
}
