import { Divider, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ObtenerDatos } from '../helpers/ApiHelper'
import dayjs from 'dayjs'
import { DeliveryDining, Edit, Handshake, Handyman, Image, Sell } from '@mui/icons-material'
import { ModalEntregaArticulo } from '../components/tienda/ModalEntregaArticulo'

export const EntregaArticulosPage = () => {
    const [entregaArticulos, setEntregaArticulos] = useState()
    const [articuloSeleccionado, setArticuloSeleccionado] = useState()
    const [modalEntrega, setModalEntrega] = useState()


    const obtenerArticulos = () => {
        ObtenerDatos('/tienda.php?funcion=articulosEntrega').then(response => {
            console.log('respuesta del servidor', response)
            setEntregaArticulos(response)
        })
    }

    useEffect(() => {
        obtenerArticulos()
    }, [])
    

    const columna = (titulo, ancho, fondo, anchoLetra, articulo) => {
        return (
          <Typography
          style={{
            flex:ancho,
            fontWeight:anchoLetra,
            fontSize:14,
            paddingLeft:5,
            paddingRight:5,
            padding:5,
            color:articulo?.idPedidoTienda == articuloSeleccionado?.idPedidoTienda ? 'white':'black',
            backgroundColor:articulo?.idPedidoTienda == articuloSeleccionado?.idPedidoTienda ? '#03ABC6':'white'
          }}
        >
          {titulo}
        </Typography>
      
        )
      }
      const columna1 = (titulo, ancho, fondo, anchoLetra, articulo) => {
        return (
          <Typography
          style={{
            flex:ancho,
            fontWeight:anchoLetra,
            fontSize:14,
            paddingLeft:5,
            paddingRight:5,
            padding:5,
            color:'black',
            backgroundColor:fondo
          }}
        >
          {titulo}
        </Typography>
      
        )
      }
    

    return (
        <>
            <div
            style={{
                marginLeft:20,
                marginTop:20,
                marginRight:20,
                display:'flex',
                flexDirection:'row',
                flex:5,
                justifyContent:'space-between',
                alignItems:'center'
            }}
            > 
            <Typography
                style={{
                fontSize:20,
                fontWeight:600
                }}
            >
                Entrega de artículos
            </Typography>


        </div>
        <div
            style={{
                display:'flex',
                flexDirection:'row',
                minHeight:'80vh',
            }}
        >
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex:95,
                maxHeight:'70vh',
                overflowY:'auto'

            }}
            >
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              width:'100%',
              backgroundColor:'red'
              //flex:1
            }}
            >
            {columna1('Fecha',20,'#03ABC6',500)}
            {columna1('Nombre de articulo',20,'#03ABC6',500)}
            {columna1('Usuario',20,'#03ABC6',500)}
            {columna1('Estado',20,'#03ABC6',500)}
            {columna1('Fecha entrega',20,'#03ABC6',500)}
            {columna1('',5,'#03ABC6',500)}
          </div>
          <Divider />
          {
            entregaArticulos?.map(articulo => (
              <div
              onClick={() => {
                setArticuloSeleccionado(articulo)
              }}
              style={{
                display:'flex',
                flexDirection:'row',
                //flex:1
              }}
              >            
              {columna(dayjs(articulo?.fecha).format('DD/MM/YYYY'),20,'white',400, articulo)}
              {columna(articulo?.articulo,20,'white',400, articulo)}
              {columna(articulo?.nombre+' '+articulo?.apellidoPaterno+' '+articulo?.apellidoMaterno,20,'white',400, articulo)}
              {columna(articulo?.estado,20,'white',400, articulo)}
              {columna(articulo?.idEstado=='1' ? dayjs(articulo?.fechaEntrega).format('DD/MM/YYYY'):'',20,'white',400, articulo)}
              <div
              style={{
                display:'flex',
                flex:5,
                fontWeight:500,
                color:articulo?.idTienda == articuloSeleccionado?.idPedidoTienda ? 'white':'black',
                backgroundColor:articulo?.idPedidoTienda == articuloSeleccionado?.idPedidoTienda ? '#03ABC6':'white'
              }}
              >
                {
                    articulo?.idEstado==0 ? (
            <IconButton
              onClick={() => setModalEntrega(true)}
              >
              <Edit style={{color:articulo?.idPedidoTienda == articuloSeleccionado?.idPedidoTienda ? 'white':'green',}} />
            </IconButton>
                    ):(
                        <IconButton
                        //onClick={() => setModalEntrega(true)}
                        disabled

                        >
                            <Edit style={{color:articulo?.idPedidoTienda == articuloSeleccionado?.idPedidoTienda ? 'gray':'gray',}} />
                    </IconButton>
                    )
}
            </div>              
            </div>
            ))
          }          
          </div>        
          <div
      style={{
        display:'flex',
        flexDirection:'column',
        flex:95,
        marginLeft:20
      }}
      >
               {
          articuloSeleccionado?.identificacion ? (
            <div>
                <img 
                src={'https://www.deporti.com.mx/imagenes/entregas/'+articuloSeleccionado?.identificacion}
                style={{
                    maxWidth:250,
                    marginLeft:30,
                    marginRight:20,
                    borderRadius:15
                }}
                />
                <Typography style={{marginLeft:30}}>Identificación entregada</Typography>
        </div>
          ):(
            <Image style={{fontSize:300, color:'gray'}} />
          )
        }
    </div>
    </div>
    <ModalEntregaArticulo modalEntrega={modalEntrega} setModalEntrega={setModalEntrega} articulo={articuloSeleccionado} obtenerArticulos={obtenerArticulos} />
    </>
  )
}
