export const Login = async(usuario, password) => {
    return new Promise((resolve, reject) => {
        const data = new FormData();
        data.append('usuario', usuario);
        data.append('password', password);
         fetch(process.env.REACT_APP_API_URL+'/usuarios.php?funcion=loginUsuario', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}

export const ObtenerTecnicosDependencia = async(data) => {
    return new Promise((resolve, reject) => {

        //const data = new FormData();
       // data.append('orden','TipoInstalacion asc')
         fetch(process.env.REACT_APP_API_URL+'/usuarios.php?funcion=tecnicosDependencia', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}
