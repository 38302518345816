import { Delete, Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import { UsuariosContext } from '../../context/UsuariosContext'

export const TablaUsuarios = () => {
    const {usuarios, obtenerUsuarios, usuarioSeleccionado, setUsuarioSeleccionado, setModalUsuario, eliminarUsuario} = useContext(UsuariosContext);


  return (
    <div
        style={{
            display:'flex',
            flexDirection:'column',
            marginBottom:60,
            overflowY:'auto',
            backgroundColor:'transparent',
            maxHeight:'75vh'

        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'row',
                backgroundColor:'#03ABC6',
                padding:10,
                borderRadius:10,
                color:'white',
                fontWeight:'600',
                
            }}
        >
            <div style={{display:'flex', flexDirection:'row', flex:20}}>Nombre</div>
            <div style={{display:'flex', flexDirection:'row', flex:20}}>A. Paterno</div>
            <div style={{display:'flex', flexDirection:'row', flex:20}}>A. Materno</div>
            <div style={{display:'flex', flexDirection:'row', flex:20}}>Etiqueta</div>
            <div style={{display:'flex', flexDirection:'row', flex:20}}>Institución</div>
            <div style={{display:'flex', flexDirection:'row', flex:25}}>CURP</div>
            {/* <div style={{display:'flex', flexDirection:'row', flex:20}}>Clave de Elector</div> */}
            <div style={{display:'flex', flexDirection:'row', flex:5}}></div>
        </div>
        {
            usuarios?.map(usuario => (
                <div
                    onClick={() => {setUsuarioSeleccionado(usuario)}}
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        backgroundColor:usuario.idUsuario==usuarioSeleccionado?.idUsuario ? '#03ABC6':'white',
                        padding:10,
                        borderRadius:10,
                        color:usuario.idUsuario==usuarioSeleccionado?.idUsuario ? 'white':'black',
                        fontWeight:'400',
                        fontSize:13
                    }}
            >
                <div style={{display:'flex', flexDirection:'row', flex:20}}>{usuario.nombre}</div>
                <div style={{display:'flex', flexDirection:'row', flex:20}}>{usuario.apellidoPaterno}</div>
                <div style={{display:'flex', flexDirection:'row', flex:20}}>{usuario.apellidoMaterno}</div>
                <div style={{display:'flex', flexDirection:'row', flex:20}}>{usuario.etiqueta}</div>
                <div style={{display:'flex', flexDirection:'row', flex:20}}>{usuario.institucion}</div>
                <div style={{display:'flex', flexDirection:'row', flex:25}}>{usuario.curp}</div>
                {/* <div style={{display:'flex', flexDirection:'row', flex:20}}>{usuario.claveElector}</div> */}
                <div style={{display:'flex', flexDirection:'row', flex:5}}>
                    <IconButton
                        onClick={() => {setUsuarioSeleccionado(usuario); setModalUsuario(true)}}
                        size='small'
                    >
                        <Edit style={{color:'green'}} />
                    </IconButton>
                </div>
                <div style={{display:'flex', flexDirection:'row', flex:5}}>
                    <IconButton
                        onClick={() => {setUsuarioSeleccionado(usuario); eliminarUsuario(usuario?.idUsuario)}}
                        size='small'
                    >
                        <Delete style={{color:'red'}} />
                    </IconButton>
                </div>
            </div>    
            ))
        }
    </div>
  )
}
