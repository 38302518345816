import React, { useContext } from 'react'
import { UsuariosContext } from '../../context/UsuariosContext';
import { Typography } from '@mui/material';

export const CardReporte = () => {
    const {usuarioSeleccionado} = useContext(UsuariosContext)
  return (
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            flex:1,
            backgroundColor:'transparent',
            padding:10,
            maxHeight:'90vh',
            overflowY:'auto'
        }}
    >
        <div>
            <Typography
                style={{
                    fontSize:12,
                    fontWeight:'600'
                }}
            >
                Teléfono
            </Typography            >
            <Typography
            style={{
                fontSize:12,
                fontWeight:'400'
            }}
        >
                {usuarioSeleccionado?.Telefono}
            </Typography>
            <Typography
                style={{
                    fontSize:12,
                    fontWeight:'600'
                }}
            >
                Profile Name
            </Typography>
            <Typography
            style={{
                fontSize:12,
                fontWeight:'400'
            }}
        >
                {usuarioSeleccionado?.ProfileName}
            </Typography>
            <Typography
                style={{
                    fontSize:12,
                    fontWeight:'600'
                }}
            >
                Descripción
            </Typography>
            <Typography
            style={{
                fontSize:12,
                fontWeight:'400'
            }}
        >
                {usuarioSeleccionado?.Descripcion}
            </Typography>
            <Typography
                style={{
                    fontSize:12,
                    fontWeight:'600'
                }}
            >
                Descripción de ubicación
            </Typography>
            <Typography
            style={{
                fontSize:12,
                fontWeight:'400'
            }}
        >
                {usuarioSeleccionado?.descripcionUbicacion}
            </Typography>
            <Typography
                style={{
                    fontSize:12,
                    fontWeight:'600'
                }}
            >
                Estado
            </Typography>
            <Typography
            style={{
                fontSize:12,
                fontWeight:'400'
            }}
        >
                {usuarioSeleccionado?.EstatusReporte}
            </Typography>
            <Typography
                style={{
                    fontSize:12,
                    fontWeight:'600'
                }}
            >
                Fecha Resuelto
            </Typography>
            <Typography
            style={{
                fontSize:12,
                fontWeight:'400'
            }}
        >
                {usuarioSeleccionado?.FechaResuelto}
            </Typography>
            <Typography
                style={{
                    fontSize:12,
                    fontWeight:'600'
                }}
            >
                Comentarios
            </Typography>
            <Typography
            style={{
                fontSize:12,
                fontWeight:'400'
            }}
        >
                {usuarioSeleccionado?.Comentarios}
            </Typography>
            <img onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="";
            }} style={{width:'60%', height:'auto', borderRadius:10, position:'relative', margin:30}} src={'https://deporti.com.mx/reportes/Reporte-'+usuarioSeleccionado.IdReporte.padStart(6,"0")+'.jpg'} />
            <img onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="";
            }} style={{width:'60%', height:'auto', borderRadius:10, position:'relative', margin:30}} src={'https://deporti.com.mx/reportes/Tecnico-'+usuarioSeleccionado.IdReporte.padStart(6,"0")+'.jpg'} />
        </div>

    </div>
  )
}
