import { ArrowBack, ChevronLeft, ChevronRight, Search } from '@mui/icons-material';
import { Autocomplete, Button, IconButton, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { CardUsuario } from '../components/usuarios/CardUsuario';
import { ModalApoyo } from '../components/usuarios/ModalApoyo';
import { ModalDeportesFavoritos } from '../components/usuarios/ModalDeportesFavoritos';
import { ModalDeportesPractica } from '../components/usuarios/ModalDeportesPractica';
import { ModalImagenApoyo } from '../components/usuarios/ModalImagenApoyo';
import { ModalPassword } from '../components/usuarios/ModalPassword';
import { ModalPermisos } from '../components/usuarios/ModalPermisos';
import { ModalTablaApoyos } from '../components/usuarios/ModalTablaApoyos';
import { ModalUsuario } from '../components/usuarios/ModalUsuario';
import { TablaUsuarios } from '../components/usuarios/TablaUsuarios';
import { UsuariosContext } from '../context/UsuariosContext'

export const UsuariosPage = () => {
    const {usuarios, modalUsuario, setModalUsuario, usuarioSeleccionado, setUsuarioSeleccionado, pagina, setPagina, registros, usuarioBuscar, setUsuarioBuscar, obtenerUsuarios,
        etiquetas, setEtiquetaFiltro
    } = useContext(UsuariosContext)
    const [valorEtiqueta, setValorEtiqueta] = useState()

    useEffect(() => {
        console.log('usuarios en page', usuarios)
    }, [usuarios])

    const paginaAnterior = () => {
        if(pagina-1>0) {
            setPagina(pagina-1);
        }
    }

    const paginaSiguiente = () => {
        if((pagina+1)<=Math.ceil(registros/100)) {
            setPagina(pagina+1);
        }
    }

    const teclaPresionada = (event) => {
        console.log('key', event.key)
        if(event.key=='Enter') {
            setPagina(1); 
            obtenerUsuarios()
        }
    }
    
  return (
        <>
            <div
            style={{
                marginLeft:20,
                marginTop:20,
                marginRight:20,
                display:'flex',
                flexDirection:'row',
                flex:5,
                justifyContent:'space-between',
                alignItems:'center'
            }}
            > 
            <Typography
                style={{
                fontSize:20,
                fontWeight:600
                }}
            >
                Usuarios
            </Typography>
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    backgroundColor:'transparent'
                }}
            >
                <TextField 
                    onChange={(e) => {setUsuarioBuscar(e.target.value)}}
                    onKeyUp={(e) => {teclaPresionada(e)}}
                    size='small'
                    variant='outlined'
                    label='Usuario'
                />
                <Autocomplete
                    InputLabelProps={{shrink:true}}
                    variant="standard"
                    onChange={(event, newValue) => {setValorEtiqueta(newValue); setEtiquetaFiltro(newValue) }}
                    value={valorEtiqueta?.id ? valorEtiqueta:null}
                    //defaultValue={tiposActivo[index]}
                    //disablePortal
                    id="combo-box-demo"
                    options={etiquetas}
                    sx={{ zIndex:999999999 }}
                    style={{zIndex:99999999, flex:100, margintop:0, marginLeft:10, minWidth:300, marginBottom:10}}
                    renderInput={(params) => <TextField required variant="standard" onKeyUp={(e) => {teclaPresionada(e)}}  style={{zIndex:9999999, backgroundColor:'white', marginTop:0, }} {...params} label="Etiqueta" />}
                />                
                <IconButton
                    onClick={() => {setPagina(1); obtenerUsuarios()}}
                >
                    <Search />
                </IconButton>                
            </div>
            <Button
                onClick={() => {setUsuarioSeleccionado(); setModalUsuario(true)}}
                variant="contained"
            >
                Agregar
            </Button>
            </div>
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    flex:95,
                    backgroundColor:'transparent',
                    marginBottom:10,
                    marginLeft:10,
                    marginTop:10
                }}
                >
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:80,
                            backgroundColor:'transparent'
                        }}
                    >
                        <TablaUsuarios />
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'flex-end',
                                marginRight:40,
                                alignItems:'center'
                            }}
                        >Página {pagina} de {Math.ceil(registros/100)}
                            <IconButton
                                onClick={() => paginaAnterior()}
                            >
                                <ChevronLeft />
                            </IconButton>
                            <IconButton
                                onClick={() => paginaSiguiente()}
                            >
                                <ChevronRight />
                            </IconButton>
                        </div>
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:15,
                            backgroundColor:'transparent',
                            marginLeft:20,
                            marginRight:20
                        }}
                    >
                        <CardUsuario />
                    </div>
            </div>
            <ModalUsuario />
            <ModalApoyo />
            <ModalTablaApoyos />
            <ModalImagenApoyo />
            <ModalDeportesFavoritos />
            <ModalDeportesPractica />
            <ModalPermisos />
            <ModalPassword />
        </>
  )
}
