import { DisabledByDefaultOutlined } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { ObtenerDatos } from '../../helpers/ApiHelper'

export const ModalEntregaArticulo = ({modalEntrega, setModalEntrega, articulo, obtenerArticulos}) => {
    const {usuario} = useContext(AuthContext)
    const archivo = useRef();
    const refImagen = useRef();
    const [imagen, setImagen] = useState()

    const handleClose = () => {
        setModalEntrega(false)
    }


    // useEffect(() => {
    //     console.log('deporte seleccionado para imagen', deporteSeleccionado)
    //     refImagen.current.src =  deporteSeleccionado?.iconoMini ? 'https://deporti.com.mx/imagenes/deportes/'+deporteSeleccionado?.iconoMini:'https://deporti.com.mx/imagenes/deportes/imagotipo.png'
    // }, [deporteSeleccionado])

    const seleccionImagen = (event) => {
        console.log(event.target.files[0])
        setImagen({file:event.target.files[0]});
        console.log('selecciono imagen de deporte',event);
        var reader = new FileReader();
        reader.onload = function(){
            refImagen.current.src = reader.result;
        };
        reader.readAsDataURL(event.target.files[0]);
       // subirImagen();
    }

    const subirImagen = () => {
        const data = new FormData();
        data.append('identificacion', archivo.current.files[0])
        data.append('idPedidoTienda', articulo?.idPedidoTienda)
        data.append('idUsuario', usuario?.idUsuario)
        
        
        ObtenerDatos('/tienda.php?funcion=entregaArticulo',data).then(response => {
            console.log('respuesta del servidor', response)
//            obtenerDeportes();
                setModalEntrega(false)
                obtenerArticulos()
        })
    }
    

  return (
    <Dialog
        maxWidth='sm'
        fullWidth
        open={modalEntrega}
        onClose={handleClose}
    >
        <DialogContent>
        <div
        style={{
            display:'flex',
            flexDirection:'column'
        }}
    >
        <img 
            ref={refImagen}
            style={{
                width:150,
                height:'auto'
            }}
            src={articulo?.iconoMini ? 'https://deporti.com.mx/imagenes/deportes/'+articulo?.iconoMini:'https://deporti.com.mx/imagenes/deportes/imagotipo.png'}
        />
        <input 
            onChange={(e) => {console.log('se selecciono archivo', e), seleccionImagen(e)}}
            ref={archivo}
            type='file'
            style={{
                visibility:'hidden',
                width:1,height:1
            }}
        />
        <Button
            onClick={() => archivo.current.click()}
            variant='contained'
        >
            Identificacion presentada
        </Button>
    </div>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClose}
            >
                Cancelar
            </Button>
            <Button
                onClick={() => subirImagen()}
            >
                Articulo entregado
            </Button>
        </DialogActions>
    </Dialog>
  )
}
