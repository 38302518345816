import { Button, Checkbox, Dialog, DialogActions, DialogContent, Divider } from '@mui/material'
import React, { useContext, useState } from 'react'
import { UsuariosContext } from '../../context/UsuariosContext'

export const ModalDeportesPractica = () => {
    const {modalDeportesPractica, setModalDeportesPractica, deportesPractica, setDeportesPractica, actualizaDeportePractica, usuarioSeleccionado} = useContext(UsuariosContext)
    const [actualizaPractica, setActualizaPractica] = useState(0)
    const handleClose = () => {
        setModalDeportesPractica(false)
    }

    const handleDeportePractica = (e, deporte, index) => {
        if(usuarioSeleccionado?.idUsuario) {
            actualizaDeportePractica(deporte?.idDeporte, e.target.checked)
        } else {
            console.log('agregar en datos, usuario nuevo')
                console.log('valor del deporte', deportesPractica[index])
                const nuevosDeportes = deportesPractica;
                nuevosDeportes[index].idUsuario=-1;
                console.log('nuevosDeportes',nuevosDeportes)
                setDeportesPractica(nuevosDeportes)
                console.log('deportes practica x', deportesPractica)
                setActualizaPractica(actualizaPractica+1)
        }

    }

    if(deportesPractica?.length) {
    return (
    <Dialog
        open={modalDeportesPractica}
        onClose={handleClose}
    >
        <DialogContent>
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    fontSize:11
                }}
            >
                {
                    deportesPractica?.map((deporte, index) => (
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'center'
                            }}
                        >
                            <Checkbox 
                                checked={deporte?.idUsuario ? true:false}
                                onChange={(e) => {console.log(e); handleDeportePractica(e, deporte, index)}}
                                size='small' style={{padding:2}} />
                            {deporte.deporte}
                        </div>
                    ))
                }
            </div>
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button onClick={handleClose} >Guardar</Button>
        </DialogActions>
    </Dialog>
  )
            }
}
