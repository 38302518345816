import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { TorneosContext } from '../../context/TorneosContext'

export const ModalAgregarTorneo = () => {
    const {modalAgregaTorneo, setModalAgregaTorneo, ligas, setLigas, obtenerLigas, ciudades, setCiudades, obtenerCiudades,
        datosTorneo, setDatosTorneo, valorLiga, setValorLiga, valorCiudad, setValorCiudad, actualizaDatos, guardarTorneo,
        torneoSeleccionado
    } = useContext(TorneosContext)

    const handleClose = () => {
        setModalAgregaTorneo(false)
    }

    useEffect(() => {
        if(modalAgregaTorneo) {
            obtenerLigas();
            obtenerCiudades();
            if(torneoSeleccionado?.idTorneoDeportivo) {
                console.log('Torneo a editar', torneoSeleccionado)
                setValorLiga({id:torneoSeleccionado?.idLiga,label:torneoSeleccionado?.nombre})
                setValorCiudad({id:torneoSeleccionado?.idCiudad, label:torneoSeleccionado?.nombreCiudad})
                setDatosTorneo({
                    ...datosTorneo,
                    'idTorneoDeportivo':torneoSeleccionado?.idTorneoDeportivo,
                    'nombreTorneo':torneoSeleccionado?.nombreTorneo,
                    'inicio':torneoSeleccionado?.inicio,
                    'fin':torneoSeleccionado?.fin,
                    'idLiga':torneoSeleccionado?.idLiga,
                    'idCiudad':torneoSeleccionado?.idCiudad
                })
            }
        }
        
    }, [modalAgregaTorneo])

    const handleSubmit =  (event) => {
        event.preventDefault();
        console.log('guardar torneo')
        guardarTorneo();
    }

  return (
    <Dialog
        maxWidth='sm'
        fullWidth
        open={modalAgregaTorneo}
        onClose={handleClose}
    >
        <form onSubmit={handleSubmit}>
            <DialogTitle>Torneo</DialogTitle>
            <Divider />
            <DialogContent>
            <Autocomplete
                InputLabelProps={{shrink:true}}
                variant="standard"
                onChange={(event, newValue) => {console.log('new value', newValue); setValorLiga(newValue); actualizaDatos('idLiga', newValue.id)}}
                value={valorLiga?.id ? valorLiga:null}
                //defaultValue={tiposActivo[index]}
                //disablePortal
                id="combo-box-demo"
                options={ligas}
                sx={{ zIndex:999999999 }}
                style={{zIndex:99999999, flex:25, margintop:3, marginLeft:0}}
                renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Liga" />}
            />
            <TextField 
                InputLabelProps={{
                    shrink:true
                }}
                onChange={(e) => {actualizaDatos('nombreTorneo', e.target.value)}}
                value={datosTorneo?.nombreTorneo}
                variant='standard'
                label='Nombre'
                placeholder='Nombre del torneo'
                fullWidth
                required
            />
            <TextField 
                onChange={(e) => {actualizaDatos('inicio', e.target.value)}}
                value={datosTorneo?.inicio}
                variant='standard'
                label='Inicio'
                placeholder='Inicio del torneo'
                fullWidth
                type='date'
                InputLabelProps={{
                    shrink:true
                }}
            />
            <TextField 
                onChange={(e) => {actualizaDatos('fin', e.target.value)}}
                value={datosTorneo?.fin}
                variant='standard'
                label='Fin'
                placeholder='Fin del torneo'
                fullWidth
                type='date'
                InputLabelProps={{
                    shrink:true
                }}
            />
           <Autocomplete
                InputLabelProps={{shrink:true}}
                variant="standard"
                onChange={(event, newValue) => {console.log('new value', newValue); setValorCiudad(newValue); actualizaDatos('idCiudad', newValue.id)}}
                value={valorCiudad?.id ? valorCiudad:null}
                //defaultValue={tiposActivo[index]}
                //disablePortal
                id="combo-box-demo"
                options={ciudades}
                sx={{ zIndex:999999999 }}
                style={{zIndex:99999999, flex:25, margintop:3, marginLeft:0}}
                renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Ciudad" />}
            />            
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                >
                    Cancelar
                </Button>
                <Button
                    type='submit'
                >
                    Guardar
                </Button>
            </DialogActions>
        </form>
    </Dialog>
  )
}
