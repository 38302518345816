import { Alert, Button, Dialog, DialogActions, DialogContent, Divider, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { UsuariosContext } from '../../context/UsuariosContext'
import { ObtenerDatos } from '../../helpers/ApiHelper';



export const ModalPassword = () => {
    const {modalPassword, setModalPassword, usuarioSeleccionado} = useContext(UsuariosContext);
    console.log('usuario seleccionado', usuarioSeleccionado)
    const [password, setPassword] = useState();
    const [password1, setPassword1] = useState()
    const [userName, setUserName] = useState(usuarioSeleccionado?.userName);
    const [passwordVacio, setPasswordVacio] = useState(false)
    const [passwordDiferente, setPasswordDiferente] = useState(false)

    const handleClose = () => {
        setModalPassword(false)
    }

    useEffect(() => {
        setUserName(usuarioSeleccionado?.userName)
    }, [usuarioSeleccionado])
    

    const guardarPassword = () => {
        console.log(password?.length)
        if(password?.length==0 || !password?.length) {
            console.log('vacio')
            setPasswordVacio(true)
            return 0;
        } else {
            setPasswordVacio(false)
        }

        if(password!==password1) {
            console.log('no coincide')
            setPasswordDiferente(true)
            return 0       
        } else {
            setPasswordDiferente(false)
        }
        console.log('guardar cambio')
        const data = new FormData()
        data.append('idUsuario', usuarioSeleccionado?.idUsuario)
        data.append('pass', password)
        data.append('userName', userName)
        ObtenerDatos('/usuarios.php?funcion=cambiarPassword', data).then(response => {
            handleClose();
        })
        
    }

  return (
    <Dialog
        open={modalPassword}
        onClose={handleClose}
    >
        <DialogContent>
            <Typography
                style={{
                    fontSize:15,
                    fontWeight:'600',
                    marginBottom:10,
                    
                }}
            >
                Establecer contraseña
            </Typography>
            <Divider />
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    marginTop:10
                }}
            >
            <TextField 
                onChange={(e) => {setUserName(e.target.value)}}
                variant='standard'
                label='Username'
                value={userName}
                InputLabelProps={{
                    shrink:true
                }}
            />
            <TextField 
                onChange={(e) => {setPassword(e.target.value)}}
                variant='standard'
                label='Contraseña'
                type='password'
                inputProps={{
                    shrink:true
                }}
            />
            <TextField 
                onChange={(e) => {setPassword1(e.target.value)}}
                variant='standard'
                label='Repite la contraseña'
                type='password'
            />
            <Typography style={{color:'red', fontSize:12, visibility:passwordVacio ? '':'hidden'}}>La contraseña no puede quedar vacia</Typography>
            <Typography style={{color:'red', fontSize:12, visibility:passwordDiferente ? '':'hidden'}}>Las contraseñas no coinciden</Typography>
            </div>
        </DialogContent>
        <Divider />
        <DialogActions>
            
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => guardarPassword()}>Guardar</Button>
        </DialogActions>
    </Dialog>
  )
}
