import { Button, Checkbox, FilledInput, FormControl, FormControlLabel, IconButton, Input, InputAdornment, InputLabel, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import logo from '../assets/logo.png'
import { AuthContext } from '../context/AuthContext';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Person } from '@mui/icons-material';
import { Login } from '../helpers/UsuariosHelper';
import { ObtenerDatos } from '../helpers/ApiHelper';
import Swal from 'sweetalert2';


export const CambiarPasswordPage = () => {
    const queryParamters = new URLSearchParams(window.location.search)

    const [token, setToken] = useState(queryParamters.get('token'));
    const [password1, setPassword1] = useState()
    const [password2, setPassword2] = useState()
    const [email, setEmail] = useState(queryParamters.get('email'));    
    const [password, setPassword] = useState();
    const {usuario, setUsuario, logueado, setLogueado} = useContext(AuthContext);
    const [showPassword, setShowPassword] = React.useState(false);
    const [habilitaGuardar, setHabilitaGuardar] = useState(false)

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
  
      const login =() => {
          Login(usuario,password).then(response => {
            if(response?.idUsuario) {
                setLogueado(true)
                setUsuario(response)

            }
          });
      }

      const restablecer = () => {
        const data = new FormData();
        data.append('password',password1);
        data.append('email', email);
        data.append('token', token)
        ObtenerDatos('/usuarios.php?funcion=restablecerPassword',data).then(response => {
            console.log('respuesta de restablecer password', response)
            if(response==1) {
                Swal.fire({
                    icon: "success",
                    title: "Restablecida",
                    text: "Tu contraseña fue restablecida satisfactoriamente!",
                    
                  });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Ooops!",
                    text: "Algo salio mal, no se pudo restablecer!",
                    
                  });
            }
        })
      }
    
      useEffect(() => {
        console.log('parametros recibidos', token, email)
      }, [token, email])

      useEffect(() => {
        if(password1?.length>=4 && password2?.length>=4) {
            if(password1===password2) {
                setHabilitaGuardar(true);
            } else {
                setHabilitaGuardar(false)
            }
        } else {
            setHabilitaGuardar(false);
        }
      }, [password1, password2])
      
      

  return (
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            flex:1,
            height:'100vh',
            backgroundColor:'transparent'
        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex:1,
                backgroundColor:'transparent',
                justifyContent:'center',
                alignItems:'center'
            }}
        >
            <div>
                <img 
                style={{
                    width:354,

                }}
                src={logo}
                />
                <div
                    style={{backgroundColor:'transparent', textAlign:'center'}}
                >
                    <Typography
                        style={{
                            fontSize:18,
                            fontWeight:'600',
                        }}
                    >
                        Restablecer contraseña
                    </Typography>
                </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:30,
                                borderRadius:20
                            }}
                        >
                            <FilledInput
                                autoComplete="new-password"
                                placeholder='Password'
                                disableUnderline
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => {setPassword1(e.target.value)}}
                                label="Password"
                                style={{
                                    display:'flex',
                                    flex:1,
                                    maxWidth:'100%',
                                    borderRadius:20,
                                    backgroundColor:'#F6F6F6',
                                    padding:10
                                }}
                                inputProps={{
                                    style:{
                                        borderRadius:10,
                                        backgroundColor:'#F6F6F6'
                                    }
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                size='small' variant="filled" />
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:30, borderRadius:20
                            }}
                        >
                            <FormControl
                                style={{
                                    display:'flex',
                                    flex:1,
                                    maxWidth:'100%'

                                }}
                                placeholder="Confirme password"
                                sx={{ m: 1, width: '25ch' }} variant="filled">
                            {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                            <FilledInput
                                autoComplete="new-password"
                                placeholder='Confirme password'
                                                      style={{
                                                        display:'flex',
                                                        flex:1,
                                                        maxWidth:'100%',
                                                        borderRadius:20,
                                                        backgroundColor:'#F6F6F6',
                                                        padding:10
                                                    }}
                                                    inputProps={{
                                                        style:{
                                                            borderRadius:10,
                                                            backgroundColor:'#F6F6F6'
                                                        }
                                                    }}                                                    
                                disableUnderline
                                onChange={(e) => setPassword2(e.target.value)}
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
//                                label="Password"
                            />
                            </FormControl>
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:5,
                            }}
                        >
                             {/* <FormControlLabel control={<Checkbox size='small'  />} label={<Typography style={{fontSize:12}}>Mantener sesión</Typography>} />
                             <Button
                             
                                style={{
                                    textTransform:'unset',
                                    color:'#642644',
                                }}
                             >¿Olvidaste tu contraseña?</Button> */}
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:5,
                            }}
                        >
                             <Button
                             disabled={!habilitaGuardar}
                             onClick={() => restablecer()}
                                style={{
                                    textTransform:'unset',
                                    backgroundColor: habilitaGuardar ? 'black':'gray',
                                    color:'white',
                                    minWidth:'100%',
                                    borderRadius:30,
                                    marginTop:20,
                                    padding:15
                                }}
                             >
                                Restablecer
                             </Button>
                        </div>
            </div>
        </div>
    </div>
  )
}
