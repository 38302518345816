import { Delete, Edit, Image } from '@mui/icons-material';
import { Button, Divider, IconButton, Typography } from '@mui/material';
import React, { createContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { ModalAgregaArticulo } from '../components/tienda/ModalAgregaArticulo';
import { ObtenerDatos } from '../helpers/ApiHelper';

export const TiendaContext = createContext();

export const TiendaDeportiPage = () => {
  const [articulos, setArticulos] = useState();
  const [articuloSeleccionado, setArticuloSeleccionado] = useState();
  const [modalAgregaArticulo, setModalAgregaArticulo] = useState(false);

  const obtenerArticulos = () => {
    ObtenerDatos('/tienda.php?funcion=obtenerArticulos', null).then(response => {
      console.log('articulos', response);
      setArticulos(response);
    })
  }

  useEffect(() => {
    obtenerArticulos();
  }, [])
  
  const columna = (titulo, ancho, fondo, anchoLetra, articulo) => {
    return (
      <Typography
      style={{
        flex:ancho,
        fontWeight:anchoLetra,
        fontSize:14,
        paddingLeft:5,
        paddingRight:5,
        padding:5,
        color:articulo?.idTienda == articuloSeleccionado?.idTienda ? 'white':'black',
        backgroundColor:articulo?.idTienda == articuloSeleccionado?.idTienda ? '#03ABC6':'white'
      }}
    >
      {titulo}
    </Typography>
  
    )
  }
  const columna1 = (titulo, ancho, fondo, anchoLetra, articulo) => {
    return (
      <Typography
      style={{
        flex:ancho,
        fontWeight:anchoLetra,
        fontSize:14,
        paddingLeft:5,
        paddingRight:5,
        padding:5,
        color:'black',
        backgroundColor:fondo
      }}
    >
      {titulo}
    </Typography>
  
    )
  }

  const eliminarArticulo = (idArticulo) => {
    Swal.fire({
      title: '¿Estás seguro(a)?',
      text: "Se eliminará el artículo, esto no puede ser revertido, solo se pueden eliminar artículos que no han sido usados",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = new FormData();
        data.append('idArticulo', idArticulo)
        ObtenerDatos('/tienda.php?funcion=eliminarArticulo', data).then(response => {
          if(response==1) {
            Swal.fire(
              'Eliminado!',
              'El artículo ha sido eliminado.',
              'success'
            )
            obtenerArticulos();
          } else {
            Swal.fire(
              'Error!',
              'El artículo no pudo ser eliminado!.',
              'error'
            )

          }
        })
      }
    })
  }

  return (
    <TiendaContext.Provider value={{modalAgregaArticulo, setModalAgregaArticulo, articuloSeleccionado, obtenerArticulos}}>
    <>
    <div
      style={{
        marginLeft:20,
        marginTop:20,
        marginRight:20,
        display:'flex',
        flexDirection:'row',
        flex:5,
        justifyContent:'space-between',
        alignItems:'center'
      }}
    > 
      <Typography
        style={{
          fontSize:20,
          fontWeight:600
        }}
      >
        Tienda Deporti
      </Typography>
      <Button
        onClick={() => {setArticuloSeleccionado(); setModalAgregaArticulo(true)}}
        variant="contained"
      >
        Agregar
      </Button>
    </div>
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        flex:80,
        marginTop:20
      }}
    >

    <div
      style={{
        display:'flex',
        flexDirection:'column',
        flex:95
      }}
      >
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              width:'100%',
              backgroundColor:'red'
              //flex:1
            }}
            >
            {columna1('Nombre de articulo',20,'#03ABC6',500)}
            {columna1('Categoría',20,'#03ABC6',500)}
            {columna1('Cantidad',20,'#03ABC6',500)}
            {columna1('Puntos Deporti',20,'#03ABC6',500)}
            {columna1('',5, '#03ABC6',500)}
          </div>
          <Divider />
          {
            articulos?.map(articulo => (
              <div
              onClick={() => {
                setArticuloSeleccionado(articulo)
              }}
              style={{
                display:'flex',
                flexDirection:'row',
                //flex:1
              }}
              >            
              {columna(articulo?.articulo,20,'white',400, articulo)}
              {columna(articulo?.categoria,20,'white',400, articulo)}
              {columna(articulo?.cantidad,20,'white',400, articulo)}
              {columna(articulo?.puntosDeporti,20,'white',400, articulo)}
              <div
              style={{
                display:'flex',
                flex:5,
                fontWeight:500,
                color:articulo?.idTienda == articuloSeleccionado?.idTienda ? 'white':'black',
                backgroundColor:articulo?.idTienda == articuloSeleccionado?.idTienda ? '#03ABC6':'white'
              }}
              >
            <IconButton
              onClick={() => setModalAgregaArticulo(true)}
              >
              <Edit style={{color:articulo?.idTienda == articuloSeleccionado?.idTienda ? 'white':'green',}} />
            </IconButton>
            <IconButton
              onClick={() => eliminarArticulo(articulo?.idTienda)}
              >
              <Delete style={{color:articulo?.idTienda == articuloSeleccionado?.idTienda ? 'white':'red',}} />
            </IconButton>
            </div>
            </div>
            ))
          }
      </div>
      <div
      style={{
        display:'flex',
        flexDirection:'column',
        flex:30
      }}
      >
        {
          articuloSeleccionado?.imagen ? (
        <img 
          src={'https://www.deporti.com.mx/imagenes/articulos/'+articuloSeleccionado?.imagen}
          style={{
            maxWidth:250,
            marginLeft:30,
            marginRight:20,
            borderRadius:15
          }}
        />
          ):(
            <Image style={{fontSize:300, color:'gray'}} />
          )
        }
      </div>
      </div>

    </>
    <ModalAgregaArticulo />
    </TiendaContext.Provider>
  )
}
