import { createContext, useEffect, useState } from "react";


export const AuthContext = createContext();

export const AuthProvider = (props) => {
    const [usuario, setUsuario] = useState();
    const [logueado, setLogueado] = useState(false);
    const [opcionSeleccionada, setOpcionSeleccionada] = useState('dashboard');
    const [nombreFiltro, setNombreFiltro] = useState('');
    const [etiquetaFiltro, setEtiquetaFiltro] = useState();
    const [actualizaMapa, setActualizaMapa] = useState(0)

    useEffect(() => {
        if(logueado) {
            console.log('guardando datos en localstorage', JSON.stringify(usuario), logueado)
          localStorage.setItem('logueado', logueado)
          localStorage.setItem('usuario', JSON.stringify(usuario))
        }
      }, [logueado, usuario])
      
  
      
    return(
        <AuthContext.Provider
            value={{
                usuario,
                setUsuario,
                logueado,
                setLogueado,
                opcionSeleccionada, setOpcionSeleccionada,
                nombreFiltro, setNombreFiltro,
                etiquetaFiltro, setEtiquetaFiltro,
                actualizaMapa, setActualizaMapa
            }}
            >
            {props.children}
        </AuthContext.Provider>
    )
}