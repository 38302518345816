import { Button, Checkbox, Dialog, DialogActions, DialogContent, Divider } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { UsuariosContext } from '../../context/UsuariosContext'

export const ModalDeportesFavoritos = () => {
    const {modalDeportesFavoritos, setModalDeportesFavoritos, deportesFavoritos,setDeportesFavoritos, actualizaDeporteFavorito, usuarioSeleccionado,
        
    } = useContext(UsuariosContext)
    const [actualizaFavoritos, setActualizaFavoritos] = useState(0)

    const handleClose = () => {
        setModalDeportesFavoritos(false)
    }

    useEffect(() => {
        console.log('acutlaizando', actualizaFavoritos)
    }, [actualizaFavoritos])
    
    
    useEffect(() => {
        console.log('actualizar cambiaron deportes favoritos')
    }, [deportesFavoritos])
    

    const handleDeporteFavorito = async(e, deporte, index) => {
        if(usuarioSeleccionado?.idUsuario) {
            actualizaDeporteFavorito(deporte?.idDeporte, e.target.checked)
        } else {
            console.log('agregar en datos, usuario nuevo')
                console.log('valor del deporte', deportesFavoritos[index])
                const nuevosDeportes = deportesFavoritos;
                nuevosDeportes[index].idUsuario=-1;
                console.log('nuevosDeportes',nuevosDeportes)
                await setDeportesFavoritos(nuevosDeportes)
                await console.log('deportes favoritos x', deportesFavoritos)
                setActualizaFavoritos(actualizaFavoritos+1)
        }
    }


    const verificaDeporte = (deporte) => {
        deportesFavoritos?.map(deporteFavorito => {
            console.log('existeDeporte', deporteFavorito)
        })
        return false;
    }

    return (
    <Dialog
        open={modalDeportesFavoritos}
        onClose={handleClose}
    >
        <DialogContent>
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    fontSize:11
                }}
            >
                {
                    deportesFavoritos?.map((deporte, index) => (
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'center'
                            }}
                        >
                            <Checkbox 
                                onChange={(e) => {console.log(e); handleDeporteFavorito(e, deporte, index) }}
                                checked={deporte?.idUsuario!=null }
                                size='small' style={{padding:2}} />
                            {deporte.deporte}
                        </div>
                    ))
                }
            </div>
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button onClick={handleClose} >Guardar</Button>
        </DialogActions>
    </Dialog>
  )
            
}
