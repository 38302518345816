import { Button, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Calendario } from '../components/torneos/Calendario';
import { ModalAgregarTorneo } from '../components/torneos/ModalAgregarTorneo';
import { TablaPosiciones } from '../components/torneos/TablaPosiciones';
import { TorneosContext } from '../context/TorneosContext';
import { Delete, Edit } from '@mui/icons-material';
import { AuthContext } from '../context/AuthContext';

export const TorneosDeportivosPage = () => {
    const { torneos, obtenerTorneos, torneoSeleccionado, setTorneoSeleccionado,
        modalAgregaTorneo, setModalAgregaTorneo, eliminarTorneo} = useContext(TorneosContext);
        const {usuario} = useContext(AuthContext)
        const uploadInputRef = useRef(null);
        const imgMilitante = useRef(null);
        const [file, setFile] = useState()
    
        const refForm = useRef();
    
        const SeleccionImagen = (event) => {
          console.log('selecciono imagen',event);
          setFile({file:event.target.files[0]});
  
      };

      useEffect(() => {
        console.log('state file', file, torneoSeleccionado);
        const data= new FormData();
        data.append('archivo', uploadInputRef.current?.files[0]);
        data.append('idTorneo', torneoSeleccionado?.idTorneoDeportivo);
        fetch(process.env.REACT_APP_API_URL+'/importar.calendario.php', {
          method:'POST',
          body:data
      })
      .then(response => response.text())
      .then(response =>{
        console.log('respuesta del servidor', response)
      })
      
      }, [file])

    useEffect(() => {
        obtenerTorneos();
    }, [])
    

    const columna = (titulo, ancho, fondo, anchoLetra, torneo) => {
    //  console.log('dibujando', torneo, torneoSeleccionado)
        return (
          <Typography
          key={torneo?.idTorneo}
          style={{
            flex:ancho,
            fontWeight:anchoLetra,
            fontSize:14,
            paddingLeft:5,
            paddingRight:5,
            padding:5,
            color:torneo?.idTorneoDeportivo === torneoSeleccionado?.idTorneoDeportivo ? 'white':'black',
            backgroundColor:torneo?.idTorneoDeportivo === torneoSeleccionado?.idTorneoDeportivo ? '#03ABC6':'white'
          }}
        >
          {titulo}
        </Typography>
      
        )
      }
    
      const columna1 = (titulo, ancho, fondo, anchoLetra, torneo) => {
        return (
          <Typography
          style={{
            flex:ancho,
            fontWeight:anchoLetra,
            fontSize:14,
            paddingLeft:5,
            paddingRight:5,
            padding:5,
            color:torneo?.idTorneo =='black',
            backgroundColor:fondo
          }}
        >
          {titulo}
        </Typography>
        )
      }

  return (
    <>
    <div
      style={{
        marginLeft:20,
        marginTop:20,
        marginRight:20,
        display:'flex',
        flexDirection:'row',
        flex:5,
        justifyContent:'space-between',
        alignItems:'center',
        maxWidth:'40%'
      }}
    > 
      <Typography
        style={{
          fontSize:20,
          fontWeight:600
        }}
      >
        Torneos deportivos
      </Typography>
      <Button
        onClick={() => {setTorneoSeleccionado(); setModalAgregaTorneo(true)}}
        variant="contained"
      >
        Agregar
      </Button>
    </div>
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        flex:95
      }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex:50
            }}
        >
            <div
                style={{
                display:'flex',
                flexDirection:'row',
                width:'100%',
                backgroundColor:'red'
                //flex:1
                }}
            >
                {columna1('Nombre del torneo',20,'#03ABC6',500)}
                {columna1('Liga',20,'#03ABC6',500)}
                {columna1('Ciudad',20,'#03ABC6',500)}
                {columna1('Inicio',10,'#03ABC6',500)}
                {columna1('Fin',10,'#03ABC6',500)}
                {columna1('',5,'#03ABC6',500)}
            </div>
              {
                torneos?.map(torneo => (
                  <div
                    onClick={() => {
                      setTorneoSeleccionado(torneo)
                    }}
                    key={torneo?.idTorneo}
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      cursor:'pointer'
                    }}
                  >
                      {columna(torneo?.nombreTorneo,20,'#03ABC6',400,torneo)}
                      {columna(torneo?.nombre,20,'#03ABC6',400,torneo)}
                      {columna(torneo?.nombreCiudad,20,'#03ABC6',400,torneo)}
                      {columna(dayjs(torneo?.inicio).format('DD/MM/YYYY'),10,'#03ABC6',400,torneo)}
                      {columna(dayjs(torneo?.fin).format('DD/MM/YYYY'),10,'#03ABC6',400,torneo)}
                      <div>
                        {
                           (usuario?.permisos)?.substr(14,1)==1 ? (
                        <IconButton
                          onClick={() =>{setModalAgregaTorneo(true)}}
                          size='small'
                        >
                          <Edit style={{fontSize:12, color:'green'}} />
                        </IconButton>
                           ):null
                        }
                      </div>
                      <div>
                      {
                           (usuario?.permisos)?.substr(13,1)==1 ? (
                      <IconButton
                          size='small'
                          onClick={() => {eliminarTorneo()}}
                        >
                        <Delete style={{fontSize:12, color:'red'}} />
                      </IconButton>
                           ):null
                          }
                      </div>
                  </div>
                ))
              }
        </div>
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex:35
            }}
        >

            <TablaPosiciones />
        </div>
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex:35
            }}
        >
         <div
            style={{
              display:'flex',
              flexDirection:'row',
              marginLeft:10,
              justifyContent:'space-between',
              alignItems:'center'
            }}
          >
            <Typography style={{fontWeight:'600'}}>Calendario</Typography>
            <Button
                style={{
                  textTransform:'unset'
                }}
                //variant="contained"
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                >Actualizar</Button>
            <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden', width:1}} accept='.xls,.xlsx,.xlsm' type="file" />
            
          </div>          
            <Calendario />
        </div>
    </div>
    <ModalAgregarTorneo />
    </>
  )
}
