import { Autocomplete, Box, Button, createMuiTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TextField, Typography } from '@mui/material';
import { UNSAFE_convertRoutesToDataRoutes } from '@remix-run/router';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ObtenerDatos } from '../../helpers/ApiHelper';
import { EscuelasContext } from '../../pages/EscuelasDeportivasPage';
import { Image, ImageSearch, Input } from '@mui/icons-material'
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const theme = createMuiTheme({
    overrides: {
      MuiDialog: {
        paper: {
          borderWidth: 10,
          borderRadius: 10,
          borderColor: "red",
          borderStyle: "solid",
          backgroundColor: "#101010",
          color: "#fff"
        }
      }
    }
  });


export const ModalAgregaClase = () => {
    const {modalAgregaClase, setModalAgregaClase, escuelaSeleccionada, obtenerEscuelas, claseSeleccionada, setClaseSeleccionada, obtenerClases} = useContext(EscuelasContext);
    console.log('escuela seleccionada al entrar a crear clase', escuelaSeleccionada, claseSeleccionada)
    const [datosClase, setDatosClase] = useState({idEscuela:escuelaSeleccionada?.idEscuela});
    const [deportes, setDeportes] = useState();
    const [recintos, setRecintos] = useState();
    const [valorDireccion, setValorDireccion] = useState();
    const [valorRecinto, setValorRecinto] = useState()
    const [errorGuardado, setErrorGuardado] = useState(false);
    const uploadInputRef = useRef(null);
    const uploadInputRef1 = useRef(null);
    const imgMilitante = useRef(null);
    const imgMilitante1 = useRef(null);
    const [file, setFile] = useState()
    const [file1, setFile1] = useState()
    const refForm = useRef();
    const [adjuntos, setAdjuntos] = useState();
    const [valorPoblacion, setValorPoblacion] = useState();
    const [poblacion, setPoblacion] = useState();


    const handleClose = async() => {
        await setDatosClase({})
        setModalAgregaClase(false);
        setClaseSeleccionada();
    }

    useEffect(() => {
      console.log('selecciono escuela', escuelaSeleccionada)
    
    }, [escuelaSeleccionada])
    


    const obtenerRecintos = () => {
      ObtenerDatos('/recintos.php?funcion=obtenerRecintos', null).then(response => {
        setDeportes(response);
        console.log('recintos', response);
        const opciones = new Array();
        response?.map(dato => {
          opciones.push({label:dato.nombre, id:dato.idRecinto, direccion:dato.direccion})
          if(claseSeleccionada?.idRecinto && dato.idRecinto==claseSeleccionada?.idRecinto) {
            setValorRecinto({label:dato.nombre, id:dato.idRecinto})
          }
        })
        setRecintos(opciones)
      });
    }

    const obtenerPoblacion = () => {
      ObtenerDatos('/escuelas.php?funcion=poblacion', null).then(response => {
        setDeportes(response);
        console.log('poblacion', response);
        const opciones = new Array();
        response?.map(dato => {
          opciones.push({label:dato.poblacion, id:dato.idPoblacion})
          if(claseSeleccionada?.idPoblacion && dato.idPoblacion==claseSeleccionada?.idPoblacion) {
            setValorPoblacion({label:dato.poblacion, id:dato.idPoblacion})
          }
        })
        setPoblacion(opciones)
      });
    }


    useEffect(() => {
      obtenerRecintos();
      obtenerPoblacion();
    }, [modalAgregaClase])
    

    const actualizaDatos = (dato, valor) => {
       setDatosClase({
           ...datosClase,
           [dato]:valor
       })
    }

    useEffect(() => {
      console.log('Se actualizaron los datos de la escuela', datosClase)
    }, [datosClase])

    const verificaAccion = () => {
      console.log('recinto seleccionado en verificar accion', escuelaSeleccionada)
      if(claseSeleccionada?.idEscuelaClase) {
        console.log('es edicion, se deben igualar datos')
        setDatosClase(claseSeleccionada)
      } else {
      }
    }

    useEffect(() => {
      verificaAccion();
    },[escuelaSeleccionada, claseSeleccionada])
    

    const handleSubmit =  (event) => {
        event.preventDefault();
        const data = new FormData();
        data.append('datosClase', JSON.stringify(datosClase))
        data.append('idEscuela', escuelaSeleccionada?.idEscuela)

         ObtenerDatos('/escuelas.php?funcion=agregarClase', data).then(respuesta => {
            console.log('respuesta de guardado en modal', respuesta);
            if(respuesta!=1) {
                setErrorGuardado(true);
            } else{
                setErrorGuardado(false);
                obtenerEscuelas();
                obtenerClases();
                handleClose();
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Clase guardada correctamente",
                  showConfirmButton: false,
                  timer: 1500
                });            

            }
        })
    }

  return (
      <div
        style={{
            minWidth:400
        }}
    >
     <Dialog
     
     
     maxWidth='xs' minWidth='xs' fullWidth='true'  open={modalAgregaClase} onClose={handleClose}>
        <form onSubmit={handleSubmit} ref={refForm}>
        <DialogTitle>Clase</DialogTitle>
        <DialogContent
            style={{minWidth:400, minHeight:'70vh'}}
        >
          <div
            style={{
              display:'flex',
              flexDirection:'row'
            }}
          >
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:70,
                marginRight:60
              }}
            >
          <Autocomplete
            InputLabelProps={{shrink:true}}
            variant="standard"
            onChange={(event, newValue) => {console.log('new value', newValue.direccion);
              setDatosClase({
                ...datosClase,
                idRecinto:newValue.id,
                direccion:newValue.direccion
            })
            ; setValorRecinto(newValue);}}
            value={valorRecinto?.id ? valorRecinto:null}
            //defaultValue={tiposActivo[index]}
            //disablePortal
            id="combo-box-demo"
            options={recintos}
            sx={{ zIndex:999999999 }}
            style={{zIndex:99999999, flex:25, margintop:3, marginLeft:10}}
            renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Recinto" />}
          />
          <TextField
              style={{
                display:'flex',
                flex:10,
                marginLeft:10
              }}
              InputLabelProps={{shrink:true}}
              autoFocus
              margin="dense"
              label="Dirección"
              type="text"
              fullWidth
              variant="standard"
              required={true}
              onChange={(e) => actualizaDatos('direccion',e.target.value)}
              value={datosClase?.direccion || ''}
            />     
          <TextField
              style={{
                display:'flex',
                flex:10,
                marginLeft:10
              }}
              InputLabelProps={{shrink:true}}
              autoFocus
              margin="dense"
              label="Costo"
              type="number"
              fullWidth
              variant="standard"
              required={true}
              onChange={(e) => actualizaDatos('costo',e.target.value)}
              value={datosClase?.costo || 0}
            />     
         <Autocomplete
            InputLabelProps={{shrink:true}}
            variant="standard"
            onChange={(event, newValue) => {
              console.log('poblacion seleccionada', newValue)
              setDatosClase({
                ...datosClase,
                idPoblacion:newValue.id,
            })
            ; setValorPoblacion(newValue);}}
            value={valorPoblacion?.id ? valorPoblacion:null}
            //defaultValue={tiposActivo[index]}
            //disablePortal
            id="combo-box-demo"
            options={poblacion}
            sx={{ zIndex:999999999 }}
            style={{zIndex:99999999, flex:25, margintop:3, marginLeft:10}}
            renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Poblacion" />}
          />    
          <div
            style={{
              display:'flex',
              flexDirection:'row',
            }}
          >
            <TextField
              style={{
                display:'flex',
                flex:10,
                marginLeft:10
              }}
              InputLabelProps={{shrink:true}}
              autoFocus
              margin="dense"
              label="Edad, inicio"
              type="number"
              fullWidth
              variant="standard"
              required={true}
              onChange={(e) => actualizaDatos('edadInicio',e.target.value)}
              value={datosClase?.edadInicio || 0}
            />     
            <TextField
              style={{
                display:'flex',
                flex:10,
                marginLeft:10
              }}
              InputLabelProps={{shrink:true}}
              autoFocus
              margin="dense"
              label="fin"
              type="number"
              fullWidth
              variant="standard"
              required={true}
              onChange={(e) => actualizaDatos('edadFin',e.target.value)}
              value={datosClase?.edadFin || 0}
            />     
          </div>        
          <TextField
              style={{
                display:'flex',
                flex:10,
                marginLeft:10
              }}
              InputLabelProps={{shrink:true}}
              autoFocus
              margin="dense"
              label="Categorías"
              type="text"
              fullWidth
              variant="standard"
              required={false}
              onChange={(e) => actualizaDatos('categorias',e.target.value)}
              value={datosClase?.categorias || ''}
            />     
          <TextField
              style={{
                display:'flex',
                flex:10,
                marginLeft:10
              }}
              InputLabelProps={{shrink:true}}
              autoFocus
              margin="dense"
              label="Otro"
              type="text"
              fullWidth
              variant="standard"
              required={false}
              onChange={(e) => actualizaDatos('otro',e.target.value)}
              value={datosClase?.otro || ''}
            />     
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'flex-end'
              }}
            >
              <Typography style={{color:'gray', minWidth:75}}>Lúnes</Typography>
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Inicio"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('lunesInicio',e.target.value)}
                value={datosClase?.lunesInicio || ''}
                />               
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Fin"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('lunesFin',e.target.value)}
                value={datosClase?.lunesFin || ''}
              />               
              </div>          
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'flex-end'
              }}
            >
              <Typography style={{color:'gray', minWidth:75}}>Martes</Typography>
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Inicio"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('martesInicio',e.target.value)}
                value={datosClase?.martesInicio || ''}
                />               
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Fin"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('martesFin',e.target.value)}
                value={datosClase?.martesFin || ''}
              />               
              </div>          
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'flex-end'
              }}
            >
              <Typography style={{color:'gray', minWidth:75}}>Miércoles</Typography>
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Inicio"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('miercolesInicio',e.target.value)}
                value={datosClase?.miercolesInicio || ''}
                />               
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Fin"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('miercolesFin',e.target.value)}
                value={datosClase?.miercolesFin || ''}
              />               
              </div>          
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'flex-end'
              }}
            >
              <Typography style={{color:'gray', minWidth:75}}>Juéves</Typography>
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Inicio"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('juevesInicio',e.target.value)}
                value={datosClase?.juevesInicio || ''}
                />               
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Fin"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('juevesFin',e.target.value)}
                value={datosClase?.juevesFin || ''}
              />               
              </div>          
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'flex-end'
              }}
            >
              <Typography style={{color:'gray', minWidth:75}}>Viernes</Typography>
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Inicio"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('viernesInicio',e.target.value)}
                value={datosClase?.viernesInicio || ''}
                />               
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Fin"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('viernesFin',e.target.value)}
                value={datosClase?.viernesFin || ''}
              />               
              </div>          
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'flex-end'
              }}
            >
              <Typography style={{color:'gray', minWidth:75}}>Sábado</Typography>
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Inicio"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('sabadoInicio',e.target.value)}
                value={datosClase?.sabadoInicio || ''}
                />               
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Fin"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('sabadoFin',e.target.value)}
                value={datosClase?.sabadoFin || ''}
              />               
              </div>          
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'flex-end'
              }}
            >
              <Typography style={{color:'gray', minWidth:75}}>Domingo</Typography>
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Inicio"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('domingoInicio',e.target.value)}
                value={datosClase?.domingoInicio || ''}
                />               
              <TextField
                style={{
                  display:'flex',
                  flex:25,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Fin"
                type="time"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('domingoFin',e.target.value)}
                value={datosClase?.domingoFin || ''}
              />               
              </div>          
         

              </div>
            </div>


          {
            errorGuardado && (
              <Typography
                style={{
                  fontSize:12,
                  color:'red'
                }}
              >
                Error al guardar
            </Typography>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type='submit' >Guardar</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
