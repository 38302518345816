import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TextField, Typography } from '@mui/material';
import { UNSAFE_convertRoutesToDataRoutes } from '@remix-run/router';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ObtenerDatos } from '../../helpers/ApiHelper';
import { Image, ImageSearch, Input } from '@mui/icons-material'
import { LigasContext } from '../../pages/LigasDeportivasPage';
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
export const ModalAgregaLiga = () => {
    const {modalAgregaLiga, setModalAgregaLiga, ligaSeleccionada, obtenerLigas} = useContext(LigasContext);
    const [datosLiga, setDatosEscuela] = useState();
    const [deportes, setDeportes] = useState();
    const [valorDeporte, setValorDeporte] = useState();
    const [ciudades, setCiudades] = useState();
    const [valorCiudad, setValorCiudad] = useState();    
    const [errorGuardado, setErrorGuardado] = useState(false);
    const uploadInputRef = useRef(null);
    const uploadInputRef1 = useRef(null);
    const imgMilitante = useRef(null);
    const imgMilitante1 = useRef(null);
    const [file, setFile] = useState()
    const [file1, setFile1] = useState()
    const refForm = useRef();
    const [adjuntos, setAdjuntos] = useState();


    const handleClose = async() => {
        await setDatosEscuela({})
        setModalAgregaLiga(false)
    }

    const SeleccionImagen = (event) => {
      setFile({file:event.target.files[0]});
      console.log('selecciono imagen escuela',event);
      var reader = new FileReader();
      reader.onload = function(){
          imgMilitante.current.src = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
  };

    const SeleccionImagen1 = (event) => {
      setFile1({file:event.target.files[0]});
      console.log('selecciono imagen ine',event);
      var reader = new FileReader();
      reader.onload = function(){
          imgMilitante1.current.src = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
  };

    const seleccionArchivos = (e) => {
      console.log('archivos seleccionados',e);
      setAdjuntos(e.target.files);
    }


    const obtenerDeportes = () => {
      ObtenerDatos('/deportes.php?funcion=obtenerDeportes', null).then(response => {
        setDeportes(response);
        console.log('deportes', response);
        const opciones = new Array();
        response?.map(dato => {
          opciones.push({label:dato.deporte, id:dato.idDeporte})
        })
        setDeportes(opciones)
      });
    }

    const obtenerCiudades = () => {
      ObtenerDatos('/ciudades.php?funcion=obtenerCiudades', null).then(response => {
        const opciones = new Array();
        response?.map(dato => {
          opciones.push({label:dato.nombreCiudad, id:dato.idCiudad})
        })

        setCiudades(opciones);
        console.log('ciudades', response);
      });
    }    

    useEffect(() => {
      console.log('liga seleccionada en effect', ligaSeleccionada)
      if(modalAgregaLiga) {
        obtenerDeportes();
        obtenerCiudades();
        obtenerLigas();
        if(ligaSeleccionada?.idLiga) {
          console.log('liga seleccionada para editar', ligaSeleccionada)
          setValorDeporte({
            id:ligaSeleccionada?.idDeporte,
            label:ligaSeleccionada?.deporte
          })
          setValorCiudad({
            id:ligaSeleccionada?.idCiudad,
            label:ligaSeleccionada?.nombreCiudad
          })
          setDatosEscuela(ligaSeleccionada)
        }
      }
    }, [modalAgregaLiga])
    

    const actualizaDatos = (dato, valor) => {
       setDatosEscuela({
           ...datosLiga,
           [dato]:valor
       })
    }

    useEffect(() => {
      console.log('Se actualizaron los datos de la liga', datosLiga)
    }, [datosLiga])

   
   

    const handleSubmit =  (event) => {
        event.preventDefault();
        const data = new FormData();
        data.append('datosLiga', JSON.stringify(datosLiga))
        data.append('imagenEscuela', uploadInputRef.current.files[0])
        data.append('imagenINE', uploadInputRef1.current.files[0])

         ObtenerDatos('/ligas.php?funcion=agregarLiga', data).then(respuesta => {
            console.log('respuesta de guardado en modal', respuesta);
            if(respuesta!=1) {
                setErrorGuardado(true);

            } else{
                setErrorGuardado(false);
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Liga guardada correctamente",
                  showConfirmButton: false,
                  timer: 1500
                });         
                obtenerLigas();
                handleClose();

            }
        })
    }

  return (
      <div
        style={{
            minWidth:400
        }}
    >
     <Dialog maxWidth='md' minWidth='md' fullWidth='true'  open={modalAgregaLiga} onClose={handleClose}>
        <form onSubmit={handleSubmit} ref={refForm}>
        <DialogTitle>Liga deportiva</DialogTitle>
        <DialogContent
            style={{minWidth:400, minHeight:'70vh'}}
        >
          <div
            style={{
              display:'flex',
              flexDirection:'row'
            }}
          >
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:70,
                marginRight:60
              }}
            >

 
          <TextField
            style={{
              display:'flex',
              marginLeft:10
            }}
            InputLabelProps={{shrink:true}}
            autoFocus
            margin="dense"
            label="Nombre"
            type="text"
            fullWidth
            variant="standard"
            required={true}
            onChange={(e) => actualizaDatos('nombre',e.target.value)}
            value={datosLiga?.nombre || ''}
          />
          <Autocomplete
          InputLabelProps={{shrink:true}}
            variant="standard"
            onChange={(event, newValue) => {console.log('new value', newValue); setValorDeporte(newValue); actualizaDatos('idDeporte', newValue.id)}}
            value={valorDeporte}
            //defaultValue={tiposActivo[index]}
            //disablePortal
            id="combo-box-demo"
            options={deportes}
            sx={{ zIndex:999999999 }}
            style={{zIndex:99999999, flex:25, margintop:3, marginLeft:10}}
            renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Deporte / Disciplina" />}
            />
  


          <Autocomplete
          InputLabelProps={{shrink:true}}
            variant="standard"
            onChange={(event, newValue) => {console.log('new value', newValue);setValorCiudad(newValue); actualizaDatos('idCiudad', newValue.id)}}
            value={ valorCiudad}
           // defaultValue={valorCiudad || null}
            //disablePortal
            id="combo-box-demo"
            options={ciudades}
            sx={{ width: '100%', zIndex:999999999, }}
            style={{zIndex:99999999, display:'flex', flex:25, marginTop:0, marginLeft:10 }}
            renderInput={(params) => <TextField InputLabelProps={{shrink:true}} required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8}} {...params} label="Ciudad" />}
          />       
         <TextField
            style={{
              display:'flex',
              flex:10,
              marginLeft:10
            }}
            InputLabelProps={{shrink:true}}
            autoFocus
            margin="dense"
            label="Telefono"
            type="text"
            fullWidth
            variant="standard"
            required={true}
            onChange={(e) => actualizaDatos('telefono',e.target.value)}
            value={datosLiga?.telefono || ''}
          />               
            <TextField
              style={{
                display:'flex',
                flex:25,
                marginLeft:10
              }}
              InputLabelProps={{shrink:true}}
              autoFocus
              margin="dense"
              label="Facebook"
              type="text"
              fullWidth
              variant="standard"
              required={false}
              onChange={(e) => actualizaDatos('facebook',e.target.value)}
              value={datosLiga?.facebook || ''}
              />               
         
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:30,
                marginRight:20
              }}
              >
 
              <TextField
                style={{
                  display:'flex',
                  flex:10,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Instagram"
                type="text"
                fullWidth
                variant="standard"
                required={false}
                onChange={(e) => actualizaDatos('instagram',e.target.value)}
                value={datosLiga?.instagram || ''}
                />               
              <TextField
                style={{
                  display:'flex',
                  flex:10,
                  marginLeft:10
                }}
                InputLabelProps={{shrink:true}}
                autoFocus
                margin="dense"
                label="Correo"
                type="email"
                fullWidth
                variant="standard"
                required={true}
                onChange={(e) => actualizaDatos('correo',e.target.value)}
                value={datosLiga?.correo || ''}
                />               
   
    
         <TextField
            style={{
              display:'flex',
              flex:10,
              marginLeft:10
            }}
            InputLabelProps={{shrink:true}}
            autoFocus
            margin="dense"
            label="Nombre del responsable"
            type="text"
            fullWidth
            variant="standard"
            required={true}
            onChange={(e) => actualizaDatos('nombreResponsable',e.target.value)}
            value={datosLiga?.nombreResponsable || ''}
          />               
         <TextField
            style={{
              display:'flex',
              flex:10,
              marginLeft:10
            }}
            InputLabelProps={{shrink:true}}
            autoFocus
            margin="dense"
            label="Cargo del responsable"
            type="text"
            fullWidth
            variant="standard"
            required={true}
            onChange={(e) => actualizaDatos('cargoResponsable',e.target.value)}
            value={datosLiga?.cargoResponsable || ''}
          />               
            <TextField
              style={{
                display:'flex',
                flex:25,
                marginLeft:10
              }}
              InputLabelProps={{shrink:true}}
              autoFocus
              margin="dense"
              label="CTA"
              type="text"
              fullWidth
              variant="standard"
              required={true}
              onChange={(e) => actualizaDatos('cta',e.target.value)}
              value={datosLiga?.cta || ''}
              />               
                
      
            </div>
            </div>


            <div
              style={{
                display:'flex',
                flexDirection:'column'
              }}
            >
            

              <Button
                style={{
                  textTransform:'unset'
                }}
                variant="contained"
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                >Imagen del evento <ImageSearch />
              </Button>
            <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden'}} accept='.jpg,.png' type="file" />
            <img  height={300} width='auto' style={{objectFit:'scale-down'}}  ref={imgMilitante} 
             src={ligaSeleccionada?.idLiga ? 'https://deporti.com.mx/imagenes/ligas/'+ligaSeleccionada?.logo:''}
            />
  
            

              <Button
                style={{
                  textTransform:'unset'
                }}
                variant="contained"
                onClick={() => uploadInputRef1.current && uploadInputRef1.current.click()}
                >INE <ImageSearch /></Button>
            <input name="imgMilitante1" onChange={event => SeleccionImagen1(event) } ref={uploadInputRef1} style={{visibility:'hidden'}} accept='.jpg,.png' type="file" />
            <img height={300} width='auto' style={{objectFit:'scale-down'}}  ref={imgMilitante1}  
                src={ligaSeleccionada?.idLiga ? 'https://deporti.com.mx/imagenes/ligas/'+ligaSeleccionada?.id:''}            />
              </div>      


            </div>


          {
            errorGuardado && (
              <Typography
                style={{
                  fontSize:12,
                  color:'red'
                }}
              >
                Error al guardar
            </Typography>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type='submit' >Guardar</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
