import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TextField, Typography } from '@mui/material';
import { UNSAFE_convertRoutesToDataRoutes } from '@remix-run/router';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ObtenerDatos } from '../../helpers/ApiHelper';
import { EscuelasContext } from '../../pages/EscuelasDeportivasPage';
import { Image, ImageSearch, Input } from '@mui/icons-material'
import { TiendaContext } from '../../pages/TiendaDeportiPage';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
export const ModalAgregaArticulo = () => {
    const {modalAgregaArticulo, setModalAgregaArticulo, articuloSeleccionado, obtenerArticulos} = useContext(TiendaContext);
    const [datosArticulo, setDatosArticulo] = useState();
    const [categorias, setCategorias] = useState();
    const [valorCategoria, setValorCategoria] = useState({id:0,label:''});
    const [errorGuardado, setErrorGuardado] = useState(false);
    const uploadInputRef = useRef(null);
    const imgMilitante = useRef(null);
    const [file, setFile] = useState()
    const refForm = useRef();
    const [adjuntos, setAdjuntos] = useState();


    const SeleccionImagen = (event) => {
      setFile({file:event.target.files[0]});
      console.log('selecciono imagen escuela',event);
      var reader = new FileReader();
      reader.onload = function(){
          imgMilitante.current.src = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
  };

    const handleClose = async() => {
        await setDatosArticulo({})
        setModalAgregaArticulo(false)
    }

    const obtenerCategorias = () => {
      ObtenerDatos('/tienda.php?funcion=obtenerCategorias', null).then(response => {
        console.log('cateogiras en agregar', response)
        const opciones = new Array();
        response?.map(dato => {
          opciones.push({label:dato.categoria, id:dato.idCategoriaTienda})
        })
        setCategorias(opciones)
      })
    }


    useEffect(() => {
      obtenerArticulos();
      obtenerCategorias();
    }, [modalAgregaArticulo])
    

    const actualizaDatos = (dato, valor) => {
       setDatosArticulo({
           ...datosArticulo,
           [dato]:valor

       })
    }

    const verificaAccion = () => {
      if(articuloSeleccionado?.idTienda) {
        console.log('Esta editando', articuloSeleccionado)
        setDatosArticulo(articuloSeleccionado)
        setValorCategoria({
          id:articuloSeleccionado?.idCategoriaTienda,
          label:articuloSeleccionado?.categoria
        })
      }
    }

    useEffect(() => {
      if(modalAgregaArticulo) {
        verificaAccion()
      }
    }, [modalAgregaArticulo])
    

    useEffect(() => {
    },[articuloSeleccionado])

    const handleSubmit =  (event) => {
        event.preventDefault();
        const data = new FormData();
        data.append('datosArticulo', JSON.stringify(datosArticulo))
        data.append('imagenArticulo', uploadInputRef.current.files[0])

         ObtenerDatos('/tienda.php?funcion=agregarArticulo', data).then(respuesta => {
            console.log('respuesta de guardado en modal', respuesta);
            if(respuesta!=1) {
                setErrorGuardado(true);
            } else{
                setErrorGuardado(false);
                obtenerArticulos();
                handleClose();

            }
        })
    }

  return (
      <div
        style={{
            minWidth:400
        }}
    >
     <Dialog maxWidth='md' minWidth='md' fullWidth='true'  open={modalAgregaArticulo} onClose={handleClose}>
        <form onSubmit={handleSubmit} ref={refForm}>
        <DialogTitle>Artículo</DialogTitle>
        <DialogContent
            style={{minWidth:400, minHeight:'70vh'}}
        >
          <div
            style={{
              display:'flex',
              flexDirection:'row'
            }}
          >
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:70,
                marginRight:60
              }}
            >

 
          <TextField
              style={{
                display:'flex',
                flex:10,
                marginLeft:10
              }}
              InputLabelProps={{shrink:true}}
              autoFocus
              margin="dense"
              label="Artículo"
              type="text"
              fullWidth
              variant="standard"
              required={true}
              onChange={(e) => actualizaDatos('articulo',e.target.value)}
              value={datosArticulo?.articulo || ''}
            />               
          <Autocomplete
          InputLabelProps={{shrink:true}}
            variant="standard"
            onChange={(event, newValue) => {console.log('new value', newValue); setValorCategoria(newValue); actualizaDatos('idCategoria', newValue.id)}}
            value={valorCategoria?.id ? valorCategoria:null}
            //defaultValue={tiposActivo[index]}
            //disablePortal
            id="combo-box-demo"
            options={categorias}
            sx={{ zIndex:999999999 }}
            style={{zIndex:99999999, flex:25, margintop:3, marginLeft:10}}
            renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Categoría" />}
            />
            <TextField
              style={{
                display:'flex',
                flex:25,
                marginLeft:10
              }}
              InputLabelProps={{shrink:true}}
              autoFocus
              margin="dense"
              label="Cantidad"
              type="number"
              fullWidth
              variant="standard"
              required={false}
              onChange={(e) => actualizaDatos('cantidad',e.target.value)}
              value={datosArticulo?.cantidad || ''}
              />               
            <TextField
              style={{
                display:'flex',
                flex:25,
                marginLeft:10
              }}
              InputLabelProps={{shrink:true}}
              autoFocus
              margin="dense"
              label="Puntos Deporti"
              type="number"
              fullWidth
              variant="standard"
              required={false}
              onChange={(e) => actualizaDatos('puntosDeporti',e.target.value)}
              value={datosArticulo?.puntosDeporti || ''}
              />               
            <TextField
              style={{
                display:'flex',
                flex:25,
                marginLeft:10
              }}
              InputLabelProps={{shrink:true}}
              autoFocus
              margin="dense"
              label="Recoger en"
              type="text"
              fullWidth
              variant="standard"
              required={false}
              onChange={(e) => actualizaDatos('recogerEn',e.target.value)}
              value={datosArticulo?.recogerEn || ''}
              />               
         
            </div>


            <div
              style={{
                display:'flex',
                flexDirection:'column'
              }}
            >
            

              <Button
                style={{
                  textTransform:'unset'
                }}
                variant="contained"
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                >Imagen del artículo <ImageSearch />
              </Button>
            <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden'}} accept='.jpg,.png' type="file" />
            <img  height={300} width='auto' style={{objectFit:'scale-down'}}  ref={imgMilitante} src=''/>
  
            

              </div>      


            </div>


          {
            errorGuardado && (
              <Typography
                style={{
                  fontSize:12,
                  color:'red'
                }}
              >
                Error al guardar
            </Typography>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type='submit' >Guardar</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  )
} 
