import React, { useContext, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import { AuthUsuariosProvider, UsuariosContext, UsuariosProvider } from '../context/UsuariosContext'
import { AdministracionPage } from '../pages/AdministracionPage'
import { CalendarioPage } from '../pages/CalendarioPage'
import { DeportesPage } from '../pages/DeportesPage'
import { EscuelasDeportivasPage } from '../pages/EscuelasDeportivasPage'
import HomePage from '../pages/HomePage'
import { InicialPage } from '../pages/InicialPage'
import { LigasDeportivasPage } from '../pages/LigasDeportivasPage'
import { LoginPage } from '../pages/LoginPage'
import { MapaPage } from '../pages/MapaPage'
import { RecintosDeportivosPage } from '../pages/RecintosDeportivosPage'
import { TiendaDeportiPage } from '../pages/TiendaDeportiPage'
import { TorneosDeportivosPage } from '../pages/TorneosDeportivosPage'
import { UsuariosPage } from '../pages/UsuariosPage'
import { CambiarPasswordPage } from '../pages/CambiarPasswordPage'

export const Rutas = () => {
  const {logueado, setLogueado, usuario, setUsuario} = useContext(AuthContext);

  useEffect(() => {

    console.log(localStorage.getItem('logueado'))
    if(localStorage?.getItem('logueado')=='true') {
      setLogueado(true)
      setUsuario(JSON.parse(localStorage.getItem('usuario')));
      console.log('el usuario esta logueado', localStorage.getItem('logueado'))
    } else {
      localStorage.removeItem('usuario');
      localStorage.removeItem('logueado')
      console.log('se verifico pero no esta logueado ', logueado);

    }
  }, [])


  return (
    <Routes>
      {
        !logueado ? (
          <>
          <Route path="/" element={<LoginPage />}/>
          <Route path="/login" element={<LoginPage />}/>
          <Route path="/*" element={<LoginPage />}/>
          <Route path="/restablecerPassword" element={<CambiarPasswordPage />}/>
          </>
        ):(

          <>
          <Route name='home' path="/" element={<HomePage />}>
          <Route path="/inicio" element={<InicialPage />} />  
          <Route path="/calendario" element={<CalendarioPage />} />  
          <Route path="/escuelasDeportivas" element={<EscuelasDeportivasPage />} />  
          <Route path="/tiendaDeporti" element={<TiendaDeportiPage />} />  
          <Route path="/ligasDeportivas" element={<LigasDeportivasPage />} />  
          <Route path="/torneosDeportivos" element={<TorneosDeportivosPage />} />  
          <Route path="/recintosDeportivos" element={<RecintosDeportivosPage />} />  
          <Route path="/deportes" element={<DeportesPage />} />  
          <Route path="/mapa" element={<MapaPage />} />  
          <Route path="/usuarios" element={<UsuariosPage />} />  
          <Route path="/*" element={<InicialPage />} />  
          </Route>
          </>
        )
      }
  </Routes>        
  )
}
