import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Modal, TextField, Typography } from '@mui/material';
import { UNSAFE_convertRoutesToDataRoutes } from '@remix-run/router';
import dayjs from 'dayjs';
import React, { createRef, useContext, useEffect, useRef, useState } from 'react'
import { ObtenerDatos } from '../../helpers/ApiHelper';
import GoogleMapReact from 'google-map-react';


import { RecintosContext } from '../../pages/RecintosDeportivosPage';
import { DragHandleRounded, Map, Room } from '@mui/icons-material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const defaultProps = {
    center: {
      lat: 28.65766,
      lng: -106.08410
    },
    zoom: 7,
    maptypeid: 'satellite'
  };
export const ModalAgregaRecintoComponent = () => {
    const {modalAgregaRecinto, setModalAgregaRecinto, recintoSeleccionado, obtenerRecintos} = useContext(RecintosContext);
    const [datosRecinto, setDatosRecinto] = useState();
    const [ciudades, setCiudades] = useState();
    const [valorCiudad, setValorCiudad] = useState();
    const [valorRecinto, setValorRecinto] = useState()
    const [tiposRecinto, setTiposRecinto] = useState();
    const [errorGuardado, setErrorGuardado] = useState(false);
    const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [marcador, setMarcador] = useState()
  const [isMapsLoaded, setIsMapsLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const marcadorRef = useRef();

    const refForm = useRef();

    const handleClose = async() => {
        await setDatosRecinto({})
        await setValorCiudad({id:null, label:'Selecciona una opción'});
        await setValorRecinto({id:null, label:'Selecciona una opción'});
        await setModalAgregaRecinto(false);
    }

    useEffect(() => {
      obtenerRecintos();
      obtenerCiudades();
      obtenerTiposRecinto();
    }, [modalAgregaRecinto])
    

    const actualizaDatos = (dato, valor) => {
       setDatosRecinto({
           ...datosRecinto,
           [dato]:valor
       })
    }

    useEffect(() => {
      console.log('Se actualizaron los datos del recinto', datosRecinto)
    }, [datosRecinto])
    

    const obtenerCiudades = () => {
      ObtenerDatos('/ciudades.php?funcion=obtenerCiudades', null).then(response => {
        const opciones = new Array();
        response?.map(dato => {
          opciones.push({label:dato.nombreCiudad, id:dato.idCiudad})
        })

        setCiudades(opciones);
        if(recintoSeleccionado?.idRecinto) {
          console.log('datos a comparar', recintoSeleccionado, opciones )
          const datosCiudad = opciones.filter(ciudad => parseInt(ciudad.id) == parseInt(recintoSeleccionado?.idCiudad));
          console.log('ciudad a editar', datosCiudad)
          setValorCiudad({label:datosCiudad[0].label, id:datosCiudad[0].id});
        }
        console.log('ciudades', response);
      });
    }

    useEffect(() => {
      console.log('valor ciudad', valorCiudad)
    }, [valorCiudad])
    

    const obtenerTiposRecinto = () => {
      ObtenerDatos('/recintos.php?funcion=tiposRecinto', null).then(response => {
        const opciones = new Array();
        response?.map(dato => {
          opciones.push({label:dato.descripcion, id:dato.idTipoRecinto})
        })

        setTiposRecinto(opciones);
        if(recintoSeleccionado?.idRecinto) {
          const datosRecinto = opciones.filter(recinto => parseInt(recinto.id) == parseInt(recintoSeleccionado?.idTipoRecinto));
          setValorRecinto({label:datosRecinto[0].label, id:datosRecinto[0].id});
        }        
        console.log('tiposde recinto', response);
      });
    }


    const verificaAccion = () => {
      console.log('recinto seleccionado en verificar accion', recintoSeleccionado)
      if(recintoSeleccionado?.idRecinto) {
        setDatosRecinto({
          'nombre':recintoSeleccionado.nombre,
          'direccion':recintoSeleccionado.direccion,
          'idRecinto':recintoSeleccionado.idRecinto,
          'idCiudad':{id:recintoSeleccionado.idCiudad},
          'idTipoRecinto':{id:recintoSeleccionado.idTipoRecinto},
          'latitud':recintoSeleccionado?.latitud,
          'longitud':recintoSeleccionado?.longitud,
          'puntosDiarios':recintoSeleccionado?.puntosDiarios,
          'inicio':recintoSeleccionado?.inicio,
          'fin':recintoSeleccionado?.fin
        })
      } else {
        setValorCiudad({});
        setValorRecinto({});        
      }
    }

    useEffect(() => {
      verificaAccion();
    },[recintoSeleccionado])
    

    const handleSubmit =  (event) => {
        event.preventDefault();
         ObtenerDatos('/recintos.php?funcion=agregarRecinto', JSON.stringify(datosRecinto)).then(respuesta => {
            console.log('respuesta de guardado en modal', respuesta);
            if(respuesta!=1) {
                setErrorGuardado(true);
            } else{
                setErrorGuardado(false);
                obtenerRecintos();
                handleClose();

            }
        })
    }

    const geolocalizarDireccion = () => {
      fetch('https://maps.googleapis.com/maps/api/geocode/json?region=MEXICO&address='+datosRecinto?.direccion+'&key=AIzaSyBW_XR1RMYZ6NZlqdamgkpq0YqclLcYeOk')
      .then(response => response.json())
      .then(response => {
        console.log('respuesta de geolocalizacion', response?.results[0]?.geometry?.location)
        const coordenadas = response?.results[0]?.geometry?.location
        if (map) {
          map.setOptions({ gestureHandling: 'greedy', mapTypeControl: true, maptypeid:'satellite', zoom:15,
          markers:[],
          center: {
            lat: coordenadas.lat,
            lng: coordenadas.lng
          },
        });
        
        console.log('map antes de geolocalizar direccion en mapa',map, maps)
        //map.set
        if(marcador?.setMap) {
          marcador.setMap(null)
          setMarcador(null)
        }

        setDatosRecinto({
          ...datosRecinto,
          latitud:coordenadas.lat,
          longitud:coordenadas.lng,
        })

        let marker = new maps.Marker(
          
          {
          position:{
            lat: coordenadas.lat,
            lng: coordenadas.lng
          },
          map,
          title: datosRecinto?.nombre,
          draggable:true,
          

        }
          
        );

        console.log('marcador ref', marcador)
        // marcadorRef.current.position={
        //   lat: coordenadas.lat,
        //   lng: coordenadas.lng
        // }

        marker.addListener('dragend', function(event) {
          console.log('finalizo drag, leyendo coordenadas 1',event?.latLng.lat(),event?.latLng.lng())
          setDatosRecinto({
            ...datosRecinto,
            latitud:event?.latLng.lat(),
            longitud:event?.latLng.lng(),
          })
        })
        setMarcador(marker)
        
        }
      })
    }

    

    const loadMap = (map, maps, usuariosFuncion) => {
      console.log('usuarios recibidos en funcion', usuariosFuncion)
      
      setMap(map);
      setMaps(maps);
      const d = new Date();
      const seconds = Math.round(d.getTime() / 1000);
      if (map) {
        map.setOptions({ gestureHandling: 'greedy', mapTypeControl: true, maptypeid:'satellite', zoom:15});

 

      }


      if(recintoSeleccionado?.latitud>0 && map) {
        let marcador = new maps.Marker(
          
          {
          position:{
            lat: parseFloat(datosRecinto?.latitud),
            lng: parseFloat(datosRecinto?.longitud)
          },
          map,
          title: recintoSeleccionado?.nombre,
          draggable:true,
          

        }
          
        );
        marcador.addListener('dragend', function(event) {
          console.log('finalizo drag marker creador con datos originales, leyendo coordenadas 1',event?.latLng.lat(),event?.latLng.lng())
          setDatosRecinto({
            ...datosRecinto,
            latitud:event?.latLng.lat(),
            longitud:event?.latLng.lng(),
          })
        })
        setMarcador(marcador)
       
    } else {
      console.log('no se creo marcador', recintoSeleccionado)
    }
      
      // const kmlLayerSecciones = new maps.KmlLayer({
      //     url: 'http://raosari.dyndns.info:8080/decidechih/servicios/kmls/generar.capa.php?v=202203211403&anio='+anio,
      //     suppressInfoWindows: true,
      //     draggable: true,
      //     zIndex:2000,
      //     map:map
      // });
  
    }
  
    function createMapOptions(maps) {
      // next props are exposed at maps
      // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
      // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
      // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
      // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
      // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
      return {
        zoomControlOptions: {
          position: maps.ControlPosition.RIGHT_CENTER,
          style: maps.ZoomControlStyle.SMALL
        },
        mapTypeControlOptions: {
          position: maps.ControlPosition.TOP_RIGHT
        },
        mapTypeControl: true
      };
    }
    
    const AnyReactComponent = ({ text }) => <Room style={{color:'purple'}} />;
  return (
      <div
        style={{
            minWidth:600
        }}
    >
     <Dialog maxWidth='md' fullWidth open={modalAgregaRecinto} onClose={handleClose}>
        <form onSubmit={handleSubmit} ref={refForm}>
        <DialogTitle>Recinto</DialogTitle>
        <DialogContent
            style={{minWidth:600}}
        >
          <div
            style={{
              display:'flex',
              flexDirection:'row'
            }}
          >
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:60
              }}
            >
            <TextField
              InputLabelProps={{shrink:true}}
              autoFocus
              margin="dense"
              label="Nombre"
              type="text"
              fullWidth
              variant="standard"
              required={true}
              onChange={(e) => actualizaDatos('nombre',e.target.value)}
              value={datosRecinto?.nombre || ''}
            />
            <div
              style={{
                display:'flex',
                flexDirection:'row',
              }}
            >
              <TextField
                InputLabelProps={{shrink:true}}
                
                margin="dense"
                label="Dirección"
                type="text"
                fullWidth
                variant="standard"
                required={true}
                onChange={(e) => actualizaDatos('direccion',e.target.value)}
                value={datosRecinto?.direccion || ''}
              />
              <IconButton
                onClick={() =>{geolocalizarDireccion()}}
              >
                <Map style={{color:'green'}} />
              </IconButton>
            </div>
            <Autocomplete
              variant="standard"
              onChange={(event, newValue) => {console.log('new value', newValue);setValorCiudad(newValue); actualizaDatos('idCiudad', newValue)}}
              value={ valorCiudad?.id ? valorCiudad:null}
              defaultValue={valorCiudad || null}
              //disablePortal
              id="combo-box-demo"
              options={ciudades}
              sx={{ width: '100%', zIndex:999999999 }}
              style={{zIndex:99999999}}
              renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:5}} {...params} label="Ciudad" />}
            />
            <Autocomplete
              variant="standard"
              onChange={(event, newValue) => {console.log('new value', newValue); setValorRecinto(newValue); actualizaDatos('idTipoRecinto', newValue)}}
              value={valorRecinto?.id ? valorRecinto:null}
              //defaultValue={tiposActivo[index]}
              //disablePortal
              id="combo-box-demo"
              options={tiposRecinto}
              sx={{ width: '100%', zIndex:999999999 }}
              style={{zIndex:99999999}}
              renderInput={(params) => <TextField required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:5}} {...params} label="Tipo de recinto" />}
            />
            <div
              style={{
                display:'flex',
                flexDirection:'row',
              }}
            >
              
              <TextField 
                fullWidth
                variant='standard'
                label="Puntos/día"
                type='number'
                value={datosRecinto?.puntosDiarios || null}
                onChange={(e) => actualizaDatos('puntosDiarios',e.target.value)}
                InputLabelProps={{
                  shrink:true
                }}
              />
              <TextField 
                fullWidth
                variant='standard'
                label="Inicio"
                type='date'
                value={datosRecinto?.inicio || null}
                onChange={(e) => actualizaDatos('inicio',e.target.value)}
                style={{
                  marginLeft:10
                }}
                InputLabelProps={{
                  shrink:true
                }}
              />
              <TextField 
                fullWidth
                variant='standard'
                label="Fin"
                type='date'
                value={datosRecinto?.fin || null}
                onChange={(e) => actualizaDatos('fin',e.target.value)}
                style={{
                  marginLeft:10
                }}
                InputLabelProps={{
                  shrink:true
                }}
              />
            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'row',
              }}
            >
              
              <TextField 
                fullWidth
                variant='standard'
                label="Latitud"
                value={datosRecinto?.latitud || null}
                InputLabelProps={{
                  shrink:true
                }}
              />
              <TextField 
                fullWidth
                variant='standard'
                label="Longitud"
                value={datosRecinto?.longitud || null}
                style={{
                  marginLeft:10
                }}
                InputLabelProps={{
                  shrink:true
                }}
              />
            </div>
            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:60,
                padding:20
              }}
            >
              <div
                style={{
                  width:350,
                  height:350
                }}
              >
     <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBW_XR1RMYZ6NZlqdamgkpq0YqclLcYeOk", }}
        //ref={myRef}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        draggable={true}
   //     ondragend={(e) => finalizoDrag(e)}
        //year={year.value}
        //seccionseleccionada = {seccionseleccionada}
        onDragend= {(evt) => { }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          maps.ControlPosition='right'
          maps.maptypeid='satellite'
            //mapRef.current = map;
          loadMap(map, maps);
          

            const d = new Date();
            const seconds = Math.round(d.getTime() / 1000);
            return(<b>este es un objeto dentro de una funcion</b>)

    
        }}
        onChange={({map, maps}) => {
            //console.log(year.year.value)
            
            //loadMap1(map,maps,year.year.value);
        }}
      >
{/* <AnyReactComponent
      lat={28.65766}
      lng={-106.08410}
      text="My Marker"
      
      onDragEnd={(e) => console.log(e.nativeEvent.coordinate)}
      title={ "Location"}
  /> */}
    </GoogleMapReact>
              </div>
            </div>
          </div>

          {
            errorGuardado && (
              <Typography
                style={{
                    fontSize:12,
                    color:'red'
                }}
              >
                Error al guardar
            </Typography>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type='submit' >Guardar</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
