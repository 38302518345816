import { BadgeOutlined, CardMembership, ImageSearch } from '@mui/icons-material';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, Divider, TextField } from '@mui/material'
import React, { useContext } from 'react'
import Swal from 'sweetalert2';
import { AuthContext } from '../../context/AuthContext';
import { UsuariosContext } from '../../context/UsuariosContext'
import { ObtenerDatos } from '../../helpers/ApiHelper';

export const ModalApoyo = () => {
    const {modalApoyo, setModalApoyo, usuariosAutorizan, usuariosEntregan, actualizaDatos, datosApoyo, valorUsuarioAutoriza, setValorUsuarioAutoriza,
        uploadInputRef, imgMilitante, file, setFile, SeleccionImagen, usuarioSeleccionado, contarApoyos, valorUsuarioEntrego, setValorUsuarioEntrego,
        uploadInputRef1, imgMilitante1, file1, setFile1, SeleccionImagen1
    } = useContext(UsuariosContext);
        const {usuario} = useContext(AuthContext)

    const handleClose = () => {
        setModalApoyo(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Guardando apoyo', uploadInputRef?.current?.files.length)

        const data = new FormData();
        data.append('datos', JSON.stringify(datosApoyo))
        data.append('archivo', uploadInputRef.current?.files[0]);

        for(let x=0;x<uploadInputRef?.current?.files.length;x++) {
            console.log('archivo', uploadInputRef.current?.files[x])
            data.append('archivo'+x, uploadInputRef.current?.files[x]);
        }

        data.append('archivos', uploadInputRef.current?.files?.length || 0);
        data.append('identificacion', uploadInputRef1.current?.files[0]);
        data.append('idUsuarioApoyo', usuarioSeleccionado?.idUsuario);
        data.append('idUsuarioRegistra', usuario?.idUsuario)
        ObtenerDatos('/usuarios.php?funcion=guardarApoyo', data).then(response => {
            console.log('respuesta de guardar apoyo', response)
            contarApoyos();
            setModalApoyo(false);
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Apoyo guardado correctamente",
                showConfirmButton: false,
                timer: 1500
              });
        })

    }

  return (
      <Dialog
        fullWidth
        open={modalApoyo} onClose={handleClose}
    >
    <form onSubmit={handleSubmit} >
        <DialogContent>
            <TextField 
                size='small'
                onChange={(e) => {actualizaDatos('personaMoral', e.target.value)}}
                fullWidth
                variant='outlined'
                label='Nombre de Persona Moral'
                multiline={false}
                rows={4}
            />
            <TextField 
                size='small'
                onChange={(e) => {actualizaDatos('apoyo', e.target.value)}}
                required
                fullWidth
                variant='outlined'
                label='¿Cuál fue el apoyo?'
                multiline={true}
                rows={2}
                style={{
                    marginTop:15
                }}
            />
            <TextField 
                size='small'
                onChange={(e) => {actualizaDatos('monto', e.target.value)}}
                required
                fullWidth
                variant='outlined'
                label='Monto'
                type='number'
                step={0.01}
                multiline={false}
                rows={4}
                style={{
                    marginTop:15
                }}
                inputProps={{
                    step:0.01
                }}
            />
            <TextField 
                size='small'
                onChange={(e) => {actualizaDatos('fecha', e.target.value)}}
                required
                fullWidth
                variant='outlined'
                label='Fecha'
                multiline={false}
                type='date'
                style={{
                    marginTop:15
                }}
                InputLabelProps={{
                    shrink:true
                }}
            />
            <TextField 
                size='small'
                onChange={(e) => {actualizaDatos('comentarios', e.target.value)}}
                required
                fullWidth
                variant='outlined'
                label='Comentarios'
                multiline={true}
                rows={2}
                style={{
                    marginTop:15
                }}
            />
            <TextField 
                size='small'
                onChange={(e) => {actualizaDatos('numeroIdentificacion', e.target.value)}}
                fullWidth
                variant='outlined'
                label='# Identificación'
                multiline={false}
                rows={2}
                style={{
                    marginTop:15
                }}
            />
            <Autocomplete
                size='small'
                InputLabelProps={{shrink:true}}
                variant="standard"
                onChange={(event, newValue) => {setValorUsuarioAutoriza(newValue); actualizaDatos('idUsuarioAutoriza', newValue.id)}}
                value={valorUsuarioAutoriza?.id ? valorUsuarioAutoriza:null}
                //defaultValue={tiposActivo[index]}
                //disablePortal
                id="combo-box-demo"
                options={usuariosAutorizan}
                sx={{ zIndex:999999999 }}
                style={{zIndex:99999999, flex:25, margintop:3, marginLeft:0}}
                renderInput={(params) => <TextField size='small' required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Autorizó" />}
            />
            <Autocomplete
                size='small'
                InputLabelProps={{shrink:true}}
                variant="standard"
                onChange={(event, newValue) => {setValorUsuarioEntrego(newValue); actualizaDatos('idUsuarioEntrego', newValue.id)}}
                value={valorUsuarioEntrego?.id ? valorUsuarioEntrego:null}
                //defaultValue={tiposActivo[index]}
                //disablePortal
                id="combo-box-demo"
                options={usuariosEntregan}
                sx={{ zIndex:999999999 }}
                style={{zIndex:99999999, flex:25, margintop:3, marginLeft:0}}
                renderInput={(params) => <TextField size='small' required variant="standard"   style={{zIndex:9999999, backgroundColor:'white', marginTop:8, }} {...params} label="Entregó" />}
            />
            <div
                style={{
                    display:'flex',
                    flexDirection:'row'
                }}
            >
             <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden'}} accept='.jpg,.png' type="file" multiple />
              <img style={{
                
                width:100,
                height:'auto'
              }} ref={imgMilitante} src={''} />            
               <Button
                style={{
                  textTransform:'unset',
                  maxWidth:140,
                  minWidth:'40%',
                  marginTop:20
                }}
                variant="contained"
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                >Comprobante de Entrega <ImageSearch /></Button>
             <input name="imgMilitante1" onChange={event => SeleccionImagen1(event) } ref={uploadInputRef1} style={{visibility:'hidden'}} accept='.jpg,.png' type="file"  />
              <img style={{
                
                width:100,
                height:'auto'
              }} ref={imgMilitante1} src={''} />            
               <Button
                style={{
                  textTransform:'unset',
                  maxWidth:140,
                  minWidth:'40%',
                  marginTop:20
                }}
                variant="contained"
                onClick={() => uploadInputRef1.current && uploadInputRef1.current.click()}
                >Identificación Oficial <BadgeOutlined /></Button>
                </div>
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button onClick={() => {handleClose()}}>Cancelar</Button>
            <Button type='submit'>Guardar</Button>
        </DialogActions>
    </form>
    </Dialog>
  )
}
