import { Autocomplete,  IconButton,  TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { UsuariosContext } from '../../context/UsuariosContext'
import { ObtenerDatos } from '../../helpers/ApiHelper'
import Select from 'react-select';
import { AuthContext } from '../../context/AuthContext';
import { SearchOffOutlined, SearchRounded } from '@mui/icons-material';

export const FiltrosMapa = () => {
    const [etiquetas, setEtiquetas] = useState()
    const {nombreFiltro, setNombreFiltro, etiquetaFiltro, setEtiquetaFiltro, setActualizaMapa, actualizaMapa} = useContext(AuthContext)

    useEffect(() => {
        obtenerEtiquetas();
    }, [])
    const obtenerEtiquetas = () => {
        ObtenerDatos('/usuarios.php?funcion=etiquetas').then(response => {
            const opciones = new Array();
            opciones.push({value:'', label:'Todos'})
            response?.map(etiqueta => {
                opciones?.push({value:etiqueta.idEtiqueta, label:etiqueta.etiqueta})
            })
            setEtiquetas(opciones)
        })
    }

  return (
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            width:'100%',
            justifyContent:'flex-end',
            alignItems:'flex-end',
            backgroundColor:'transparent',
            color:'black',
            marginRight:30
        }}
    >
        <TextField
            size='small'
            label="Nombre"
            onChange={(e) => {setNombreFiltro(e.target.value)}}
            value={nombreFiltro}
            style={{
                marginRight:10
            }}
            inputProps={{
                style:{
                    fontSize:11,
                    width:200,
                    height:21,
                    marginRight:0
                }
            }}
            InputLabelProps={{
                style:{
                    fontSize:11
                }
            }}
        />
        {
            etiquetas?.length>0 ? (
                <Select
//                defaultValue={selectedOption}
                onChange={(e) => {setEtiquetaFiltro(e)}}
                options={etiquetas}
                placeholder="Etiqueta"
                menuPortalTarget={document.body} 
                InputLabelProps={{
                    style:{
                        fontSize:11
                    }
                }}
                InputProps={{
                    style:{
                        fontSize:11
                    }
                }}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999, fontSize:11 }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? 'grey' : 'green',
                    fontSize:12,
                    minWidth:150,
                    maxHeight:20
                  }),
            }}
                style={{
                    width:150,
                    maxHeight:20,
                    menuPortal: base => ({ ...base, zIndex: 999999, fontSize:11 }),
    
                }}
            />
            ):null
        }
        <IconButton
            onClick={() => {setActualizaMapa(actualizaMapa+1)}}
        >
            <SearchRounded />
        </IconButton>
    </div>
  )
}
