import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TextField, Typography } from '@mui/material';
import { UNSAFE_convertRoutesToDataRoutes } from '@remix-run/router';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ObtenerDatos } from '../../helpers/ApiHelper';
import { DeportesContext } from '../../pages/DeportesPage';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
export const ModalAgregaDeporteComponent = () => {
    const {modalAgregaDeporte, setModalAgregaDeporte, deporteSeleccionado, obtenerDeportes} = useContext(DeportesContext);
    const [datosDeporte, setDatosDeporte] = useState();
    const [errorGuardado, setErrorGuardado] = useState(false);

    const refForm = useRef();

    const handleClose = async() => {
        await setDatosDeporte({})
        setModalAgregaDeporte(false)
    }

    useEffect(() => {
      obtenerDeportes();
    }, [modalAgregaDeporte])
    

    const actualizaDatos = (dato, valor) => {
       setDatosDeporte({
           ...datosDeporte,
           [dato]:valor
       })
    }

    useEffect(() => {
      console.log('Se actualizaron los datos del recinto', datosDeporte)
    }, [datosDeporte])

    const verificaAccion = () => {
      console.log('recinto seleccionado en verificar accion', deporteSeleccionado)
      if(deporteSeleccionado?.idDeporte) {
        setDatosDeporte({
          'deporte':deporteSeleccionado.deporte,
          'idDeporte':deporteSeleccionado.idDeporte,
          'mostrarEnListado':deporteSeleccionado.mostrarEnListado
        })
      } else {
      }
    }

    useEffect(() => {
      verificaAccion();
    },[deporteSeleccionado])
    

    const handleSubmit =  (event) => {
        event.preventDefault();
         ObtenerDatos('/deportes.php?funcion=agregarDeporte', JSON.stringify(datosDeporte)).then(respuesta => {
            console.log('respuesta de guardado en modal', respuesta);
            if(respuesta!=1) {
                setErrorGuardado(true);
            } else{
                setErrorGuardado(false);
                obtenerDeportes();
                handleClose();

            }
        })
    }

  return (
      <div
        style={{
            minWidth:600
        }}
    >
     <Dialog open={modalAgregaDeporte} onClose={handleClose}>
        <form onSubmit={handleSubmit} ref={refForm}>
        <DialogTitle>Deporte</DialogTitle>
        <DialogContent
            style={{minWidth:600}}
        >
          <TextField
            InputLabelProps={{shrink:true}}
            autoFocus
            margin="dense"
            label="Nombre"
            type="text"
            fullWidth
            variant="standard"
            required={true}
            onChange={(e) => actualizaDatos('deporte',e.target.value)}
            value={datosDeporte?.deporte || ''}
          />
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              alignItems:'center'
            }}
          >
          <Checkbox 
            label='Mostrar en listado de deportes'
            checked={datosDeporte?.mostrarEnListado=='1' ? true:false}
            onChange={(e) => {console.log('checkbox cambio', e); actualizaDatos('mostrarEnListado', e.target.checked==true ? 1:0)}}
            />
            <Typography>Mostrar en listado de deportes</Typography>

          </div>


          {
            errorGuardado && (
              <Typography
                style={{
                    fontSize:12,
                    color:'red'
                }}
              >
                Error al guardar
            </Typography>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type='submit' >Guardar</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
