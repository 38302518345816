import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Rutas } from './routes/Rutas';
import { AuthContext, AuthProvider } from './context/AuthContext';
import { useContext, useEffect } from 'react';
function App() {
 
  return (
    <AuthProvider>
    <BrowserRouter>
      <Rutas />
    </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
