import react, { createContext, useEffect, useState, children, useRef, useContext } from "react";
import { Form } from "react-router-dom";
import Swal from "sweetalert2";
import { ObtenerDatos } from "../helpers/ApiHelper";
import { AuthContext } from "./AuthContext";


export const UsuariosContext = createContext();

export const UsuariosProvider = (props) => {
    const [usuarios, setUsuarios] = useState();
    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState();
    const [modalUsuario, setModalUsuario] = useState(false)
    const [instituciones, setInstituciones] = useState()
    const [generos, setGeneros] = useState()
    const [estadosCiviles, setEstadosCiviles] = useState()
    const [ciudades, setCiudades] = useState();
    const [municipios, setMunicipios] = useState();
    const [ocupaciones, setOcupaciones] = useState();
    const [modalApoyo, setModalApoyo] = useState(false)
    const [datosApoyo, setDatosApoyo] = useState();
    const [usuariosAutorizan, setUsuariosAutorizan] = useState([]);
    const [usuariosEntregan, setUsuariosEntregan] = useState([])
    const [valorUsuarioAutoriza, setValorUsuarioAutoriza] = useState();
    const [valorUsuarioEntrego, setValorUsuarioEntrego] = useState();
    const uploadInputRef = useRef(null);
    const imgMilitante = useRef(null);
    const [file, setFile] = useState()
    const uploadInputRef1 = useRef(null);
    const imgMilitante1 = useRef(null);
    const [file1, setFile1] = useState()
    const [apoyosOtorgados, setApoyosOtorgados] = useState(0);
    const [modalTablaApoyos, setModalTablaApoyos] = useState(false)
    const [datosApoyos, setDatosApoyos] = useState([]);
    const [modalImagenApoyo, setModalImagenApoyo] = useState();
    const [apoyoSeleccionado, setApoyoSeleccionado] = useState();
    const [etiquetas, setEtiquetas] = useState();
    const [modalDeportesFavoritos, setModalDeportesFavoritos] = useState(false)
    const [deportesFavoritos, setDeportesFavoritos] = useState();
    const [deportesPractica, setDeportesPractica] = useState();
    const [textoDeportesFavoritos, setTextoDeportesFavoritos] = useState()
    const [modalDeportesPractica, setModalDeportesPractica] = useState(false)
    const [modalPermisos, setModalPermisos] = useState(false)
    const [permisos, setPermisos] = useState();
    const [modalPassword, setModalPassword] = useState(false)
    const [deportesFavoritosNuevo, setDeportesFavoritosNuevo] = useState();
    const [pagina, setPagina] = useState(1);
    const [registros, setRegistros] = useState();
    const [usuarioBuscar, setUsuarioBuscar] = useState();
    const {usuario} = useContext(AuthContext)
    const [etiquetaFiltro, setEtiquetaFiltro] = useState()

    const actualizaDatos = (campo, valor) => {
        setDatosApoyo({
            ...datosApoyo,
            [campo]:valor
        })
    }

    useEffect(() => {
        console.log('usuario o reporte seleccionado en mapa', usuarioSeleccionado);
        contarApoyos();
        obtenerDeportesPractica();
        obtenerDeportesFavoritos();
        obtenerPermisos();
    }, [usuarioSeleccionado])

    const obtenerPermisos = () => {
        const data = new FormData();
        data.append('idUsuario', usuarioSeleccionado?.idUsuario || 0)
        fetch(process.env.REACT_APP_API_URL+'/usuarios.php?funcion=permisos', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(permisos => {
            console.log(permisos)
            setPermisos(permisos)
          //console.log(reportes.length)
        })
        .catch(error => {
          console.log('Ocurrio un error', error)
        })

    }

    const actualizaDeporteFavorito = (idDeporte, seleccionado) => {
        console.log(idDeporte, seleccionado)
        const accion = seleccionado ? 1:0
        const data = new FormData();
        data.append('idUsuario', usuarioSeleccionado?.idUsuario);
        data.append('idDeporte', idDeporte)
        data.append('accion', accion)
            ObtenerDatos('/usuarios.php?funcion=actualizaDeporteFavorito', data).then(response => {
                console.log(response)
                obtenerDeportesFavoritos();
            })
    }

    const actualizaDeportePractica = (idDeporte, seleccionado) => {
        console.log(idDeporte, seleccionado)
        const accion = seleccionado ? 1:0
        const data = new FormData();
        data.append('idUsuario', usuarioSeleccionado?.idUsuario);
        data.append('idDeporte', idDeporte)
        data.append('accion', accion)
            ObtenerDatos('/usuarios.php?funcion=actualizaDeportePractica', data).then(response => {
                console.log(response)
                obtenerDeportesPractica();
            })
    }

    const obtenerEtiquetas = () => {
        ObtenerDatos('/usuarios.php?funcion=etiquetas').then(response => {
            const opciones = new Array();
            response?.map(etiqueta => {
                opciones?.push({id:etiqueta.idEtiqueta, label:etiqueta.etiqueta})
            })
            setEtiquetas(opciones)
        })
    }

    const obtenerDeportesPractica = () => {
        const data = new FormData();
        data.append('idUsuario', usuarioSeleccionado?.idUsuario || 0);
        ObtenerDatos('/usuarios.php?funcion=usuarioDeportesPractica', data).then(response => {
            console.log('Deportes que practica', response)
            setDeportesPractica(response);
        })
    }

    const obtenerDeportesFavoritos = () => {
        const data = new FormData();
        data.append('idUsuario', usuarioSeleccionado?.idUsuario || 0);
        ObtenerDatos('/usuarios.php?funcion=usuarioDeportesFavoritos', data).then(response => {
            console.log('Deportes favoritos', response)
            setDeportesFavoritos(response);
        })
    }

    const obtenerApoyos = () => {
        const data = new FormData();
        data.append('idUsuario', usuarioSeleccionado?.idUsuario)
        ObtenerDatos('/usuarios.php?funcion=obtenerApoyos', data).then(response => {
            console.log('apoyos al usuario', response)
            setDatosApoyos(response); 
        })
    }
    

    const contarApoyos = () => {
        const data = new FormData();
        data.append('idUsuario', usuarioSeleccionado?.idUsuario)
        ObtenerDatos('/usuarios.php?funcion=contarApoyos', data).then(response => {
            setApoyosOtorgados(response);
        })
    }

    const obtenerUsuarios = () => {
        console.log('etiqueta filtro', etiquetaFiltro)
        const data = new FormData();
        data.append('pagina', pagina)
        data.append('usuario', usuarioBuscar)
        data.append('idUsuario', usuario.idUsuario)
        data.append('idEtiqueta', etiquetaFiltro?.id || 0)
        ObtenerDatos('/usuarios.php?funcion=usuarios', data).then(response => {
            console.log('usuarios obtenidos', response)
            setUsuarios(response.usuarios)
            setRegistros(parseInt(response.total))
        })
    }

    useEffect(() => {
        obtenerUsuarios();
    }, [pagina])
    

    const obtenerUsuariosAutorizan = () => {
        ObtenerDatos('/usuarios.php?funcion=usuariosAutorizan').then(response => {
            console.log('usuarios obtenidos', response)
            let opciones = new Array()
            response?.map(autoriza => {
                opciones.push({id:autoriza.idUsuario, label:autoriza.nombre+' '+autoriza.apellidoPaterno+' '+(autoriza?.apellidoMaterno ? autoriza?.apellidoMaterno:'') })
            })
            setUsuariosAutorizan(opciones)
        })
    }

    const obtenerUsuariosEntregan = () => {
        ObtenerDatos('/usuarios.php?funcion=usuariosEntregan').then(response => {
            console.log('usuarios obtenidos', response)
            let opciones = new Array()
            response?.map(autoriza => {
                opciones.push({id:autoriza.idUsuario, label:autoriza.nombre+' '+autoriza.apellidoPaterno+' '+(autoriza?.apellidoMaterno ? autoriza?.apellidoMaterno:'') })
            })
            setUsuariosEntregan(opciones)
        })
    }

    const obtenerInstituciones = () => {
        ObtenerDatos('/instituciones.php?funcion=instituciones').then(response => {
            let opciones = new Array();
            response?.map(institucion => {
                opciones.push({...institucion, id:institucion.idInstitucion, label:institucion.institucion})
            })
            setInstituciones(opciones)
        })
    }

    const obtenerGeneros = () => {
        ObtenerDatos('/usuarios.php?funcion=generos').then(response => {
            let opciones = new Array();
            response?.map(genero => {
                opciones.push({...genero, id:genero.idGenero, label:genero.genero})
            })
            setGeneros(opciones)
        })
    }

    const obtenerEstadosCiviles = () => {
        ObtenerDatos('/usuarios.php?funcion=estadosCiviles').then(response => {
            let opciones = new Array();
            response?.map(estadoCivil => {
                opciones.push({...estadoCivil, id:estadoCivil.idEstadoCivil, label:estadoCivil.estadoCivil})
            })
            setEstadosCiviles(opciones)
        })
    }

    const obtenerCiudades  = () => {
        ObtenerDatos('/usuarios.php?funcion=ciudades').then(response => {
            let opciones = new Array();
            response?.map(ciudad => {
                opciones.push({...ciudad, id:ciudad.idCiudad, label:ciudad.nombreCiudad})
            })
            setCiudades(opciones)
        })
    }

    const obtenerMunicipios  = () => {
        ObtenerDatos('/usuarios.php?funcion=municipios').then(response => {
            let opciones = new Array();
            response?.map(municipio => {
                opciones.push({...municipio, id:municipio.idMunicipio, label:municipio.municipio})
            })
            setMunicipios(opciones)
        })
    }

    const obtenerOcupaciones  = () => {
        ObtenerDatos('/usuarios.php?funcion=ocupaciones').then(response => {
            let opciones = new Array();
            response?.map(ocupacion => {
                opciones.push({...ocupacion, id:ocupacion.idOcupacion, label:ocupacion.ocupacion})
            })
            setOcupaciones(opciones)
        })
    }

    useEffect(() => {
        obtenerUsuarios();
        obtenerUsuariosAutorizan();
        obtenerUsuariosEntregan();
        obtenerEtiquetas();
    }, [])

    const SeleccionImagen = (event) => {
        setFile({file:event.target.files[0]});
        console.log('selecciono imagen',event);
        var reader = new FileReader();
        reader.onload = function(){
            imgMilitante.current.src = reader.result;
        };
        reader.readAsDataURL(event.target.files[0]);
    };
    
    const SeleccionImagen1 = (event) => {
        setFile1({file:event.target.files[0]});
        console.log('selecciono imagen',event);
        var reader = new FileReader();
        reader.onload = function(){
            imgMilitante1.current.src = reader.result;
        };
        reader.readAsDataURL(event.target.files[0]);
    };
    

    const eliminarUsuario = (idUsuario) => {
        Swal.fire({
          title: '¿Estás seguro(a)?',
          text: "Se eliminará al usuario, esto no puede ser revertido",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminar!'
        }).then((result) => {
          if (result.isConfirmed) {
            const data = new FormData();
            data.append('idUsuario', idUsuario)
            ObtenerDatos('/usuarios.php?funcion=eliminarUsuario', data).then(response => {
              if(response==1) {
                Swal.fire(
                  'Eliminado!',
                  'El usuario ha sido eliminado.',
                  'success'
                )
                obtenerUsuarios();
              } else {
                Swal.fire(
                  'Error!',
                  'el usuario no pudo ser eliminado!.',
                  'error'
                )
    
              }
            })
          }
        })
      }
    

   
    return(
        <UsuariosContext.Provider
            value={{
                usuarios,
                obtenerUsuarios,
                usuarioSeleccionado, setUsuarioSeleccionado,
                modalUsuario, setModalUsuario, obtenerInstituciones,
                instituciones, generos, obtenerGeneros, estadosCiviles, obtenerEstadosCiviles,ciudades,
                obtenerCiudades, municipios, obtenerMunicipios, ocupaciones, obtenerOcupaciones, eliminarUsuario, modalApoyo, setModalApoyo,
                usuariosAutorizan, actualizaDatos, datosApoyo,valorUsuarioAutoriza, setValorUsuarioAutoriza,
                uploadInputRef, imgMilitante, file, setFile, SeleccionImagen, apoyosOtorgados, contarApoyos, modalTablaApoyos, setModalTablaApoyos,
                datosApoyos, obtenerApoyos, modalImagenApoyo, setModalImagenApoyo, apoyoSeleccionado, setApoyoSeleccionado, etiquetas,
                modalDeportesFavoritos, setModalDeportesFavoritos, deportesFavoritos, deportesPractica, setDeportesPractica, actualizaDeporteFavorito, modalDeportesPractica, setModalDeportesPractica,
                actualizaDeportePractica, modalPermisos, setModalPermisos, permisos, obtenerPermisos, modalPassword, setModalPassword, setDeportesFavoritos,
                deportesFavoritosNuevo, setDeportesFavoritosNuevo, pagina, setPagina, registros, usuarioBuscar, setUsuarioBuscar,
                valorUsuarioEntrego, setValorUsuarioEntrego, usuariosEntregan, obtenerUsuariosEntregan, etiquetaFiltro, setEtiquetaFiltro,
                uploadInputRef1, imgMilitante1, file1, setFile1, SeleccionImagen1,
                etiquetas

            }}
            >
            {props.children}
        </UsuariosContext.Provider>
    )
}