export const ObtenerDatos = async(url, data) => {
    return new Promise((resolve, reject) => {
         fetch(process.env.REACT_APP_API_URL+url, {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}