import React, { useContext, useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { styled } from '@mui/material/styles';

import { IconButton, Tooltip, tooltipClasses  } from '@mui/material';
import { ReportesContext } from '../pages/ReportesPage';
import { AuthContext } from '../context/AuthContext';
import { Delete, Edit } from '@mui/icons-material';
import dayjs from 'dayjs';
import { EliminarReporte } from '../helpers/ReportesHelper';
import Swal from 'sweetalert2';
export const TablaReportes = ({reportesTabla}) => {
    const [reportesTablaMostrar, setReportesTablaMostrar] = useState()
    const {modalReporte, setModalReporte, setReporteSeleccionado, modalEdicionReporte, setModalEdicionReporte, setTotalReportesVista, setModalAsignarTecnico, Reportes} = useContext(ReportesContext)
    const {usuario, idDependenciaMostrar} = useContext(AuthContext)

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip placement='right' {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 500,
          borderRadius:10,
          padding:0
        },
      });

      console.log('Usuario en reportes', usuario, 'permiso', usuario?.permisos?.substr(16,1))

      useEffect(() => {
        setTotalReportesVista(reportesTablaMostrar?.length)
      }, [reportesTablaMostrar])
      

      useEffect(() => {
        console.log('reportes tabla mostrar', reportesTablaMostrar)
        if(idDependenciaMostrar==0) {
            setReportesTablaMostrar(reportesTabla)
        } else {
            const reportesMostrar = reportesTabla?.filter(reporte => reporte.IdDependencia==idDependenciaMostrar);
            setReportesTablaMostrar(reportesMostrar)
        }
      }, [reportesTabla, idDependenciaMostrar])
      
      const eliminarReporte = (idReporte) => {
        Swal.fire({
          title: '¿Estás seguro(a)?',
          text: "Se eliminará la clase, esto no puede ser revertido",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminar!'
        }).then((result) => {
          if (result.isConfirmed) {
            EliminarReporte(idReporte).then(response => {
                console.log('reporte eliminado');
                Reportes();
            
              if(response==1) {
                Swal.fire(
                  'Eliminado!',
                  'El reporte ha sido eliminado.',
                  'success'
                )
//                Reportes();
              } else {
                Swal.fire(
                  'Error!',
                  'El reporte no pudo ser eliminado!.',
                  'error'
                )
    
              }
            })
          }
        })
      }


     

  return (
    <div
        style={{
            display:'flex',
            flex:1
        }}
    >
        <TableContainer
            style={{
                display:'flex',
                flex:1,
                maxHeight:'70vh'
                
            }}
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Prioridad</TableCell>
                        <TableCell>Foto</TableCell>
                        <TableCell>Notas</TableCell>
                        <TableCell>Folio</TableCell>
                        <TableCell>Ciudadano</TableCell>
                        <TableCell>Estatus</TableCell>
                        <TableCell>Enviado</TableCell>
                        <TableCell>Proceso</TableCell>
                        <TableCell>Resuelto</TableCell>
                        {
                            (usuario?.permisos).substr(9,1)==1 ? (     
                                <TableCell></TableCell>
                                ):null
                            }
                        {
                             usuario?.Usuarios_IdTipoUsuario==2 ? (     
                            <TableCell></TableCell>
                             ):null
                        }
                    </TableRow>
                </TableHead>
                <TableBody
                    style={{
                        fontSize:'.7rem'
                    }}
                >
                {
                    reportesTablaMostrar?.map(reporte => (
                       
                        <TableRow
                            style={{
                                fontSize:11
                            }}
                        >
                            <TableCell style={{
                                fontSize:12
                            }}>
                                Nivel {reporte?.IdPrioridad ? reporte.IdPrioridad:1}
                            </TableCell>
                            <TableCell
                                onClick={() => {setReporteSeleccionado(reporte); setModalReporte(true)}}
                                style={{
                                    fontSize:12
                                }}
                            >
                                <CustomWidthTooltip 

                                    componentsProps={{
                                        tooltip: {
                                        sx: {
                                            bgcolor: '#03ABC6',
                                            '& .MuiTooltip-arrow': {
                                            color: 'common.black',
                                            maxWidth:600,
                                            
                                            // width:300
                                            },
                                        },
                                        },
                                    }}                                
                                    style={{
                                        backgroundColor:'white',
                                       
                                    }}
                                    title={
                                        <>
                                        <div
                                            style={{
                                                backgroundColor:'transparent',
                                                display:'flex',
                                                flexDirection:'row'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width:500,
                                                    height:600,
                                                    flex:40,
//                                                    alignItems:'flex',
                                                    display:'flex',
                                                    flexDirection:'column',
                                                    justifyContent:'space-around'
                                                }}
                                            >
                                                <div>
                                                <img onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src="";
  }} style={{width:'auto', height:200, borderRadius:10, position:'relative', margin:30}} src={'https://deporti.com.mx/reportes/Reporte-'+reporte.IdReporte.padStart(6,"0")+'.jpg'} />
                                                </div>
                                                <div>
                                                {reporte?.Resuelto ? (
                                                <img onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src="";
                                                  }} style={{width:'auto', height:200, borderRadius:10, position:'relative', margin:30}} src={'http://quejas.juarez.gob.mx/imagenes/Tecnico-'+reporte.IdReporte.padStart(6,"0")+'.jpg'} />
                                                
                                                ):null
                                            }
                                            </div>
                                            </div>
                                            <div
                                                style={{
                                                    backgroundColor:'#642644',
                                                    width:'auto'
                                                }}
                                            >
                                            </div>
                                            <div
                                                style={{
                                                    display:'flex',
                                                    flexDirection:'column',
                                                    backgroundColor:'white',
                                                    flex:70,
                                                    alignItems:'center',
                                                    
                                                }}
                                            >
                                            <img  
                                                src={require("../assets/logo.png") } 
                                                style={{
                                                width:120,
                                                height:'auto',
                                                marginTop:30
                                                }}
                                            />
                                            </div>
                                            </div>                                                
                                        </>
                                    }>
                                    <PhotoOutlinedIcon />
                                </CustomWidthTooltip>
                            </TableCell>
                            <TableCell
                                 style={{
                                    fontSize:12
                                }}
                                onClick={() => setModalReporte(true)}
                            >
                                <>
                                <CustomWidthTooltip                      
                                componentsProps={{
                                        tooltip: {
                                        sx: {
                                            bgcolor: '#03ABC6',
                                            '& .MuiTooltip-arrow': {
                                            color: 'common.black',
                                            maxWidth:600,
                                            
                                            // width:300
                                            },
                                        },
                                        },
                                    }}                                
                                    style={{
                                        backgroundColor:'white',
                                       
                                    }}
                                    title={
                                        <div
                                            style={{
                                                maxWidth:300,
                                                padding:30
                                            }}
                                        >
                                           {reporte.Descripcion}
                                        </div>
                                    } 
                                >
                                <DescriptionOutlinedIcon />
                                </CustomWidthTooltip>
                                </>
                            </TableCell>
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                #{reporte.IdReporte}
                            </TableCell>
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte?.IdPlataforma==3 ? reporte?.Usuarios_Nombre+' '+reporte?.Usuarios_ApellidoPaterno : reporte.ProfileName}
                            </TableCell>
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte.EstatusReporte}
                            </TableCell>
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte.Enviado}
                            </TableCell>
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {dayjs(dayjs()).diff(dayjs(reporte.Fecha),'day')} día(s)
                            </TableCell>
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte.Resuelto}
                            </TableCell>
                            {
                            (usuario?.permisos).substr(9,1)==1 ? (     
                            <>
                            {
                                reporte.IdEstatus=="2" ? (
                            <TableCell>
                                <IconButton
                                    onClick={() => {setReporteSeleccionado(reporte); setModalEdicionReporte(true)}}
                                >
                                    <Edit size="small" style={{fontSize:14, color:'green'}} />
                                </IconButton>
                            </TableCell> 
                            ):(
                                <TableCell>
                                <IconButton
                                  disabled
                                >
                                    <Edit size="small" style={{fontSize:14, color:'gray'}} />
                                </IconButton>
                            </TableCell> 

                            )
                            }
                            </>
                            ):null
                        }
                        {
                            usuario?.permisos?.substr(16,1)==1 ? (
                                <TableCell>
                                <IconButton
                                  onClick={() => eliminarReporte(reporte.IdReporte)}
                                >
                                    <Delete size="small" style={{fontSize:14, color:'red'}} />
                                </IconButton>
                            </TableCell> 
                            ):null
                        }   
                        </TableRow>
                        
                    ))
                }
                </TableBody>
            </Table>
        </TableContainer>
    </div>
  )
}
