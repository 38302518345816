import React from 'react'
import logo from '../assets/logo.png'


export const InicialPage = () => {
  return (
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            flex:1,
            justifyContent:'center',
            alignItems:'center'
        }}
    >
                       <img 
                style={{
                    width:500,

                }}
                src={logo}
                />
    </div>
  )
}
